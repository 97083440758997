@import '~font-awesome/css/all.css';

html[dir="ltr"] {
  --corner-top-end-radius: 0 6px 0 0;
}

body {
  &.modern-theme {
    color: var(--color-gray-900);

    .vnotify-container {
      &.vn-bottom-right {
        top: 12px !important;
        z-index: 99999;
        width: calc((calc(100vw - 1200px) / 2) - 24px);
        right: 16px;
        bottom: 24px;
        min-width: 320px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 10px;
        flex-direction: column;

        .vnotify-item {
          margin: 0;
          width: fit-content;
          max-width: 320px;
          padding-inline-start: 3rem;
          padding: 0px;
          padding-inline-start: 56px;
          background-color: rgba(var(--toastr-color), 0.85);
          transition-duration: 0.15s;
          border: solid 1px rgb(var(--toastr-color));
          border-radius: @roundedCorners;
          --shadow-color-rgb: var(--toastr-color);
          --shadow-opacity: .5;
          box-shadow: 0px 6px 20px rgba(var(--toastr-color), 0.5);
          -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
          min-height: 56px;
          overflow: hidden;

          &::before {
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-flex;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            font-family: var(--fontawesome-font);
            font-weight: 700;
            position: absolute;
            inset-inline-start: 18px;
            top: calc(50% - 10px);
            font-size: 20px;
          }
          &.vnotify-success {
            --toastr-color: 56, 159, 143;

            &:hover {
              --toastr-color: 26, 137, 120;
            }
            &::before {
              content: "\F058";
            }
          }
          &.vnotify-error {
            --toastr-color: 247, 95, 95;

            &:hover {
              --toastr-color: 244, 47, 47;
            }
            &::before {
              content: "\F057";
            }
          }
          &.vnotify-warning {
            --toastr-color: 255, 160, 118;

            &:hover {
              --toastr-color: 255, 124, 66;
            }
            &::before {
              content: "\F071";
            }
          }
          &.vnotify-info {
            --toastr-color: 76, 170, 229;

            &:hover {
              --toastr-color: 6, 156, 249;
            }
            &::before {
              content: "\F06A";
            }
          }
          .vnotify-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            display: flex;
            height: 100%;
            align-items: center;
            padding-inline: 20px;
            padding-block: 10px;
            background: @whiteColor;
            color: rgb(var(--toastr-color));
          }
          .vn-close {
            top: 0;
            right: 4px;
            padding: 0;
            height: 28px;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-flex;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            font-family: var(--fontawesome-font);
            font-weight: 400;
            align-items: center;
            opacity: 0;
            transition-duration: 0.15s;
            color: rgb(247, 95, 95);

            &::before {
              content: "\f00d";
              line-height: 1;
              transform: rotate(0deg);
              background: transparent;
              top: initial;
              height: auto;
            }
            &::after {
              display: none;
            }
          }
          &:hover {
            opacity: 1 !important;

            .vn-close {
              opacity: 1;
            }
          }
        }
        .vnotify-text {
          border-radius: 0 calc(@roundedCorners - 1px) calc(@roundedCorners - 1px) 0;
        }
      }
      &:empty {
        display: none;
      }
    }
    .step-page-container {
      #formBtn {
        border-radius: @roundedCorners !important;
        background-color: var(--color-primary-600);
        padding-inline-start: 25px;
        padding-inline-end: 25px;
        font-weight: 600;
        text-transform: none;
      }
      #verify-email-to-send {
        .modal-dialog {
          margin: 210px auto 0;
        }
      }
    }
    .be-item-radio {
      label {
        display: flex;
        align-items: center;
        gap: 6px;
        padding-inline-start: 0;
      }
      .be-item-input {
        margin: 0;
        position: relative;
        top: 1px;
      }
    }
    // Statuses badges
    .status-badge {
      --status-color: var(--main-text-color);
      --status-color-bg: #E6E6EA;
      --status-font-weight: 500;
      font-size: var(--status-font-size, 12px);
      font-weight: var(--status-font-weight);
      color: var(--status-color);
      background-color: transparent;
      padding: 4px 12px;
      border-radius: 20px;
      border: none;
      line-height: 1.5;
      white-space: nowrap;

      &.status-bg,
      &.hover-bg:hover {
        background-color: var(--status-color-bg);
        white-space: nowrap;
      }
      &.uppercase {
        text-transform: uppercase;
        padding-inline: 0px;
      }
      &:is(.status-default, .status-unavailable) {
        --status-color: var(--main-text-color);
        --status-color-bg: #E6E6EA;
      }
      &:is(.status-info, .status-pending, .status-filepending) {
        --status-color: #1D86C8;
        --status-color-bg: #E4F2FB;
      }
      &:is(.status-success, .status-processed, .status-filevalidated) {
        --status-color: #10B981;
        --status-color-bg: #E8F7F1;
      }
      &:is(.status-warning, .status-correction, .status-filedecline) {
        --status-color: @warningNew;
        --status-color-bg: @warningNewLight;
      }
      &:is(.status-error, .status-rejected, .status-filereject, .status-danger) {
        --status-color: #F53D3D;
        --status-color-bg: #FEE7E7;
      }
      .tooltip-inner {
        white-space: break-spaces;
      }
      .exclamation-icon {
        margin-inline-start: 4px;
        margin-inline-end: -2px;
      }
    }
    .popover {
      font-family: var(--main-font-family);
      z-index: 99999;

      file-status {
        display: inline-flex;
      }
    }
    .popover-file-roles {
      display: flex;
      flex-wrap: wrap;
      column-gap: 10px;
      row-gap: 4px;
    }
    // Applications status filter
    .status-legend-filter {
      margin-top: 30px;

      .all-limited-button {
        span {
          background-color: var(--color-gray-75);
          border-radius: @roundedCorners;
          color: @darkTextColor;
          height: 36px;
          padding: 0 15px;
          display: flex;
          align-items: center;
          font-weight: 500;

          &::after {
            font-family: var(--fontawesome-font);
            font-weight: 400;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            content: "\f107";
            margin-inline-start: 12px;
          }
          &:last-child {
            &::after {
              content: "\f106";
            }
          }
          &:hover {
            text-decoration: none;
          }
        }
      }
      .application-status {
        margin-inline-end: -2000px;
        height: 0;
        transition-duration: 0.4s;
        transition-timing-function: cubic-bezier(0, 0.18, 0, 1);

        .applications-statuses {
          height: 26px;
          gap: 8px;
        }
        &.show-legend {
          margin-inline-end: 0px;
          height: auto;

          @media screen and (max-width: 480px) {
            .applications-statuses {
              display: grid;

              span {
                text-align: start;
              }
            }
          }
        }
      }
      span {
        &.btn-link {
          font-size: 12px;
        }
        &.filter-by {
          margin-inline-end: 28px;
          font-size: 12px;
          color: var(--color-gray-800);
          font-weight: 500;
        }
      }
      .status-filter-toggler {
        padding: 0;
        color: var(--color-gray-800);
        border-bottom: solid 1px;
        line-height: 1.2;

        &:where(:hover, :focus) {
          border-bottom-color: transparent;
          text-decoration: none;
        }
      }
      &.part-b-status-filter {
        margin-inline-start: auto;
      }
    }
    /* Guide */
    form {
      &.formio-form {
        .form-group {
          &.form-field-type-panel {
            box-shadow: none;
          }
        }
      }
    }
    #form-type-GUIDE {
      form {
        .formio-component-questionscolumns {
          > .row {
            > .col-sm-4 {
              > .form-field-type-panel {
                > .panel {
                  background-color: @whiteColor;
                  box-shadow: 0px 0px 20px @black5Color;
                  border-radius: @roundedCorners !important;
                  padding: @panelPadding;

                  > .panel-heading {
                    padding-top: 0;
                    padding-inline-start: 0;
                    padding-inline-end: 0;
                    padding-bottom: 35px;

                    > .panel-title {
                      font-size: 18px;
                      font-weight: 600;
                      color: @darkTextColor;
                    }
                  }
                  > .panel-body {
                    padding: 0;

                    .panel {
                      background-color: transparent;
                    }
                  }
                }
              }
              & + .col-sm-4 {
                .form-field-type-htmlelement {
                  margin-bottom: 0;

                  &:first-of-type {
                    div {
                      line-height: 1;
                    }
                    span {
                      &.card-title {
                        margin: 0;
                        font-size: 18px;
                        font-weight: 600;
                        color: @darkTextColor;
                        padding-bottom: 35px;
                      }
                    }
                  }
                  & + .form-field-type-checkbox {
                    margin-top: 0;

                    .checkbox {
                      padding-top: 0;
                    }
                  }
                }
                .form-field-type-checkbox {
                  .checkbox {
                    margin-top: 0;

                    label {
                      span {
                        line-height: 1.2;
                        padding-inline-start: 3rem;
                        margin-top: -2px;

                        &::before {
                          top: 2px;
                        }
                      }
                    }
                  }
                }
              }
            }
            .block-fees {
              background-color: @whiteColor;
              box-shadow: 0px 0px 20px @black5Color;
              border-radius: @roundedCorners !important;

              .form-field-type-button {
                button {
                  background-color: transparent;
                  padding: @panelPadding;
                  color: @darkTextColor;
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 1;
                }
              }
              .formio-component-feeTitleColumns {
                > .row {
                  > .col-sm-6 {
                    &:last-of-type {
                      display: flex;
                      align-items: center;

                      .formio-component-feeTitleContent {
                        color: @darkTextColor;
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 1;
                      }
                    }
                  }
                }
              }
            }
            .block-requirements {
              background-color: @whiteColor;
              box-shadow: 0px 0px 20px @black5Color;
              border-radius: @roundedCorners !important;
              padding: @panelPadding;
              color: @darkTextColor;
              margin-top: 2rem !important;

              .formio-component-requirementTitleColumns {
                .formio-component-requirementOpenCloseButton {
                  line-height: 1;

                  &::before {
                    top: 0.4rem;
                    inset-inline-end: -2px;
                  }
                  button {
                    background-color: transparent;
                    padding: 0 0 30px;
                    line-height: 1;
                    font-size: 18px;
                    font-weight: 600;
                  }
                  &.closed {
                    button {
                      padding: 0;
                    }
                  }
                }
                .formio-component-requirementTitleCount {
                  top: 0;
                  line-height: 1;
                  inset-inline-end: 3rem;
                  font-size: 18px;
                  font-weight: 600;
                }
              }
              .formio-component-requirementsRows {
                padding: 0;

                .formio-container-component {
                  > .form-field-type-htmlelement {
                    &:first-child {
                      &:not(.ng-hide) {
                        li {
                          border-top: none;
                        }
                      }
                      &.ng-hide {
                        & + .form-field-type-htmlelement {
                          li {
                            border-top: none;
                          }
                        }
                      }
                    }
                  }
                }
              }
              & ~ .form-field-type-button {
                margin-top: 2rem !important;
              }
            }
          }
        }
      }
    }
    /* New Formio */
    @buttonAccentColor: var(--color-primary-600);
    &.formio-new {
      #header {
        z-index: 999;
        max-height: 80px;
      }
      #base {
        overflow: initial;

        #content {
          section {
            &.affix-menu-size-container {
              #shopping-cart-container {
                display: none;
              }
              .service-bar-user-lang-menu {
                &[hidden] {
                  display: block;
                }
              }
              &.affix ~ .section-body .contain-lg #formio-form-wizard {
                .formio-component-modal-wrapper.formio-component-modal-wrapper-datagrid .formio-dialog.formio-dialog-theme-default {
                  padding-top: 55px;
                  transition-duration: 0.15s;
                  transition-property: padding-top;
                }
              }
            }
          }
          .section-body {
            .contain-lg {
              .transactions-statuses {
                position: relative;
                z-index: 1040;
                background: @whiteColor;
                box-shadow: 45vw -3rem 0 @whiteColor, -45vw -3rem 0 @whiteColor, -50vw 0 0 @whiteColor, 50vw 0 0 @whiteColor;

                .alert {
                  margin-top: 1rem !important;
                  margin-bottom: 0 !important;
                  border-radius: 0 @roundedCorners @roundedCorners 0;
                  box-shadow: none;
                  border-inline-start: solid 4px rgb(var(--alert-color-rgb)) !important;
                  background-color: rgba(var(--alert-color-rgb), 0.1) !important;
                  color: rgb(var(--alert-color-rgb)) !important;
                  padding: 1.5rem 2rem !important;

                  &::after {
                    display: none !important;
                  }
                  &.alert-success {
                    --alert-color-rgb: @successNoticeRGB;
                  }
                  &.alert-warning {
                    --alert-color-rgb: @warningNoticeRGB;
                  }
                  &.alert-danger {
                    --alert-color-rgb: @errorNoticeRGB;
                  }
                }
              }
            }
          }
        }
      }
      .flatpickr-calendar {
        box-shadow: 0px 0px 20px @black5Color;
        border: solid 1px @borderColor;
        border-radius: @roundedCorners;

        .flatpickr-months {
          :where(.flatpickr-next-month, .flatpickr-prev-month) {
            padding: 5px 10px;
            display: flex;
            align-items: center;
            border-radius: @roundedCorners 0 0 0;
            position: initial;

            svg {
              fill: var(--color-primary-600);
            }
            &:hover {
              background-color: @accentColor10;

              svg {
                fill: var(--color-primary-600);
              }
            }
          }
          .flatpickr-next-month {
            border-radius: 0 @roundedCorners 0 0;
          }
          .flatpickr-month {
            position: initial;

            .flatpickr-current-month {
              padding-top: 0;
              position: initial;
              display: flex;
              align-items: center;
              width: 100%;

              .flatpickr-monthDropdown-months {
                flex: auto;
                line-height: 20px;
              }
              .numInputWrapper {
                flex: 1;
              }
              :where(.flatpickr-monthDropdown-months, .numInputWrapper) {
                font-weight: 600;
                font-size: 14px;
                color: var(--color-primary-600);
                display: inline-flex;
                height: 100%;
                padding: 0 12px;

                &:hover {
                  background-color: @accentColor10;
                }
              }
            }
          }
        }
        .flatpickr-innerContainer {
          .flatpickr-rContainer {
            .flatpickr-weekdays {
              .flatpickr-weekdaycontainer {
                .flatpickr-weekday {
                  font-weight: 600;
                  font-size: 14px;
                  color: var(--color-primary-600);
                }
              }
            }
            .flatpickr-days {
              .dayContainer {
                .flatpickr-day {
                  font-size: 12px;
                  border-radius: 0;
                  height: 32px;
                  line-height: 32px;
                  color: var(--color-primary-600);
                  border: none;

                  &:hover,
                  &:where(.today, .selected) {
                    background-color: @accentColor10;
                    border: none;
                  }
                  &.selected {
                    font-weight: 600;
                  }
                  &:where(.prevMonthDay, .nextMonthDay) {
                    color: @accentColor40;
                  }
                }
              }
            }
          }
        }
      }
    }

    .formio-component-dropdown {
      position: relative;
      display: inline-block;
      border-radius: 4px;

      > .card-header {
        padding: 0px 12px;
        min-width: 24px;
        min-height: 24px;
        cursor: pointer;

        > .card-title {
          padding-bottom: 0px;
          font-size: inherit;
          line-height: 1;
          
          i {
            position: initial !important;
          }
        }
      }
      > .dropdown-menu-container {
        position: fixed;
        z-index: 100;
        box-shadow: none;
        background-color: transparent;

        > .card-body {
          position: relative;
          overflow-y: auto;
          max-height: 30vh;
          padding: 4px;
          border-radius: 6px;
          background-color: #FFFFFF;
          box-shadow: 0px 16px 24px 0px rgba(48, 49, 51, 0.09), 0px 0px 1px 0px rgba(48, 49, 51, 0.05);

          > .form-group {
            margin-bottom: 0;
            border-bottom: solid 1px rgba(255,255,255,0.5);
          }
          > .formio-component-dropdown {
            display: block;
            width: 100%;

            > .dropdown-menu-container {
              position: inherit;

              > .card-body {
                > .formio-component {
                  > .card-header {
                    padding-left: calc(var(--depth-level)*15px);
                  }
                }
              }
            }
          }
        }
        .formio-component-button {
          &.dropdown-menu-item {
            border-bottom: none;
            margin-top: 0px !important;

            > button.btn {
              --xs-button-height: 40px !important;
              --sm-button-height: 40px !important;
              --md-button-height: 40px !important;
              --lg-button-height: 40px !important;
              --xl-button-height: 40px !important;
              font-size: 14px !important;
              font-weight: 400 !important;
              column-gap: 12px !important;
              width: 100%;
              border-radius: 0 !important;
              border: none !important;
              justify-content: flex-start;
              padding-inline: 16px !important;
              padding-block: 0px !important;
              --button-text-color: var(--color-gray-900);
              --button-shadow: none;
              --button-bg-color: transparent;

              span {
                color: var(--color-gray-500);
                font-size: 16px;
                min-width: 20px;
              }
              &:hover {
                --button-text-color-hover: var(--color-gray-900);
                --button-shadow-hover: none;
                --button-bg-color: var(--color-gray-50);
                opacity: 1 !important;
              }
            }
          }
          &.dropdown-separator-line {
            border-top: solid 1px var(--color-gray-100);
            margin-top: 4px !important;
            padding-top: 4px;
          }
        }
      }
      &.formio-component-label-hidden {
        > .card-header {
          padding: 0px;
          max-width: 24px;
          max-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      &:not(.formio-component-label-hidden) {
        width: fit-content;

        > .card-header {
          display: flex;
          align-items: center;
          min-height: 36px;

          > .card-title {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }
      &:not(.formio-collapsed) {
        background-color: var(--color-gray-100);
      }
      &.formio-collapsed {
        &:hover {
          background-color: var(--color-gray-100);
        }
        &:is(.btn-green, .btn-blue, .btn-orange, .btn-red) {
          &:hover {
            background-color: transparent;
          }
        }
        > .dropdown-menu-container {
          display: none;
        }
      }
      &.horizontal-align-right {
        display: block;
        width: fit-content;
        margin-inline-start: auto;
      }

      // SECTION Button shared styles
      --button-border-style: none;
      --button-border-width: 0px;
      --button-border-color: transparent;
      --button-border-color-hover: transparent;
      --button-bg-color: transparent;
      --button-bg-color-hover: var(--color-primary-50);
      --button-secondary-bg-color: var(--color-primary-50);
      --button-secondary-bg-color-hover: var(--color-primary-75);
      --button-secondary-text-color: var(--color-gray-800);
      --button-text-color: var(--color-gray-900);
      --button-text-color-hover: var(--color-gray-800);
      --button-outline-text-color: var(--color-primary-600);
      --button-outline-text-color-hover: var(--color-primary-900);
      --button-outline-bg-color: transparent;
      --button-outline-bg-color-hover: var(--color-primary-50);
      --button-outline-light-border-color: var(--color-primary-75);

      > .card-header {
        border-radius: @roundedCorners;
        background-color: var(--button-bg-color);
        border-style: var(--button-border-style);
        border-width: var(--button-border-width);
        border-color: var(--button-border-color);
        padding-inline: var(--md-button-inline-padding);
        padding-block: var(--md-button-block-padding);
        font-size: var(--md-button-font-size);
        min-height: var(--md-button-height);
        border-radius: var(--md-button-border-radius);

        > .card-title {
          color: var(--button-text-color) !important;
        }
        &:hover {
          background-color: var(--button-bg-color-hover);

          > .card-title {
            color: var(--button-text-color-hover) !important;
          }
        }
      }
      // ANCHOR Colors
      &:is(.btn-secondary, .light-color) {
        > .card-header {
          background-color: var(--button-secondary-bg-color);

          > .card-title {
            color: var(--button-secondary-text-color) !important;
          }
        }
      }
      &.outline-button {
        --button-border-style: solid;
        --button-border-width: 1px;
        --button-border-color: var(--color-primary-600);
        --button-border-color-hover: var(--color-primary-900);
        --button-bg-color: transparent;
        --button-bg-color-hover: var(--color-primary-50);
        --button-outline-bg-color: transparent;

        > .card-header {
          border: solid 1px var(--button-border-color);
          background-color: var(--button-outline-bg-color);

          > .card-title {
            color: var(--button-outline-text-color) !important;
          }
          &:hover {
            background-color: var(--button-outline-bg-color-hover) !important;

            > .card-title {
              color: var(--button-outline-text-color-hover);
            }
          }
        }
        &:not(.formio-collapsed) {
          background-color: transparent;

          &:hover {
            background-color: transparent;
          }
          > .card-header {
            background-color: var(--button-outline-bg-color);
          }
        }
        &:hover {
          background-color: transparent;
        }
        &:is(.btn-secondary, .light-color) {
          > .card-header {
            border-color: var(--button-outline-light-border-color);
          }
        }
      }
      &.btn-green {
        --button-border-color: #10B981;
        --button-outline-text-color: #10B981;
        --button-text-color: #FFFFFF;
        --button-text-color-hover: #FFFFFF;
        --button-secondary-bg-color: rgba(@successNoticeRGB, 0.35);
        --button-outline-bg-color-hover: rgba(@successNoticeRGB, 0.05);
        --button-bg-color-hover: rgba(@successNoticeRGB, 0.9);
      }
      &.btn-blue {
        --button-border-color: @infoNotice;
        --button-outline-text-color: @infoNotice;
        --button-text-color: #FFFFFF;
        --button-text-color-hover: #FFFFFF;
        --button-secondary-bg-color: rgba(@infoNoticeRGB, 0.35);
        --button-outline-bg-color-hover: rgba(@infoNoticeRGB, 0.05);
        --button-bg-color-hover: rgba(@infoNoticeRGB, 0.9);
      }
      &.btn-orange {
        --button-border-color: @warningNotice;
        --button-outline-text-color: @warningNotice;
        --button-text-color: #FFFFFF;
        --button-text-color-hover: #FFFFFF;
        --button-secondary-bg-color: rgba(@warningNoticeRGB, 0.5);
        --button-outline-bg-color-hover: rgba(@warningNoticeRGB, 0.05);
        --button-bg-color-hover: rgba(@warningNoticeRGB, 0.9);
      }
      &.btn-red {
        --button-border-color: @errorNotice;
        --button-outline-text-color: @errorNotice;
        --button-text-color: #FFFFFF;
        --button-text-color-hover: #FFFFFF;
        --button-secondary-bg-color: rgba(@errorNoticeRGB, 0.35);
        --button-outline-bg-color-hover: rgba(@errorNoticeRGB, 0.05);
        --button-bg-color-hover: rgba(@errorNoticeRGB, 0.9);
      }
      // ANCHOR Layout
      &.btn-block {
        width: 100%;
      }
      // ANCHOR Sizes
      &.size-xs {
        > .card-header {
          padding-inline: var(--xs-button-inline-padding);
          padding-block: var(--xs-button-block-padding);
          font-size: var(--xs-button-font-size);
          min-height: var(--xs-button-height);
          border-radius: var(--xs-button-border-radius);
        }
      }
      &.size-sm {
        > .card-header {
          padding-inline: var(--sm-button-inline-padding);
          padding-block: var(--sm-button-block-padding);
          font-size: var(--sm-button-font-size);
          min-height: var(--sm-button-height);
          border-radius: var(--sm-button-border-radius);
        }
      }
      &.size-md {
        > .card-header {
          padding-inline: var(--md-button-inline-padding);
          padding-block: var(--md-button-block-padding);
          font-size: var(--md-button-font-size);
          min-height: var(--md-button-height);
          border-radius: var(--md-button-border-radius);
        }
      }
      &.size-lg {
        > .card-header {
          padding-inline: var(--lg-button-inline-padding);
          padding-block: var(--lg-button-block-padding);
          font-size: var(--lg-button-font-size);
          min-height: var(--lg-button-height);
          border-radius: var(--lg-button-border-radius);
        }
      }
      &.size-xl {
        > .card-header {
          padding-inline: var(--xl-button-inline-padding);
          padding-block: var(--xl-button-block-padding);
          font-size: var(--xl-button-font-size);
          min-height: var(--xl-button-height);
          border-radius: var(--xl-button-border-radius);
        }
      }
    }

    // Generic form components Part A - Part B processing forms
    .formio-component {
      --field-height: @fieldHeight;
      --field-font-size: 16px;
      color: var(--color-gray-900);
      margin-top: 0;
      margin-bottom: 0;

      // Spacing custom classes
      &.top-space-05 {
        margin-top: 15px !important;

        &.top-label-space {
          margin-top: calc(15px + 29px) !important;
        }
      }
      &.top-space {
        margin-top: 30px !important;

        &.top-label-space {
          margin-top: calc(30px + 29px) !important;
        }
      }
      &.top-space-2x {
        margin-top: 60px !important;

        &.top-label-space {
          margin-top: calc(60px + 29px) !important;
        }
      }
      &.top-space-3x {
        margin-top: 90px !important;

        &.top-label-space {
          margin-top: calc(90px + 29px) !important;
        }
      }
      &.top-label-space {
        margin-top: 29px !important;
      }
      &.margin-top-zero {
        margin-top: 0 !important;

        &.dropdown-separator-line {
          margin-top: 4px !important;
        }
      }
      &:is(.formio-hidden, .hide, .formio-component-hidden):first-child { // Re-search the actual first visible component
        & ~ .formio-component:not(.formio-hidden, .hide, .formio-component-hidden) {
          margin-top: 0px;

          // Restore the adjacent margin rule
          & ~ .formio-component {
            margin-top: @componentSeparation;
          }
        }
      }
      &.formio-component-columns:has(>[class^="col-"]>.formio-component-textfield>label):has([class^="col-"]>.formio-component-button:not(.margin-top-zero,.top-space-05,.top-space,.top-space-2x,.top-space-3x))>[class^="col-"]>.formio-component-button:not(.margin-top-zero,.top-space-05,.top-space,.top-space-2x,.top-space-3x) {
        margin-top: 29px;
      }
      // Custom component spacing rules
      &.formio-component-checkbox {
        &:is(.hide, .formio-hidden) {
          &:first-child ~ .formio-component-checkbox:not(.formio-hidden, .hide) {
            margin-top: 0px;

            & ~ .formio-component-checkbox {
              margin-top: 1rem;
            }
          }
        }
      }
      &.readonly-form-columns {
        margin-top: 0px;
      }
      // Other classes
      &.semibold {
        --field-font-weight: 600;
      }
      // Formio alert errors (Send back alerts)
      &:is(.formio-component-htmlelement, .formio-component-content) {
        &.alert {
          &.alert-danger {
            border-radius: @roundedCorners;
            padding: 24px !important;
            background-color: @errorNotice15Solid !important;
            max-width: 100%;
            font-weight: 400 !important;
            font-size: 16px;
            color: @errorNotice !important;

            p {
              font-size: @defaultTextSize !important;
              color: var(--custom-text-color, @darkTextColor);
              margin-top: 0;
              margin-bottom: 0;

              span {
                font-size: @defaultTextSize;
                color: @darkTextColor;

                &.text-tiny {
                  font-size: 9px;

                  * {
                    font-size: 9px;
                  }
                  span {
                    background-color: transparent !important;
                  }
                }
                &.text-small {
                  font-size: 11px;

                  * {
                    font-size: 11px;
                  }
                }
                &.text-big {
                  font-size: 18px;

                  * {
                    font-size: 18px;
                  }
                }
                &.text-huge {
                  font-size: 24px;

                  * {
                    font-size: 24px;
                  }
                }
                &.currency-prefixed {
                  margin-inline-end: 5px;
                }
                &.currency-suffixed {
                  &:not(:empty) {
                    margin-inline-start: 5px;
                  }
                }
              }
              &:empty + p {
                margin-top: 0;
              }
              &:first-child {
                margin-top: 0;
              }
              &.correction-reason {
                --custom-text-color: @warningNew;

                span {
                  color: inherit;
                }
              }
            }
            .separate-colon {
              margin-inline-end: 4px;
            }
            &.general-rejection-reason {
              width: 100%;
              background-color: @warningNewLight !important;

              div {
                &:empty {
                  display: none;
                }
                &:last-of-type {
                  margin-bottom: 0;
                }
              }
            }
            &[class*="RejectionReasons"] {
              padding: 2rem !important;
              color: @errorNotice !important;

              div + div:not(:empty) {
                margin-top: 0.6rem;
              }
            }
            &[class*="documentRejectionReasons"] {
              p + div {
                font-size: 16px;
                font-weight: 600;
              }
            }
            &::after {
              display: none !important;
            }
          }
        }
      }
      .formio-errors.invalid-feedback:empty {
        display: none;
      }
    }
    :is(.formio-component-modal-wrapper, .formio-component) {
      // Spacing auto rules
      & + :is(.formio-component-modal-wrapper, .formio-component) {
        margin-top: @componentSeparation;
      }
    }

    :is(#formio-form-wizard, #form-type-role, #formio-form-role, #my-account-page, [class*="editgrid-row-modal-"]) {
      &.tabbed-modern-nav {
        position: relative;
        z-index: 1030;

        > .formio-component-modal-wrapper,
        > .formio-form {
          > div {
            > nav {
              .pagination {
                border: none;
                flex-wrap: nowrap;
                overflow-x: auto;
                border-radius: 0;
                overflow-y: hidden;

                li {
                  &.page-item {
                    flex: 1;
                    max-width: fit-content;
                    min-width: fit-content;
                    margin: 0px;

                    &.active {
                      position: sticky;
                      inset-inline-start: 0px;
                      inset-inline-end: 0px;
                      z-index: 9;
                    }
                  }
                }
              }
              .regs-docs-fees-cart {
                border: solid 1px @accentColor30;
                background-color: @whiteColor;
                align-self: center;
                margin-top: 0;
                height: 36px;
                box-shadow: none;
                position: relative;

                &::before {
                  border-inline-end: none;
                  background-color: @accentColor10;
                }
                &.open {
                  &::before {
                    border-inline-end: none;
                    background-color: transparent;
                  }
                }
              }
              .regs-docs-fees-drawer {
                top: calc(50% + (var(--wizard-page-menu-height, 50px) / 2));
                box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
              }
            }
          }
        }
      }
      .formio-component-modal-wrapper .formio-component,
      &:not(.new-formio-part-b) .formio-form .formio-component,
      &:not(.new-formio-part-b) .formio-form .formio-dialog,
      &.new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component,
      &.new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component,
      .formio-dialog-content .formio-component {
        &.formio-component-modal-wrapper-datagrid {
          .formio-dialog.formio-dialog-theme-default {
            padding-top: 136px;
            transition-duration: 0.15s;
            transition-property: padding-top;
          }
        }
        a {
          &[href] {
            color: var(--color-primary-600);
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }
        :where(label, label.col-form-label) {
          color: @darkTextColor70;
          font-size: 13px;
          font-weight: 600;
          margin-bottom: 0px;
          line-height: 19px;

          :where(input, select) {
            border-radius: @roundedCorners !important;
            border-color: transparent;
            display: block;
            width: 100%;
            margin-top: 10px;
            padding: 2px 4px;

            & + .formio-button-reset {
              color: transparent;
              font-size: 0px !important;
              font-weight: 400;
              padding: 0 !important;
              background: transparent;
              position: absolute;
              inset-inline-end: 0;
              bottom: 6px;
              min-height: 10px !important;
              line-height: 1 !important;
              box-shadow: none !important;

              &::before {
                font-family: var(--fontawesome-font);
                font-weight: 400;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                content: "\f00d";
                color: var(--color-gray-400);
                font-size: 16px;
                transform: translateY(4px) translateX(-6px);
                border-radius: 20px;
                width: 20px;
                height: 20px;
                display: grid;
                place-content: center;
              }
              &:hover {
                &::before {
                  background-color: var(--color-gray-100);
                  color: var(--color-gray-700);
                }
              }
            }
          }
          &.radio {
            padding-inline-start: 0;
          }
          &.field-required {
            &::after {
              content: " ";
              display: inline-block;
              background-color: @errorColor70;
              vertical-align: unset;
              width: 6px;
              height: 6px;
              border-radius: 5px;
              position: relative;
              top: -1px;
              inset-inline-start: 2px;
              min-width: 6px;
            }
            i {
              margin-inline-start: 11px;

              &::before {
                margin-inline-start: 3px !important;
              }
            }
            &.control-label--hidden {
              position: absolute;
              top: calc(var(--field-height) / 2 + var(--custom-top-space, 0px));
              inset-inline-end: 0;
              right: initial;

              &::after {
                inset-inline-end: 10px;
                inset-inline-start: initial;
                position: absolute;
                top: -3px;
              }
            }
          }
          i {
            &.fa-question-circle {
              margin-top: -4px;
              margin-inline-end: -31px;
              transform: translateY(1px);
            }
          }
          &:not(.sr-only) {
            position: relative;
          }
        }
        input {
          &.form-control {
            &:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]) {
              height: var(--field-height);

              &:where(:hover, :focus) {
                outline: none;
              }
              &:disabled {
                background-color: var(--color-gray-100);
              }
            }
          }
        }
        .fa-question-circle {
          &::before {
            font-weight: 400;
            font-size: 15px;
            color: var(--color-primary-600);
          }
        }
        .form-text {
          &.text-muted {
            &:not(:empty) {
              margin-top: 6px;
              font-size: 12px;
              text-align: start;
              display: block;
              line-height: 1.2;
              color: var(--color-primary-600);

              & + .formio-errors:not(:empty) {
                margin-top: 10px;
              }
            }
          }
        }
        &:where(.has-error, .formio-error-wrapper) {
          border-radius: @roundedCorners;

          .formio-errors {
            .form-text {
              font-weight: 400;
              margin-top: 6px;
              margin-bottom: 0;
              font-size: 12px;
              text-align: start;
              display: block;
              line-height: 1.2;
              white-space: break-spaces;

              &.error {
                color: @errorColor70;
              }
            }
          }
          &:focus-within {
            animation: hglightComp 1s ease-out 0.15s;
          }
        }
        .formio-error-wrapper {
          &.formio-component-radio {
            &.radio-switch {
              .form-radio.radio {
                border-color: @errorColor70;
              }
            }
          }
          &:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) {
            .form-control {
              border-color: @errorColor70;
              --fields-border-color: @errorColor70;
            }
          }
          &.formio-component-select {
            .form-control {
              border-color: @errorColor70;
              --fields-border-color: @errorColor70;
            }
          }
          &:is(.has-suffix, .has-prefix) {
            :is(.form-control, .input-group-append, .input-group-prepend) {
              border-color: @errorColor70 !important;
              --fields-border-color: @errorColor70;
            }
          }
        }
        &:where(.has-prefix, .has-suffix) {
          :is(.input-group-prepend, .input-group-append) {
            transition: border-color ease-in-out .15s;
          }
          &:has(.form-control:focus) {
            :is(.input-group-prepend, .input-group-append) {
              border-color: @borderColorFocus;
            }
          }
        }
        .field-wrapper {
          align-items: center;

          label {
            &.col-form-label {
              margin-bottom: 0 !important;
            }
          }
        }
        &:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-url, .formio-component-address, .formio-component-datetime) {
          input {
            &.form-control {
              &:where([type="text"], [type="phoneNumber"], [type="email"]) {
                border: solid 1px var(--fields-border-color, @borderColor);
                border-radius: @roundedCorners !important;
                font-weight: 400;
                text-align: start;

                &:where(:hover, :focus) {
                  outline: none !important;
                }
                &:focus {
                  border-color: @borderColorFocus;
                }
              }
              & + .form-text.text-right {
                font-size: 1.2rem;
                padding-top: 4px;
              }
            }
          }
          &.strong {
            --field-font-weight: 700;
          }
          .input-group {
            height: var(--field-height);

            > div {
              border: solid 1px var(--fields-border-color, @borderColor);
              background-color: @whiteColor !important;

              &.input-group-prepend {
                border-radius: @roundedCorners 0 0 @roundedCorners !important;
                color: @darkTextColor;
                font-weight: 600;

                & + input {
                  &.form-control {
                    border-top-left-radius: 0 !important;
                    border-bottom-left-radius: 0 !important;
                    border-inline-start: none !important;
                  }
                }
              }
              &.input-group-append {
                border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                color: @darkTextColor;
                font-weight: 600;
              }
            }
            input {
              &.form-control {
                &:first-child {
                  &:not(:last-child) {
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                    border-inline-end: 0;
                  }
                }
                &:not(:first-child) {
                  &:not(:last-child) {
                    border-top-right-radius: 0 !important;
                    border-bottom-right-radius: 0 !important;
                    border-inline-end: none;
                  }
                }
              }
            }
            &.formio-multiple-mask-container {
              display: flex;

              .formio-multiple-mask-select {
                width: fit-content;
                max-width: 50%;
                height: 40px !important;
                border-radius: @roundedCorners 0 0 @roundedCorners !important;
                border-inline-end: none;

                & + .formio-multiple-mask-input {
                  border-radius: 0 @roundedCorners @roundedCorners 0 !important;
                }
                &:where(:hover, :focus, :active) {
                  box-shadow: none;
                  outline: none;
                  background-color: @accentColor5;
                }
              }
            }
          }
          &.value-label-style {
            input {
              &.form-control:is([type="text"], [type="phoneNumber"], [type="email"]) {
                border: none !important;
                background-color: transparent !important;
                border-radius: 0px !important;
              }
            }
            &.required {
              label.field-required.control-label--hidden {
                &::after {
                  top: -1px;
                  inset-inline-end: 0px;
                }
                & + div {
                  input.form-control {
                    padding-inline-end: 16px;
                  }
                }
              }
            }
            &:is(.has-prefix, .has-suffix) {
              :is(.input-group-prepend, .input-group-append) {
                border: none;
                --start-padding: 14px;
                --end-padding: 14px;

                .input-group-text {
                  padding-inline-start: var(--start-padding);
                  padding-inline-end: var(--end-padding);
                }
              }
              .input-group-prepend {
                --start-padding: 0px;
              }
              .input-group-append {
                --end-padding: 0px;
              }
            }
          }
          &.field-plus-button {
            input {
              &.form-control {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
              }
            }
          }
          &.has-error {
            .input-group {
              > div {
                border-color: @errorColor30;
              }
              &.formio-multiple-mask-container {
                .formio-multiple-mask-select {
                  border-color: @errorColor30 !important;
                }
              }
            }
            &:focus-within {
              :is(.input-group-append, .input-group-prepend) {
                border-color: @errorColor30;
              }
            }
            input {
              &.form-control {
                border-color: @errorColor30 !important;
              }
            }
          }
          label.col-form-label {
            &:not(:first-child) {
              margin-bottom: 0;
              margin-top: 6px;
            }
          }
        }
        &.formio-component-day {
          input.formio-select-autocomplete-input {
            position: absolute;
          }
          .row {
            .col {
              flex: 1;

              .form-control {
                border-radius: @roundedCorners !important;
              }
            }
          }
        }
        &.formio-component-select {
          .formio-choices {
            .selection {
              &.dropdown {
                border: solid 1px var(--fields-border-color, @borderColor);
                border-radius: @roundedCorners !important;
                height: var(--field-height);
                display: flex;
                align-items: center;
                padding-inline-end: 36px;
                padding-inline-start: 14px;
                padding-block: 6px;

                &:where(:hover, :focus) {
                  outline: none;
                }
                &[disabled] {
                  background-color: var(--color-gray-100);

                  .choices__item--selectable {
                    padding-inline-end: 0px;
                  }
                }
              }
            }
            &.is-open {
              .selection {
                &.dropdown {
                  border-radius: @roundedCorners @roundedCorners 0 0 !important;
                }
              }
              &.is-flipped {
                .selection {
                  &.dropdown {
                    border-radius: 0 0 @roundedCorners @roundedCorners !important;
                  }
                }
                .choices__list.choices__list--dropdown {
                  border-radius: @roundedCorners @roundedCorners 0 0;
                }
              }
              .choices__list.choices__list--dropdown {
                border-color: @borderColorFocus;
              }
            }
            &.is-focused {
              .selection {
                &.dropdown {
                  border-color: @borderColorFocus;
                }
              }
            }
            &::after {
              color: @accentColor40;
              content: "\F078";
              font-family: var(--fontawesome-font);
              font-weight: 300;
              -moz-osx-font-smoothing: grayscale;
              -webkit-font-smoothing: antialiased;
              display: inline-block;
              font-style: normal;
              font-variant: normal;
              text-rendering: auto;
              font-size: 16px;
              width: auto;
              border: none;
              margin-top: 0;
              height: 8px;
              line-height: 7px;
              top: calc(50% - 4px);
            }
            &:hover {
              &::after {
                color: var(--color-primary-600);
              }
            }
            .choices__list {
              &.choices__list--dropdown {
                border: solid 1px @borderColor;
                border-radius: 0 0 @roundedCorners @roundedCorners;

                input {
                  &.choices__input {
                    border-radius: 0 !important;
                    padding: 10px 14px;
                    font-size: 12px;
                    border-bottom-color: @borderColor;

                    &:placeholder-shown {
                      opacity: 0.5;
                    }
                  }
                }
                .choices__item {
                  &.choices__item--choice {
                    padding: 10px 14px;
                    font-size: 13px;
                    font-weight: 400;
                    color: @darkTextColor;
                    word-break: break-word;
                    white-space: break-spaces;
                    text-align: start;

                    &:where(.is-selected, :hover) {
                      background-color: @accentColor5;
                    }
                    span {
                      line-height: 1.2;

                      mark {
                        background-color: #FEFF00;
                        padding: 0;
                        display: inline-block;
                        line-height: 1.1;
                      }
                    }
                    > span {
                      display: flex;
                      gap: 12px;

                      span {
                        min-width: 32px;
                        flex: 1;

                        & + span {
                          width: auto;
                          flex: 10;
                        }
                      }
                      &.value {
                        > span {
                          width: 100%;
                        }
                      }
                    }
                    .tooltip {
                      display: none !important;
                    }
                    [ref="tooltip"] {
                      &::before, &::after {
                        display: none;
                      }
                    }
                  }
                  &.is-highlighted {
                    background-color: var(--color-gray-75);
                  }
                }
                .choices__list {
                  max-height: clamp(200px, 500px, calc(50vh - 110px));
                }
              }
              &--single {
                padding-inline-start: 0px;

                .choices__item {
                  .value {
                    &:not(:last-child) {
                      padding-inline-end: 15px;
                    }
                    &[ref="tooltip"] {
                      &:hover {
                        &::after,
                        &::before {
                          display: none;
                        }
                      }
                    }
                  }
                  &--selectable {
                    padding-right: initial;
                    padding-inline-end: 14px;
                    position: relative;
                    line-height: 1.5;
                    text-align: start;

                    span.key {
                      font-size: 16px;
                      font-weight: 600;

                      & + .value {
                        padding-inline-start: 6px;
                      }
                    }
                    .choices__button {
                      inset-inline-end: 0px !important;
                      margin-right: initial;
                      margin-left: initial;
                    }
                    // TOBE-15198
                    // span:not(.value) + .choices__button {
                    //   inset-inline-end: 24px !important;
                    // }
                  }
                }
              }
            }
            &.is-disabled {
              .selection {
                &.dropdown {
                  background-color: var(--color-gray-100);
                }
              }
              &::after {
                display: none;
              }
            }
          }
          input.formio-select-autocomplete-input {
            position: absolute;
          }
          &.has-error {
            .formio-choices {
              .selection {
                &.dropdown {
                  border-color: @errorColor30 !important;
                }
              }
              .choices__list.choices__list--dropdown {
                border-color: @errorColor30;
              }
            }
          }
          &:not(.formio-modified) {
            .control-label--hidden {
              &.field-required {
                inset-inline-end: 0px;
              }
            }
          }
          &.formio-component-multiple {
            .formio-choices {
              .selection {
                &.dropdown {
                  min-height: 40px;
                  height: auto;
                  flex-wrap: wrap;
                  padding-inline-end: 40px;
                  gap: 6px;

                  .choices__input {
                    flex: 1;
                    background: transparent;
                    height: 28px !important;
                    margin-bottom: 0;
                    min-width: 50px;
                  }
                  .choices__list--multiple {
                    display: contents;

                    &:empty {
                      flex: 0;
                    }
                    :where(.choices__item--selectable, .choices__item.is-highlighted) {
                      overflow: hidden;
                      max-width: calc(100% - 28px);
                      min-width: 0px;
                      position: relative;
                      background-color: @accentColor10Solid;
                      color: var(--color-primary-600);
                      font-weight: 400;
                      border-radius: @roundedCorners;
                      padding-block: 4px;
                      padding-inline-start: 7px;
                      padding-inline-end: 28px;
                      border-color: @accentColor20;
                      margin: 0;

                      > span {
                        text-overflow: ellipsis;
                        font-size: 15px;
                      }
                      button.choices__button {
                        position: absolute;
                        inset-inline-end: 0;
                        margin: 0;
                        border: none;
                        border-radius: 0 4px 4px 0;
                        display: flex;
                        top: 0;
                        height: 100%;
                        box-shadow: -1px 0 0 @accentColor40;
                        filter: brightness(0.5);
                      }
                      &[data-value="[]"] {
                        display: none;
                      }
                    }
                    .choices__item.is-highlighted {
                      background-color: var(--color-primary-600);
                      color: @whiteColor;

                      button.choices__button {
                        filter: brightness(1);
                      }
                    }
                  }
                }
              }
              &:not(.is-open) {
                .selection {
                  &.dropdown {
                    .choices__input {
                      display: none;
                    }
                  }
                }
              }
              &::after {
                position: absolute;
                inset-inline-end: 14px;
              }
            }
          }
          &.field-plus-button {
            .formio-choices {
              .selection {
                &.dropdown {
                  border-radius: @roundedCorners 0 0 @roundedCorners !important;
                }
              }
            }
          }
          &.align-value-right {
            .formio-choices .selection.dropdown {
              flex-direction: row-reverse;

              .choices__list--multiple {
                text-align: end;
                padding-inline-end: 25px;
              }
            }
            .choices__item {
              text-align: end;
            }
          }
          &.value-label-style {
            .formio-choices {
              .form-control.selection {
                border: none;
                padding: 0;
                height: auto;
                line-height: 1;
                height: 40px;
                background: transparent;
                .choices__item {
                  &.choices__item--selectable {
                    overflow: visible;
                    font-size: var(--field-font-size);
                  }
                }
              }
            }
          }
          &.required {
            .choices__button {
              right: initial;
              inset-inline-end: 8px;
            }
            .choices__item--selectable {
              padding-inline-end: 40px;
            }
            label {
              &.field-required {
                &.control-label--hidden {
                  &::after {
                    inset-inline-end: 70px;
                  }
                }
              }
            }
            &:has(select:empty) {
              label {
                &.field-required {
                  &.control-label--hidden {
                    &::after {
                      inset-inline-end: 44px;
                    }
                  }
                }
              }
            }
            .formio-choices {
              .choices__list {
                &--single {
                  .choices__item {
                    &--selectable {
                      padding-inline-end: 40px;
                    }
                  }
                }
              }
            }
          }
          .choices__heading {
            background-color: @accentColor075;
            min-height: 32px;
            padding: 8px 14px;
            color: var(--color-primary-600);
            font-weight: 700;
            text-transform: uppercase;
            font-size: 12px;
            line-height: 1.3;
            align-items: center;
            display: flex;

            .value {
              font-size: inherit;
            }
          }
        }
        &.formio-component-datetime {
          .input-group {
            display: flex;

            input {
              &.form-control.input {
                &:not([disabled]) {
                  border: solid 1px @borderColor;
                  border-radius: @roundedCorners 0 0 @roundedCorners !important;
                  border-inline-end: none;
                  padding-inline-end: 0;

                  &:focus {
                    border-color: @borderColorFocus;

                    & ~ .input-group-append {
                      border-color: @borderColorFocus;
                    }
                  }
                }
                &[type="text"][disabled] {
                  border-radius: @roundedCorners !important;
                  border-inline-end: solid 1px @borderColor;

                  & ~ .input-group-append {
                    display: none;
                  }
                }
              }
            }
            .input-group-append {
              border: solid 1px @borderColor;
              border-radius: 0 @roundedCorners @roundedCorners 0 !important;
              height: var(--field-height);
              border-inline-start: none;
              cursor: pointer;
              transition: border-color ease-in-out .15s;
              display: inline-flex;

              .input-group-text {
                display: flex;
                height: 100%;
                align-items: center;
                border-radius: 0 6px 6px 0;
                border: none;
                padding: 0px 14px;

                i {
                  margin-inline-end: 0px;

                  &::before {
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 400;
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-font-smoothing: antialiased;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    line-height: 1;
                    content: "\f073";
                    color: @accentColor40;
                  }
                }
              }
            }
          }
          .input-group-text {
            font-size: 14px;
          }
          &:hover {
            .input-group {
              .input-group-append {
                .input-group-text {
                  i {
                    &::before {
                      color: var(--color-primary-600);
                    }
                  }
                }
              }
            }
          }
          &.has-error {
            .input-group {
              input {
                &.form-control.input {
                  border-color: @errorColor30 !important;
                }
              }
              .input-group-append {
                border-color: @errorColor30 !important;
              }
            }
          }
          &.value-label-style {
            .input-group {
              padding-inline-start: 0px;
            }
            input {
              &:where(.form-control, .input) {
                border: none !important;
                border-radius: 0px !important;

                &:where([disabled], :disabled) {
                  background-color: transparent !important;
                }
              }
            }
            .input-group-append {
              display: none;
            }
          }
          label {
            &.field-required {
              &.control-label--hidden {
                &::after {
                  inset-inline-end: 36px;
                }
              }
            }
          }
        }
        &.formio-component-radio {
          .form-radio {
            &.radio {
              padding-top: 14px;
              margin-top: 0;

              :where(.form-check, .form-check-inline) {
                cursor: pointer;
                margin-top: 0;
                margin-bottom: 0;

                label {
                  margin-top: 0;

                  &::before {
                    display: none;
                  }
                  input {
                    &[type="radio"] {
                      appearance: inherit;
                      padding: 0;
                      margin: -1px 0 0;

                      &::before {
                        -webkit-font-smoothing: antialiased;
                        display: inline-block;
                        font-style: normal;
                        font-variant: normal;
                        text-rendering: auto;
                        line-height: 1;
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 400;
                        font-size: @defaultTextSize;
                        content: "\f111";
                        color: @accentColor50;
                      }
                      & + span {
                        font-size: @defaultTextSize;
                        font-weight: 500;
                        color: @accentColor50;
                        margin-inline-start: 6px;
                        position: relative;
                        top: 2px;
                        line-height: 14px;
                      }
                      &:where([checked], :checked) {
                        &::before {
                          content: "\f192" !important;
                          font-weight: 900;
                          color: var(--color-primary-600) !important;
                        }
                        & + span {
                          color: var(--color-primary-600) !important;
                        }
                      }
                      &:focus {
                        outline: none;
                      }
                      &[disabled] {
                        border-radius: 50%;

                        &::before {
                          color: var(--color-gray-300) !important;
                        }
                        &:hover {
                          &::before {
                            color: var(--color-gray-300) !important;
                          }
                        }
                      }
                    }
                  }
                  &:hover {
                    input {
                      &[type="radio"] {
                        appearance: inherit;

                        &::before {
                          color: var(--color-primary-600) !important;
                        }
                        & + span {
                          color: var(--color-primary-600) !important;
                        }
                      }
                    }
                  }
                  &.label-position-left {
                    padding-inline-start: 0px;

                    span {
                      margin-inline: 0px !important;
                      margin-inline-end: 12px !important;
                    }
                    input.form-check-input {
                      position: initial;
                    }
                  }
                  &.label-position-right {
                    padding-inline-start: 26px;

                    span {
                      margin-inline: 0 !important;
                    }
                  }
                }
                &:last-of-type {
                  padding-bottom: 0px;
                }
              }
              .radio.form-check {
                label[class*="label-position-"] {
                  display: flex;

                  input.form-check-input {
                    width: initial;
                    margin: -1px 0;
                  }
                }
              }
            }
          }
          &.five-faces-rating {
            .form-radio {
              column-gap: 16px !important;
              margin-top: 12px !important;
              .form-check-inline {
                label {
                  padding-inline-start: 0 !important;
                  &:after,
                  &:before {
                    cursor: pointer;
                    display: inline;
                    font-family: "Font Awesome 6 Duotone";
                    font-size: 42px !important;
                    font-weight: 600;
                    line-height: 19px;
                    color: var(--color-gray-500)!important;
                    transition: color .2s;
                  }
                  &:after {
                    opacity: 0.4;
                    position: absolute;
                    inset-inline-start: 0;
                  }
                  &:hover {
                    &:after,
                    &:before {
                      color: var(--color-gray-700)!important;
                      transition: color .2s;
                    }
                  }
                  span {
                    display: none !important;
                  }
                  input {
                    visibility: hidden;
                  }
                }
                &:nth-child(1) {
                  label {
                    &:before {
                      content: "\e376";
                    }
                    &:after {
                      content: "\e376\e376";
                    }
                  }
                }
                &:nth-child(2) {
                  label {
                    &:before {
                      content: "\e47e";
                    }
                    &:after {
                      content: "\e47e\e47e";
                    }
                  }
                }
                &:nth-child(3) {
                  label {
                    &:before {
                      content: "\f11a";
                    }
                    &:after {
                      content: "\f11a\f11a";
                    }
                  }
                }
                &:nth-child(4) {
                  label {
                    &:before {
                      content: "\f118";
                    }
                    &:after {
                      content: "\f118\f118";
                    }
                  }
                }
                &:nth-child(5) {
                  label {
                    &:before {
                      content: "\f599";
                    }
                    &:after {
                      content: "\f599\f599";
                    }
                  }
                }
                &.radio-selected {
                  label {
                    &:before,
                    &:after {
                      color: var(--color-primary-600)!important;
                    }
                  }
                }
              }
            }
          }
          &.five-stars-rating {
            .form-radio {
              column-gap: 0px !important;
              margin-top: 12px !important;
              flex-direction: row-reverse !important;
              justify-content: flex-end !important;
          
              .form-check-inline {
                margin-right: 0;
              }
          
              .form-check-input {
                position: absolute;
                opacity: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;
              }
          
              .form-check-label {
                padding-inline-start: 0 !important;
                position: relative;
                width: 1em;
                height: 1em;
          
                &::before {
                  content: "\f005"; /* Font Awesome star icon */
                  font-family: 'Font Awesome 6 Pro';
                  font-weight: 900;
                  font-size: 2em;
                  color: var(--color-amber-200);
                  cursor: pointer;
                  display: inline-block;
                  width: 100%;
                  height: 100%;
                  text-align: center;
                  line-height: 1;
                  transition: color 0.2s; /* Add a smooth transition */
                }
          
                span {
                  display: none !important;
                }
              }
          
              /* Hover Styling (Highlights all previous stars) */
              .form-check-inline:hover ~ .form-check-inline .form-check-label::before,
              .form-check-inline:hover .form-check-label::before {
                color: var(--color-amber-400);
              }
          
              /*Checked Styling (Highlights the checked star and all previous) */
              .form-check-inline:has(.form-check-input:checked) ~ .form-check-inline .form-check-label::before,
              .form-check-inline:has(.form-check-input:checked) .form-check-label::before,
              .form-check-input:checked ~ .form-check-inline .form-check-label::before {
                color: var(--color-amber-400);
              }
          
              .form-check-input:checked + .form-check-inline .form-check-label::before {
                color: var(--color-amber-400);
              }
          
              /* Styling for hover on checked stars.  Ensure the hovered one changes to a lighter gold */
              .form-check-inline:hover > .form-check-input:checked ~ .form-check-inline .form-check-label::before {
                  color: var(--color-amber-300);
              }
              .form-check-inline:hover > .form-check-label::before {
                color: var(--color-amber-300);
              }
          
            }
          }
          &.radio-switch {
            .form-radio {
              &.radio {
                height: var(--field-height);
                border-radius: @roundedCorners;
                border: solid 1px @borderColor;
                width: fit-content !important;
                width: -moz-fit-content !important;
                padding-top: 0;
                display: flex;

                :where(.form-check-inline, .form-check) {
                  margin: 0;
                  flex: auto;
                  height: 100%;
                  padding: 0;
                  display: flex;
                  align-items: center;

                  label {
                    &.form-check-label {
                      display: flex;
                      justify-content: center;
                      height: 100%;
                      padding-inline-start: 0;

                      input {
                        &[type="radio"] {
                          display: none;

                          &:checked {
                            & + span {
                              background-color: var(--color-primary-600);
                              color: @whiteColor !important;
                              border: solid 1px var(--color-primary-600);
                              margin: -1px;
                              height: calc(100% + 2px);
                              width: calc(100% + 2px);
                            }
                          }
                          &[disabled] {
                            & + span {
                              background-color: var(--color-gray-75);
                              color: var(--color-gray-500) !important;
                              cursor: not-allowed;
                            }
                            &:checked + span {
                              background-color: var(--color-gray-200);
                              color: var(--color-gray-500) !important;
                              border: solid 1px var(--color-gray-300);
                              border-inline-start: none;
                            }
                          }
                        }
                      }
                      span {
                        margin: 0;
                        display: flex;
                        height: 100%;
                        align-items: center;
                        cursor: pointer;
                        padding: 1px 15px;
                        width: 100%;
                        top: 0;
                        color: var(--color-gray-400);
                        border-inline-end: solid 1px @borderColor;

                        &:hover {
                          background-color: var(--color-primary-50);
                        }
                      }
                    }
                  }
                  &:first-child {
                    label {
                      &.form-check-label {
                        span {
                          border-radius: var(--radio-switch-first-border-radius, @roundedCorners 0 0 @roundedCorners);
                        }
                      }
                    }
                  }
                  &:last-child {
                    label {
                      &.form-check-label {
                        span {
                          border-radius: var(--radio-switch-last-border-radius, 0 @roundedCorners @roundedCorners 0);
                          border-inline-end: none !important;
                        }
                      }
                    }
                  }
                  &.radio-selected {
                    label {
                      &.form-check-label span {
                        background-color: @accentColor;
                        color: @whiteColor !important;
                      }
                    }
                  }
                }
              }
            }
            &.required {
              label {
                &.field-required {
                  &.control-label--hidden {
                    display: none;

                    &::after {
                      display: none;
                    }
                  }
                  &:not(.control-label--hidden) {
                    & + .form-radio.radio {
                      &::before {
                        display: none;
                      }
                    }
                  }
                }
              }
              .form-radio {
                &.radio {
                  &::before {
                    content: " ";
                    display: inline-block;
                    background-color: @errorColor70;
                    width: 6px;
                    height: 6px;
                    border-radius: 5px;
                    position: absolute;
                    inset-inline-end: -16px;
                    top: calc(50% - 3px);
                  }
                  &::after {
                    display: none !important;
                  }
                }
              }
            }
            label {
              &.control-label {
                & + .input-group {
                  .radio-inline {
                    margin-top: 0;

                    label {
                      span {
                        top: 0;
                      }
                    }
                  }
                }
              }
              &.form-check-label {
                margin-bottom: 0;
              }
            }
          }
          &:not(.radio-switch) {
            .form-radio {
              &.radio {
                display: flex;
                flex-wrap: wrap;
                column-gap: 28px;
                row-gap: 18px;

                .form-check:last-child {
                  padding-bottom: 0px;
                }
                .radio {
                  margin-inline-end: 0px;

                  &.form-check {
                    width: 100%;
                  }
                  label {
                    min-height: 16px;

                    input:disabled {
                      opacity: 1;

                      &::before {
                        color: var(--color-gray-300);
                      }
                    }
                    span {
                      color: var(--color-gray-500) !important;
                      font-weight: 400 !important;
                    }
                    &.label-position-top {
                      span {
                        margin-inline-start: 0px;
                      }
                      input.form-check-input {
                        top: 0;
                        margin-top: 12px;
                      }
                    }
                    &.label-position-bottom {
                      span {
                        margin-inline-start: 0px;
                        top: 0;
                        margin-top: 12px;
                      }
                    }
                  }
                  &:hover,
                  &.radio-selected {
                    label {
                      span {
                        opacity: 1;
                      }
                    }
                  }
                }
              }
            }
            &.required {
              label.field-required.control-label--hidden + .form-radio.radio {
                .radio {
                  &.form-check {
                    label.form-check-label {
                      &::after {
                        content: " ";
                        display: inline-block;
                        background-color: @errorColor70;
                        vertical-align: unset;
                        width: 6px;
                        height: 6px;
                        border-radius: 5px;
                        margin-inline-start: 5px;
                        min-width: 6px;
                        align-self: center;
                        position: relative;
                        top: -2px;
                      }
                      &.label-position-left {
                        span {
                          top: 0px;
                        }
                        &::after {
                          top: -1px;
                        }
                      }
                    }
                  }
                  &.form-check-inline {
                    &:last-child {
                      &::after {
                        content: " ";
                        display: inline-block;
                        background-color: @errorColor70;
                        vertical-align: unset;
                        width: 6px;
                        height: 6px;
                        border-radius: 5px;
                        margin-inline-start: 5px;
                        min-width: 6px;
                        position: absolute;
                        inset-inline-end: -12px;
                        top: 4px;
                      }
                      &:has(.label-position-bottom) {
                        &::after {
                          bottom: 1px;
                          top: initial;
                        }
                      }
                    }
                  }
                }
              }
              &.formio-modified {
                label.field-required.control-label--hidden + .form-radio.radio {
                  .radio {
                    &.form-check {
                      label.form-check-label {
                        &::after {
                          content: none;
                        }
                      }
                    }
                  }
                }
              }
            }
            .field-wrapper {
              .field-label {
                padding-top: 10px;
              }
            }
          }
          &.remove-top-space {
            .form-radio {
              padding-top: 0;
            }
            .field-wrapper {
              .field-label {
                padding-top: 0px;
              }
            }
          }
          label {
            &.field-required {
              &.control-label--hidden {
                &::after {
                  display: none;
                }
              }
            }
          }
          &.payment-method {
            .form-radio {
              padding-top: 0px;
            }
          }
        }
        &.formio-component-button {
          --button-bg-color: @buttonAccentColor;
          --button-secondary-bg-color: @accentColor10;
          --button-shadow-y: 9px;
          --button-shadow-blur: 20px;
          --button-inline-padding: 25px;
          --button-block-padding: 12px;
          --button-font-size: 16px;
          --button-border-radius: @roundedCorners;
          --button-text-color: @whiteColor;
          --button-text-color-hover: @whiteColor;
          --button-outline-bg-color: transparent;
          --my-account-service-button-border-radius: 6px;
          --my-account-service-button-inline-padding: 24px;
          --my-account-service-button-block-padding: 16px;
          --my-account-service-button-bgcolor: var(--color-white);
          --my-account-service-button-disabled-bgcolor: var(--color-gray-200);
          --my-account-service-button-text-color: var(--color-gray-800);
          --my-account-service-button-disabled-text-color: var(--color-gray-900);
          --my-account-service-button-text-size: 15px;
          --my-account-service-button-extra-text-size: 12px;
          --my-account-service-button-font-weight: 600;
          --my-account-service-button-icon-bgcolor: var(--color-primary-50);
          --my-account-service-button-icon-container-size: 60px;
          --my-account-service-button-icon-size: 28px;
          --my-account-service-button-content-gap: 24px;
          --my-account-service-button-container-shadow: none;
          --my-account-service-button-container-shadow-hover: 0px 4px 16px var(--color-gray-100);

          button {
            &.btn {
              background-color: var(--button-bg-color);
              font-weight: 500;
              color: var(--button-text-color);
              text-transform: none;
              position: relative;
              z-index: 1;
              line-height: 1;
              text-align: center;
              gap: 8px;

              .fa-cog {
                position: absolute;
                animation: none;
                background: inherit;
                width: 100%;
                z-index: 9;
                color: transparent;

                & + span {
                  position: absolute !important;
                  top: -7px;
                  z-index: 99;
                  border-radius: 50%;
                  width: 8px;
                  height: 8px;
                  -webkit-animation-fill-mode: both;
                  animation-fill-mode: both;
                  -webkit-animation: load7 1.8s infinite ease-in-out;
                  animation: load7 1.8s infinite ease-in-out;
                  color: #ffffff;
                  font-size: 10px;
                  margin: 0 auto;
                  position: relative;
                  text-indent: -9999em;
                  -webkit-transform: translateZ(0);
                  -ms-transform: translateZ(0);
                  transform: translateZ(0);
                  -webkit-animation-delay: -0.16s;
                  animation-delay: -0.16s;

                  &::after,
                  &::before {
                    border-radius: 50%;
                    width: 8px;
                    height: 8px;
                    -webkit-animation-fill-mode: both;
                    animation-fill-mode: both;
                    -webkit-animation: load7 1.8s infinite ease-in-out;
                    animation: load7 1.8s infinite ease-in-out;
                    content: '';
                    position: absolute;
                    top: 0;
                  }
                  &::before {
                    inset-inline-start: -16px;
                    -webkit-animation-delay: -0.32s;
                    animation-delay: -0.32s;
                  }
                  &::after {
                    inset-inline-start: 16px;
                  }
                }
              }
              .fa-question-circle {
                color: @whiteColor;

                &::before {
                  color: inherit;
                }
              }
            }
          }
          &:is(.field-plus-button) {
            button {
              &.btn {
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                margin-inline-start: -24px;
                transition-duration: 0s;
              }
            }
          }
          &.action-progress {
            button {
              font-family: var(--main-font-family) !important;
            }
            &.cog-during-form-submit {
              button {
                font-family: 'Nunito Sans', sans-serif !important;

                &::before {
                  font-family: 'Font Awesome 5 Pro' !important;
                  font-weight: 300;
                  -moz-osx-font-smoothing: grayscale;
                  -webkit-font-smoothing: antialiased;
                  display: inline-block;
                  font-style: normal;
                  font-variant: normal;
                  text-rendering: auto;
                  line-height: 1;
                  -webkit-animation: buttonProgress 2s linear infinite;
                  animation: buttonProgress 2s linear infinite;
                  margin-inline-end: 8px;
                  content: "\f3f4";
                }
              }
            }
            &:not(.cog-during-form-submit) {
              button {
                &::before {
                  display: none;
                }
              }
            }
          }
          &.action-success {
            &.success-disabled {
              --button-text-color: @dark;
              --button-bg-color: @main-bg;

              button {
                cursor: not-allowed;
                pointer-events: none;
              }
            }
          }
          &:is(.btn-secondary, .light-color) {
            --button-text-color: @darkTextColor;
            --button-text-color-hover: @darkTextColor;

            button.btn {
              background-color: var(--button-secondary-bg-color);
            }
          }
          &.outline-button {
            --button-text-color: var(--button-bg-color);
            --button-text-color-hover: var(--button-bg-color);
            --button-border-width: 1px;
            --button-border-color: var(--button-bg-color);

            &:is(.btn-secondary, .light-color) {
              --button-border-color: var(--button-secondary-bg-color);
              --status-secondary-outline-border-color: @accentColor50;
            }
            button.btn {
              background-color: var(--button-outline-bg-color);
              border-color: var(--button-border-color);
            }
            &.btn-green {
              --button-border-color: #10B981;
              --button-text-color: #10B981;
              --button-text-color-hover: #10B981;
            }
            &.btn-blue {
              --button-border-color: @infoNotice;
              --button-text-color: @infoNotice;
              --button-text-color-hover: @infoNotice;
            }
            &.btn-orange {
              --button-border-color: @warningNotice;
              --button-text-color: @warningNotice;
              --button-text-color-hover: @warningNotice;
            }
            &.btn-red {
              --button-border-color: @errorNotice;
              --button-text-color: @errorNotice;
              --button-text-color-hover: @errorNotice;
            }
          }
          &.button-status {
            --button-border-radius: 50px;
            --button-shadow: none;
            --button-inline-padding: 20px;
            --status-bg-color: @buttonAccentColor;
            --status-secondary-bg-color: #E6E6EA;
            --status-outline-bg-color: transparent;
            --status-text-color: #FFFFFF;

            button.btn {
              background-color: var(--status-bg-color);
              color: var(--status-text-color);
              font-weight: 600;
              border-radius: var(--button-border-radius);
            }
            &.outline-button {
              --status-outline-border-color: #070B34;

              button.btn {
                background-color: var(--status-outline-bg-color);
                border-color: var(--status-outline-border-color);
                color: var(--status-outline-border-color);
              }
              &:is(.btn-secondary, .light-color) {
                --status-outline-secondary-bg-color: transparent;

                button.btn {
                  border-color: var(--status-secondary-outline-border-color);
                  color: var(--status-outline-border-color);
                  background-color: var(--status-outline-secondary-bg-color);
                }
              }
            }
            &:is(.btn-secondary, .light-color) {
              --status-text-color: var(--main-text-color);
              --status-text-color-hover: var(--main-text-color);

              button.btn {
                background-color: var(--status-secondary-bg-color);
                color: var(--status-secondary-text-color);
              }
            }
          }
          &.hover-feedback-off {
            pointer-events: none;

            button.btn {
              cursor: initial;
            }
          }
          &.formio-disabled-component {
            button {
              &.btn[disabled] {
                opacity: 0.4;
                --button-shadow: none;
              }
            }
          }
          &.no-shadow {
            button.btn {
              --button-shadow: none;
            }
          }
          &.my-account-service-button {
            button.btn {
              border-radius: var(--my-account-service-button-border-radius);
              padding-inline: var(--my-account-service-button-inline-padding);
              padding-block: var(--my-account-service-button-block-padding);
              background-color: var(--my-account-service-button-bgcolor);
              color: var(--my-account-service-button-text-color);
              font-size: var(--my-account-service-button-text-size);
              font-weight: var(--my-account-service-button-font-weight);
              justify-content: start;
              display: grid !important;
              grid-template-columns: var(--my-account-service-button-icon-container-size) auto;
              grid-template-rows: auto auto;
              column-gap: var(--my-account-service-button-content-gap);
              row-gap: 0;
              box-shadow: var(--my-account-service-button-container-shadow);
              outline: var(--color-gray-75) solid 2px;
              transition-property: box-shadow;

              .second-label {
                font-size: var(--my-account-service-button-extra-text-size);
                font-weight: inherit;
                grid-column-start: 2;
                grid-row-start: 2;
                grid-row-end: 3;
                align-self: start;
                padding-top: 5px;
                white-space: wrap;
                text-align: start;
                line-height: 1.4;
                opacity: 0.75;
              }
              span[class*="fa-"] {
                background-color: var(--custom-my-account-service-button-icon-bgcolor, var(--my-account-service-button-icon-bgcolor));
                width: var(--my-account-service-button-icon-container-size);
                height: var(--my-account-service-button-icon-container-size);
                font-size: var(--my-account-service-button-icon-size);
                border-radius: var(--my-account-service-button-icon-container-size);
                place-content: center;
                grid-row-start: 1;
                grid-row-end: 3;

                & + span:not(:last-child) {
                  grid-row-start: 1;
                  grid-row-end: 2;
                  grid-column-start: 2;
                  align-self: end;
                  white-space: break-spaces;
                  text-align: start;
                  line-height: 1.25;
                }
                + span:last-child {
                  grid-row-start: 1;
                  grid-row-end: 3;
                  white-space: break-spaces;
                  line-height: 1.4;
                  text-align: start;
                }
              }
              &:has(span:not([class*="fa-"]):first-child) {
                min-height: 92px;
                place-content: center flex-start;
              }
              span:not([class*="fa-"]):first-child {
                & + .second-label {
                  grid-column-start: 1;
                }
              }
              span:not([class*="fa-"], .second-label):first-child {
                white-space: break-spaces;
                line-height: 1.25;
                text-align: start;
                grid-column-start: 1;
                grid-column-end: 3;
              }
              &:hover {
                box-shadow: var(--my-account-service-button-container-shadow-hover);
                outline: none;
              }
              [ref="tooltip"] {
                position: absolute;
                inset-inline-end: 0px;
                color: var(--color-gray-600);
                border-radius: 50px;
                width: 16px;
                height: 16px;
                top: 50%;
                translate: -16px -50%;
                font-family: var(--fontawesome-font);

                &::before {
                  inset: initial !important;
                  left: 0 !important;
                  font-size: 18px;
                  font-weight: 400;
                  width: 9px;
                  border-radius: 10px;
                  height: 9px !important;
                }
                &::after {
                  content: attr(data-tooltip) !important;
                  font-family: var(--main-font-family) !important;
                  line-height: 1.5 !important;
                  white-space: normal;
                  background-color: var(--color-gray-900);
                  color: white;
                  border-radius: 10px;
                  padding: 16px !important;
                  font-size: 12px !important;
                  font-weight: 500 !important;
                  width: 300px;
                  text-align: center;
                  display: none;
                  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05) !important;
                  left: 50% !important;
                  translate: -50% 16px;
                }
                &:hover {
                  &::after {
                    display: table;
                  }
                  &::before {
                    box-shadow: 4px 20px 0px var(--color-gray-900) !important;
                  }
                }
              }
              &:has([ref="tooltip"]) {
                button.btn {
                  padding-inline-end: 36px;
                }
              }
            }
            &.formio-component-disabled {
              button {
                filter: grayscale(1);
                opacity: .35;
                background-color: var(--my-account-service-button-disabled-bgcolor);
                color: var(--my-account-service-button-disabled-text-color);
              }
            }
            &:has([ref="tooltip"]) {
              &:hover {
                z-index: 9;
              }
              .tooltip {
                display: none !important;
              }
            }
          }
          &.inline-button {
            display: inline-block;
          }
          &.left-space {
            margin-inline-start: var(--inline-space);
          }
          &.left-space-2x {
            margin-inline-start: var(--inline-space-2x);
          }
          &.left-space-3x {
            margin-inline-start: var(--inline-space-3x);
          }
          &.right-space {
            margin-inline-end: var(--inline-space);
          }
          &.right-space-2x {
            margin-inline-end: var(--inline-space-2x);
          }
          &.right-space-3x {
            margin-inline-end: var(--inline-space-3x);
          }
        }
        &:is(.formio-component-htmlelement, .formio-component-content) {
          :is(h1, h2, h3, h4, h5, h6) {
            line-height: 1.3;
            padding: 0;
            margin: 0;
          }
          h2 {
            font-size: calc(12pt * 2);
          }
          h3 {
            font-size: calc(12pt * 1.8);
          }
          h4 {
            font-size: calc(12pt * 1.6);
          }
          p {
            font-size: @defaultTextSize !important;
            color: var(--custom-text-color, @darkTextColor) !important;
            margin-top: 0;
            margin-bottom: 0;

            span {
              font-size: @defaultTextSize;
              color: inherit;

              &.text-tiny {
                font-size: 9px;

                * {
                  font-size: 9px;
                }
                span {
                  background-color: transparent !important;
                }
              }
              &.text-small {
                font-size: 11px;

                * {
                  font-size: 11px;
                }
              }
              &.text-big {
                font-size: 18px;

                * {
                  font-size: 18px;
                }
              }
              &.text-huge {
                font-size: 24px;

                * {
                  font-size: 24px;
                }
              }
              &.currency-prefixed {
                margin-inline-end: 5px;
              }
              &.currency-suffixed {
                &:not(:empty) {
                  margin-inline-start: 5px;
                }
              }
            }
            &:empty + p {
              margin-top: 0;
            }
            &:first-child {
              margin-top: 0;
            }
          }
          strong {
            font-weight: 700;
          }
          :is(ul, ol) {
            margin-top: 0px;
            margin-bottom: 0px;
          }
          ol {
            list-style: none;
            counter-reset: li;

            li {
              font-size: @defaultTextSize;
              color: @darkTextColor;
              padding-inline-start: 2.4rem;
              position: relative;
              line-height: 1.5;

              &::before {
                content: counter(li) + ".";
                display: inline-block;
                width: 1em;
                inset-inline-start: 0;
                counter-increment: li;
                position: absolute;
              }
              span {
                font-size: @defaultTextSize;
                color: @darkTextColor;

                &.text-tiny {
                  font-size: 12px;

                  * {
                    font-size: 12px;
                  }
                  span {
                    background-color: transparent !important;
                  }
                }
                &.text-small {
                  font-size: 14px;

                  * {
                    font-size: 14px;
                  }
                }
                &.text-big {
                  font-size: 20px;

                  * {
                    font-size: 20px;
                  }
                }
                &.text-huge {
                  font-size: 24px;

                  * {
                    font-size: 24px;
                  }
                }
              }
            }
          }
          ul {
            margin-top: 0;
            padding-inline-start: 1.8rem;
            padding-block: 0.6rem;

            li {
              font-size: @defaultTextSize;
              color: @darkTextColor;
              line-height: 1.5;

              & + li {
                margin-top: 0.5rem;
              }
            }
          }
          p:not(:empty) + ul, p:not(:empty) + ol {
            margin-top: 0px;
          }
          table {
            width: 100%;

            tr {
              td {
                font-size: @defaultTextSize;
                color: @darkTextColor;
                border: solid 1px #ebebeb;
                padding: 0 10px;

                span {
                  &.text-tiny {
                    font-size: 9px;

                    * {
                      font-size: 9px;
                    }
                    span {
                      background-color: transparent !important;
                    }
                  }
                  &.text-small {
                    font-size: 11px;

                    * {
                      font-size: 11px;
                    }
                  }
                  &.text-big {
                    font-size: 18px;

                    * {
                      font-size: 18px;
                    }
                  }
                  &.text-huge {
                    font-size: 24px;

                    * {
                      font-size: 24px;
                    }
                  }
                }
              }
            }
          }
          blockquote {
            background: @whiteColor !important;
            border-inline-start: solid 3px;
            border-radius: 0 @roundedCorners @roundedCorners 0;
            padding: 0 2rem !important;
            margin: 0;
            border-left-color: var(--color-primary-600) !important;

            &::after {
              display: none;
            }
          }
          p:empty + blockquote {
            border-top: solid 5px transparent !important;
          }
          &[class*="notice"] {
            --panel-bg-color: @darkTextColor3;
            --notice-color-rgb: var(--color-primary-600);
            background-color: var(--panel-bg-color) !important;
            border-inline-start: solid 4px;
            border-inline-start-color: rgba(var(--notice-color-rgb), 1);
            border-radius: var(--notice-corner-radius, 0 @roundedCorners @roundedCorners 0);
            padding: var(--colored-bg-content-padding-y, 15px) var(--colored-bg-content-padding-x, 20px);

            &.fill-background {
              border-radius: var(--notice-corner-radius, 0 @roundedCorners @roundedCorners 0);
            }
          }
          &.notice-blue {
            --notice-color-rgb: @infoNoticeRGB;
          }
          &.notice-green {
            --notice-color-rgb: @successNoticeRGB;
          }
          &.notice-orange {
            --notice-color-rgb: @warningNoticeRGB;
          }
          &.notice-red {
            --notice-color-rgb: @errorNoticeRGB;
          }
          &:is(.fill-background, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) {
            background-color: var(--panel-bg-color);
            border-radius: var(--notice-corner-radius, @roundedCorners);
            padding: var(--colored-bg-content-padding-y, 15px) var(--colored-bg-content-padding-x, 20px) !important;

            &.notice {
              border-radius: 0 @roundedCorners @roundedCorners 0;
            }
            &:is(.top-label-space, .top-space, .top-space-2x, .top-space-3x) {
              --colored-bg-content-padding-y: 15px;
            }
          }
          &:is(.background-red, .error-notice) {
            --panel-bg-color: @errorNotice15Solid;
            --notice-color-rgb: @errorNoticeRGB;
          }
          &:is(.background-blue, .info-notice) {
            --panel-bg-color: @infoNotice15Solid;
            --notice-color-rgb: @infoNoticeRGB;
          }
          &:is(.background-green, .success-notice) {
            --panel-bg-color: @successNotice15Solid;
            --notice-color-rgb: @successNoticeRGB;
          }
          &:is(.background-orange, .warning-notice) {
            --panel-bg-color: @warningNotice15Solid;
            --notice-color-rgb: @warningNoticeRGB;
          }
          &.background-gray {
            --panel-bg-color: var(--color-gray-75);
          }
          &.background-primary {
            --panel-bg-color: @accentColor075;
          }
          &.background-white {
            --panel-bg-color: #FFFFFF;
          }
          &.alert {
            &[class*="reason"] {
              &.editgrid-validation-reason {
                margin-bottom: -18px !important;
                box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);

                &::before {
                  content: "" !important;
                  display: block !important;
                  width: 0px;
                  height: 0px;
                  border-width: 10px 8px 0px 8px;
                  border-color: @errorNotice5Solid transparent transparent;
                  border-style: solid solid none;
                  position: absolute;
                  z-index: 9;
                  bottom: -10px;
                  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.15));
                }
              }
              &:first-child {
                margin: 0 !important;
              }
              &:not(.formio-hidden) ~ .formio-component-file {
                margin-top: 1rem !important;
              }
            }
            &.alert-danger {
              &:not(.formio-hidden, .hide) ~ .guide-form {
                margin-top: @componentSeparation;
              }
            }
          }
          .image {
            &:only-child {
              margin: 0;
            }
            &:first-child {
              margin-top: 0;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
          // Show more link
          p + a[href="#"] {
            margin-top: 5px;
            display: inline-block;
            text-decoration: none;
            font-weight: 600;
            border-bottom: solid 1px;

            &:hover {
              color: var(--color-black);
            }
          }
        }
        &.formio-component-textarea {
          textarea {
            &.form-control {
              border: solid 1px @borderColor;
              border-radius: @roundedCorners !important;

              &:where(:hover, :focus) {
                outline: none;
              }
              &::-webkit-resizer {
                display: none;
              }
            }
          }
          &.has-error {
            textarea {
              &.form-control {
                border-color: @errorColor30 !important;
              }
            }
          }
          .card.card-body {
            margin-bottom: 0 !important;
            padding: 0 !important;
            background-color: transparent;
            box-shadow: none;

            .formio-editor-read-only-content {
              min-height: var(--field-height);
              line-height: 1.5;
              padding: 8px 14px;
              font-size: var(--field-font-size);
              font-weight: var(--field-font-weight, 400) !important;
              background-color: var(--color-gray-100);
              letter-spacing: 0.4pt !important;
              color: var(--main-text-color);
              border: solid 1px @borderColor;
              border-radius: @roundedCorners !important;
              align-items: center;
              display: flex;
            }
          }
          &.field-plus-button {
            textarea {
              &.form-control {
                border-top-right-radius: 0 !important;
              }
            }
          }
          &.value-label-style {
            textarea {
              &.form-control {
                border: none;
                background: transparent;
                resize: none;
                padding: 0;
                line-height: 1;
              }
            }
            .card.card-body {
              .formio-editor-read-only-content {
                border: none;
                padding-inline: 0px;
                background-color: transparent;
              }
            }
          }
        }
        &.formio-component-selectboxes {
          .form-radio {
            &.radio {
              padding-top: 7px;
              margin-top: 0;
              display: flex;
              flex-wrap: wrap;
              column-gap: 28px;
              row-gap: 0px;

              :where(.form-check, .form-check-inline) {
                cursor: pointer;
                padding-bottom: 10px;
                margin-top: 0;
                margin-bottom: 0;
                margin-inline-end: 0px;

                label {
                  margin-top: 0;
                  gap: 12px;

                  input {
                    &[type="checkbox"] {
                      appearance: inherit;
                      inset-inline-start: initial !important;
                      width: initial;
                      padding: 0;
                      margin: 0;

                      &::before {
                        -webkit-font-smoothing: antialiased;
                        display: inline-block;
                        font-style: normal;
                        font-variant: normal;
                        text-rendering: auto;
                        line-height: 1;
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 400;
                        font-size: @defaultTextSize;
                        content: "\F0C8";
                        color: @accentColor50;
                        margin-top: 1px;
                      }
                      & + span {
                        font-size: @defaultTextSize;
                        font-weight: 600;
                        color: @accentColor50;
                        margin-inline-start: 3px;
                        position: relative;
                        top: 1px;
                        line-height: 1.5;
                        padding-inline-start: 0;

                        &::before {
                          display: none;
                        }
                      }
                      &:checked {
                        &::before {
                          content: "\F14A";
                          font-weight: 900;
                          color: var(--color-primary-600);
                        }
                        & + span {
                          color: var(--color-primary-600);
                        }
                      }
                      &:focus {
                        outline: none;
                      }
                    }
                    &[disabled] {
                      background-color: transparent !important;
                    }
                  }
                  &:hover {
                    input {
                      &[type="radio"] {
                        appearance: inherit;

                        &::before {
                          color: var(--color-primary-600);
                        }
                        & + span {
                          color: var(--color-primary-600);
                        }
                      }
                    }
                  }
                  span {
                    color: black !important;
                    font-weight: 400 !important;
                    margin-inline-start: 0px !important;
                  }
                }
                &.radio-selected {
                  label {
                    span {
                      opacity: 1;
                    }
                  }
                }
              }
              .form-check {
                width: 100%;
              }
            }
          }
          &.remove-top-space {
            .form-radio {
              padding-top: 0;
            }
          }
        }
        &.formio-component-editgrid {
          scroll-margin-top: 68px;
          --editgrid-padding: 24px;
          --editgrid-border-color: var(--color-gray-200);
          --editgrid-border-width: 1px;
          --editgrid-bg-color: #FFFFFF;
          min-width: 100%;
          padding: var(--editgrid-padding, 0px);
          border-radius: @roundedCorners;
          border: solid var(--editgrid-border-width, 1px) var(--editgrid-border-color, @accentColor10Solid);
          background-color: var(--editgrid-bg-color, transparent);

          > label.col-form-label {
            --label-font-size: 16px;
            --label-text-color: var(--color-gray-600);
            --label-bottom-margin: 24px;
          }
          ul.editgrid-listgroup {
            border: none;
            border-radius: @roundedCorners @roundedCorners 0 0;
            overflow-x: auto;

            > .scroll-padding {
              display: block;
            }
            li.list-group-item {
              &.list-group-header {
                border-bottom: none;
                background-color: var(--color-gray-100);
                border-radius: @roundedCorners @roundedCorners 0 0 !important;

                .row {
                  margin-inline: 0;

                  > div {
                    border-inline-end: none;
                    color: var(--color-primary-600);
                    font-weight: 600;
                    font-size: 13px;
                    padding-top: 6px;
                    padding-bottom: 6px;
                    min-height: 32px;
                    line-height: 19px;

                    &.radio {
                      label {
                        padding-inline-start: 0;
                        min-height: auto;
                      }
                    }

                    .formio-button-more {
                      background: transparent;
                      text-align: center;
                      padding: 2px 12px;
                      box-shadow: none;

                      .fa {
                        margin: 0;

                        &[ref="tooltip"] {
                          &::after {
                            display: none;
                          }
                        }
                      }
                      > span[ref="tooltip"] {
                        &:not([data-position]) {
                          &::after {
                            left: auto;
                            right: 0;
                            top: 100%;
                            bottom: auto;
                            transform: translateX(-30px) translateY(-30px);
                            padding: 8px;
                            margin-bottom: 0;
                          }
                          &::before {
                            transform: rotate(-45deg) translateY(-50%) scale(1);
                            left: calc(0px - 100% - 6px);
                            transition-delay: 0.04s !important;
                            transition: left 0.2s ease, opacity 0.2s ease;
                            top: 50%;
                          }
                          &:hover {
                            &::after {
                              transform: translateX(-36px) translateY(-30px) scale(1);
                            }
                            &::before {
                              left: calc(0px - 100% - 12px);
                            }
                          }
                        }
                        &:has(+.tooltip) {
                          &::after,
                          &::before {
                            display: none;
                          }
                        }
                      }
                    }
                  }
                }
                & + .list-group-footer {
                  margin-top: 8px;
                }
                & ~ .list-group-item {
                  > .row {
                    > div {
                      padding-top: 16px;
                      padding-bottom: 16px !important;
                      color: inherit;

                      .btn-icon-toggle {
                        color: var(--color-gray-500);

                        &:hover {
                          color: var(--color-gray-700);
                        }
                        &.removeRow {
                          color: var(--color-trash);

                          &:hover {
                            color: var(--color-trash-hover);
                          }
                        }
                      }
                    }
                  }
                  &:is(.new-entry) {
                    --top-padding: @panelBlockPadding;
                  }
                  &.list-group-footer {
                    > .row {
                      margin-inline: 0;

                      > div {
                        color: @darkTextColor;
                        font-weight: 600;
                        padding-top: 16px;
                        padding-bottom: 16px;
                        min-height: 50px;

                        b {
                          font-weight: inherit;
                        }
                        &.alert {
                          flex: 1;
                          margin-bottom: 8px !important;
                          min-height: 20px;
                          display: flex;
                          align-items: center;

                          &::after {
                            height: calc(100% - 4px) !important;
                            top: 2px !important;
                          }
                        }
                      }
                    }
                  }
                }
                & + .list-group-item {
                  border-top: none;

                  &.new-entry {
                    --top-padding: 0px;
                    --inline-padding: 0px;
                    background-color: transparent;
                  }
                }
                &:only-child {
                  display: none;
                }
                .eg-action-col {
                  background: var(--color-gray-100);
                  justify-self: flex-end;
                }
                .datagrid-hide-column-label label {
                  display: none;
                }
                .align-value-right {
                  text-align: end;
                }
              }
              > .row:not(.formio-component-columns) {
                > div {
                  max-width: initial;
                  margin: 0;

                  &:is(.eg-action-col, :last-child) {
                    border-inline-end: none;
                  }
                }
                &.no-actions-col {
                  margin-inline: 0;

                  > div {
                    &:last-of-type {
                      flex: 1;
                      padding-inline: 12px !important;
                      padding-block: 10px !important;
                      position: initial;
                      justify-content: initial;
                      min-width: 170px;
                    }
                  }
                }
              }
              // Experimental
              &:has(.no-actions-col) ~ .list-group-footer .row {
                > div {
                  &:last-of-type {
                    flex: 1;
                    padding-inline: 12px !important;
                    padding-block: 10px !important;
                    position: initial;
                    justify-content: initial;
                    min-width: 170px;
                  }
                }
              }
              &.list-group-footer {
                .alert {
                  &::after {
                    display: none !important;
                  }
                }
                > [class="row"] {
                  > [class*="col-"] {
                    border-bottom: none;

                    &.eg-action-col {
                      max-width: 64px;
                    }
                  }
                }
              }
              > :is([class="row"], [class="row "], [class="row selected"]) {
                display: flex;
                ///grid-template-columns: repeat(auto-fit, ~"max(calc((100% - 64px) / (var(--eg-cols) - 1)), 200px)") !important;
                margin-inline: 0;

                > [class*="col-"] {
                  height: 100%;
                  max-width: 100%;
                  min-width: var(--custom-min-width, 170px);

                  label {
                    text-align: inherit !important;

                    span {
                      font-weight: 600;
                    }
                  }
                  &.eg-action-col {
                    padding: 0 !important;
                    position: sticky;
                    inset-inline-end: 0px;
                    min-width: 64px;
                    max-width: 64px;
                  }
                }
                .generate-widths(100);
                .generate-widths(@n, @i: 5) when (@i =< @n) {
                  > [class*="col-"].width@{i} {
                    flex: @i;
                    --custom-min-width: 10px;
                  }
                  .generate-widths(@n, (@i + 5));
                }
              }
              &:not(.list-group-header, .list-group-footer, .new-entry, .editing-entry) {
                border-bottom: solid 1px var(--color-gray-200);

                > :is([class="row"], [class="row "], [class="row selected"]) {
                  margin-inline: 0;
                  border-radius: 0;

                  > [class*="col-"] {
                    min-height: 50px;
                    border-bottom: none;

                    &.select {
                      span.key {
                        display: none;
                      }
                      span.value {
                        display: -webkit-inline-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        line-height: 19px;

                        &:hover {
                          -webkit-line-clamp: 100;
                          animation-name: fullClamp;
                          animation-delay: 0.75s;
                          animation-timing-function: ease-in-out;
                          animation-fill-mode: backwards;
                          animation-duration: 0.3s;
                        }
                        &[data-tooltip] {
                          display: inline-flex;
                        }
                      }
                    }
                  }
                }
                &:not(.new-entry, .editing-entry) {
                  > :is([class="row"], [class="row "], [class="row selected"]) {
                    align-items: stretch;

                    > [class*="col-"] {
                      height: auto;

                      &:not(.hide) {
                        display: flex;
                        align-items: center;
                      }
                    }
                  }
                }
              }
              &:not(.list-group-header) {
                > [class="row"] {
                  > [class*="col-"] {
                    &.align-value-right {
                      text-align: end;
                      justify-content: end;
                    }
                  }
                }
              }
              &:is(.new-entry, .editing-entry) {
                padding: var(--top-padding, 0px) var(--inline-padding, 0px) var(--bottom-padding, calc(30px - 2rem)) !important;
                border-radius: var(--border-radius, 0px) !important;
                overflow-x: initial;
                max-width: 100%;

                .formio-component-columns.row:not(.vertical-align-bottom, .vertical-align-center) {
                  align-items: stretch;
                }
                .formio-component {
                  &.formio-component-columns {
                    gap: 24px;
                    margin-inline: 0px;

                    &:not(.hide, .formio-hidden, .hide-on-edit-mode) {
                      display: grid;
                      grid-template-columns: repeat(12, 1fr);

                      > [ref^="column-"] {
                        max-width: initial;
                        padding: 0px;
                        width: 100%;
                      }
                      > [class^="col-"] {
                        padding-inline: 0px;
                        padding-block: 0px;
                      }
                      &::before,
                      &::after {
                        display: none;
                      }
                      .formio-errors {
                        flex: 0 0 100%;

                        &:empty {
                          display: none;
                        }
                      }
                      > .col-md {
                        &-2 {
                          grid-column: span 2;
                        }
                        &-3 {
                          grid-column: span 3;
                        }
                        &-4 {
                          grid-column: span 4;
                        }
                        &-5 {
                          grid-column: span 5;
                        }
                        &-6 {
                          grid-column: span 6;
                        }
                        &-7 {
                          grid-column: span 7;
                        }
                        &-8 {
                          grid-column: span 8;
                        }
                        &-9 {
                          grid-column: span 9;
                        }
                        &-10 {
                          grid-column: span 10;
                        }
                        &-11 {
                          grid-column: span 11;
                        }
                        &-12 {
                          grid-column: span 12;
                        }
                      }
                    }
                  }
                  &.formio-component-table {
                    margin-inline: 0px;

                    td {
                      &:first-child {
                        padding-inline-start: 0px;
                      }
                      &:last-child {
                        padding-inline-end: 0px;
                      }
                    }
                  }
                }
                .hide-on-edit-mode {
                  display: none;
                }
                .formio-component-file {
                  .list-group-item {
                    .row > [class^="col-"] {
                      border: none;
                    }
                    &:last-child {
                      border-bottom: none;
                    }
                  }
                }
                :is(.list-group-header, .list-group-footer) {
                  .hide-on-edit-mode {
                    display: block;
                  }
                }
                .formio-component-panel {
                  &:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) {
                    & + .formio-component-panel {
                      &:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) {
                        --custom-top-margin-value: 3rem;
                      }
                    }
                  }
                }
                > [class="row"] {
                  background-color: var(--eg-add-edit-entry-row-bgcolor);
                  box-shadow: var(--eg-add-edit-entry-row-shadow);
                  margin-bottom: @componentSeparation;
                  font-weight: 600;

                  .eg-action-col {
                    .btn-group {
                      background-color: var(--eg-add-edit-entry-row-bgcolor);
                    }
                  }
                  .removeRow {
                    font-family: var(--fontawesome-font);
                    --icon-code: "\f1f8";
                  }
                  .hide-on-edit-mode {
                    display: block;
                  }
                }
              }
              &:is(.new-entry):not(.editing-entry) {
                --bottom-padding: 0px;

                > [class="row"] {
                  display: none;
                }
              }
              &.editing-entry {
                --top-padding: @panelBlockPadding;
                --inline-padding: 24px;
                background-color: var(--color-gray-75);

                &:not(:last-child) {
                  > :last-child {
                    margin-bottom: @componentSeparation;
                  }
                }
                &:not(.new-entry) {
                  --top-padding: 0px;
                }
                > [class="row"] {
                  margin-inline: calc(0px - @panelBlockPadding);
                  overflow: hidden;
                }
                > .formio-component-panel,
                > .formio-component-columns > [class*="col-"] > .formio-component-panel {
                  > .card {
                    border-color: var(--color-gray-200);
                    border-width: 1px;
                    border-style: solid;

                    > .card-header {
                      border-bottom-color: var(--color-gray-200);
                    }
                  }
                }
              }
              &:has(.eg-action-col) {
                > :is([class="row"], [class="row "]),
                & ~ .list-group-footer > [class="row"] {
                  :is([class*="col-"]:last-child, .eg-action-col) {
                    position: sticky;
                    inset-inline-end: 0;
                    padding: 0 !important;
                    display: var(--actions-visibility, flex);
                    justify-content: flex-end;
                    min-width: 64px;
                    max-width: 64px;
                  }
                }
              }
              &.saved-entry {
                .removeRow {
                  font-family: var(--fontawesome-font);

                  &::before {
                    content: var(--icon-code);
                  }
                }
                .removeRow {
                  --icon-code: "\f1f8";
                }
                .editRow {
                  --button-border-width: 1px;
                  --button-border-color: var(--color-primary-600);
                  color: var(--color-primary-600);
                }
              }
            }
            &:has(.list-group-header + .editing-entry + .list-group-footer) {
              .editing-entry {
                --top-padding: 0;
              }
            }
            &::-webkit-scrollbar {
              width: 8px;
              height: 8px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 4px;
              background-color: var(--color-gray-500);
            }
            &::-webkit-scrollbar-track {
              background-color: @grayAColor3;
            }
          }
          > button {
            &.btn {
              border: none;
              border-radius: 4px !important;
              color: @darkTextColor;
              background-color: @accentColor10Solid !important;
              padding: 10px 16px;
              font-weight: 600;
              font-size: 12px;
              display: flex;
              align-items: center;
              transition-duration: 0.3s;
              height: 32px;
              margin-inline: auto;
              gap: 8px;
              position: relative;
              z-index: 1;
              margin-top: 20px;
              --button-shadow: none;

              > i {
                font-weight: 400;
                font-family: var(--fontawesome-font);
              }
              &:hover {
                color: @whiteColor;
                background-color: var(--color-primary-600) !important;
                box-shadow: 0 9px 20px @accentColor40 !important;
              }
            }
          }
          &.has-open-row {
            > ul.editgrid-listgroup {
              overflow-x: initial;

              > li.list-group-footer {
                display: none;
              }
              > li.list-group-item:not(.new-entry, .editing-entry) {
                max-width: 100%;
                overflow-x: hidden;
              }
            }
          }
          .editgrid-actions {
            margin-bottom: var(--bottom-margin, 0px);

            .btn {
              transition-duration: 0.3s;
              background: transparent;
              color: var(--color-primary-600);

              &.btn-primary {
                background: var(--color-primary-600);
                color: white;

                &:hover {
                  opacity: 0.9;
                }
              }
              & + .btn {
                border: none;
                --button-shadow: none;

                &:hover {
                  background-color: @accentColor10Solid;
                  color: var(--color-primary-600);
                }
              }
            }
          }
          &.search-result-list {
            .eg-action-col {
              .btn-group {
                background: transparent;
                transition: none;
              }
            }
          }
          &.disabled-eg {
            ul.list-group {
              li.list-group-item {
                > [class="row"] {
                  // grid-template-columns: repeat(auto-fit, ~"max(calc(100% / var(--eg-cols)))") !important;
                  display: grid;

                  > div:last-of-type {
                    max-width: initial;
                    width: initial;
                    text-align: start;
                  }
                }
                &.list-group-header {
                  > [class="row"] {
                    > div:last-of-type {
                      padding-inline: 12px !important;
                      padding-block: 10px !important;
                    }
                  }
                }
                &:not(.list-group-header, .list-group-footer) {
                  > [class="row"] {
                    > div:last-of-type {
                      padding-inline: 12px !important;
                      padding-block: 16px !important;
                      position: initial;
                      display: initial;
                    }
                  }
                }
              }
            }
          }
          &.formio-virtual-scroll {
            .editgrid-listgroup-container {
              box-shadow: 0px 40px 0px #FFFFFF, 0px 41px 0px #EDEFF2;

              ul.editgrid-listgroup {
                &::after {
                  display: none;
                }
                li.list-group-item.list-group-header:only-child {
                  display: flex;
                }
              }
            }
            &.has-open-row {
              > button {
                display: none;
              }
            }
          }
          &.has-form-validation-error {
            :is(.new-entry, .editing-entry) {
              --border-radius: 1px;
              outline-offset: 6px;
              outline-style: solid;
              outline-width: 6px;
              outline-color: hsl(346.26deg, 100%, 100%);
              animation: hglightOutline 1s ease-out 0.15s;
            }
            .editgrid-actions {
              .btn-primary {
                position: relative;
                transition-duration: 0s;
                animation: shake 0.25s ease-in-out;
              }
            }
          }
          &.no-border {
            --editgrid-border-width: 0;
            --editgrid-padding: 0;
          }
          .no-rows-message {
            background-image: url(../../../images/empty-editgrid.svg);
            background-repeat: no-repeat;
            background-position: center top;
            padding-top: 86px;
            text-align: center;

            &::first-line {
              font-weight: 600;
              font-size: 16px;
              line-height: 2.5;
            }
          }
          &:where(.has-error, .formio-error-wrapper) {
            border-radius: @roundedCorners;

            .formio-errors {
              .form-text {
                font-size: 14px;
              }
            }
          }
          &:where(.has-message, .has-error, .formio-error-wrapper) {
            .editgrid-row-error {
              font-size: 14px;
              margin-top: 10px;
            }
            &:has(.list-group-footer) {
              :is(.new-entry, .editing-entry):nth-last-child(2) {
                border-bottom: none;
              }
            }
            :is(.new-entry, .editing-entry) {
              .has-error {
                margin-bottom: 8px !important;
              }
            }
            > .formio-errors {
              margin-top: 24px;

              .form-text {
                &.error {
                  border-radius: @roundedCorners;
                  padding: 15px 24px;
                  background-color: @errorNotice15Solid;
                  color: var(--color-gray-800);
                  font-size: 16px;
                }
              }
            }
          }
          .formio-component-file {
            li.list-group-item {
              border-bottom: none !important;
              box-shadow: none !important;
            }
            &.formio-component-multiple.has-files:not(.value-label-style, .formio-disabled-component) {
              ul.list-group {
                margin-bottom: 30px;
              }
              li.list-group-item {
                > .row > div {
                  padding-top: 8px !important;
                  padding-bottom: 8px !important;
                }
              }
            }
            &.formio-component-multiple.has-files.value-label-style {
              .fileSelector {
                height: calc(100% + 60px);
                padding-inline-start: 0;

                > i {
                  inset-inline-end: 0;
                }
              }
            }
            &:is(.value-label-style, .formio-disabled-component) {
              li.list-group-item {
                &:not(.list-group-header) + .list-group-item {
                  margin-top: 10px;
                }
                > .row {
                  column-gap: 12px;

                  > div {
                    padding: 0px !important;
                  }
                }
              }
            }
          }
          &.no-rows {
            &.hide-if-empty {
              display: none;
            }
          }
          &:is(
            .align-save-cancel-right,
            .align-save-cancel-center,
            .invert-save-cancel-order
          ) {
            :is(.editing-entry, .new-entry) {
              .editgrid-actions {
                display: flex;
              }
            }
          }
          &.align-save-cancel-center {
            :is(.editing-entry, .new-entry) {
              .editgrid-actions {
                justify-content: center;
              }
            }
            &.invert-save-cancel-order {
              :is(.editing-entry, .new-entry) {
                .editgrid-actions {
                  justify-content: center;
                }
              }
            }
          }
          &.align-save-cancel-right {
            :is(.editing-entry, .new-entry) {
              .editgrid-actions {
                justify-content: flex-end;
              }
            }
            &.invert-save-cancel-order {
              :is(.editing-entry, .new-entry) {
                .editgrid-actions {
                  justify-content: flex-start;
                }
              }
            }
          }
          &.invert-save-cancel-order {
            :is(.editing-entry, .new-entry) {
              .editgrid-actions {
                justify-content: flex-end;
                flex-direction: row-reverse;
              }
            }
          }
          [ref*="-searchField"] {
            height: 24px;

            &:focus-visible {
              outline: solid 1px var(--fields-border-color, var(--color-gray-300));
            }
          }
        }
        &.formio-component-datagrid {
          > .col-form-label {
            --label-font-size: 16px;
            --label-text-color: var(--color-gray-600);
            --label-bottom-margin: 24px;
          }
          table {
            &.table {
              &.datagrid-table {
                border: none;
                width: 100%;

                thead {
                  opacity: 1;
                  z-index: 10;

                  tr {
                    th {
                      border: none;
                      background-color: var(--color-gray-100);
                      color: var(--color-primary-600);
                      font-weight: 600;
                      font-size: 12px;
                      letter-spacing: initial;
                      text-transform: none;
                      padding-block: 6px;
                      padding-inline-start: 14px;
                      padding-inline-end: 0px;
                      vertical-align: bottom;
                      min-width: calc(100% / var(--eg-cols));
                      display: table-cell;

                      &:first-child {
                        border-radius: var(--cell-heading-border-radius, @roundedCorners 0 0 @roundedCorners);
                        padding-block: 6px;
                        padding-inline-start: 14px;
                        padding-inline-end: 0px;

                        &:empty {
                          padding: 0 !important;
                          width: 32px;
                        }
                      }
                      &:last-child {
                        border-radius: var(--cell-heading-border-radius, 0 @roundedCorners @roundedCorners 0);
                        padding-inline-end: 10px;
                      }
                      i {
                        &.fa-question-circle {
                          &::before {
                            font-size: 15px;
                          }
                        }
                      }
                      &:not([class]) {
                        width: 50px;
                      }
                      &.dg-action-col {
                        width: 50px;
                        min-width: 40px;
                        padding: 0;
                        vertical-align: top;
                        text-align: end;

                        .formio-button-more {
                          padding: 2px 12px;
                          background: transparent;
                          min-height: 30px;
                          box-shadow: none;
                        }
                      }
                      &.checkbox {
                        label {
                          text-align: center;
                        }
                        &:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) {
                          padding-inline: 7px;
                          text-align: center;
                        }
                        input {
                          &[type="checkbox"] {
                            width: auto;
                            margin-inline-start: calc(50% - 13px/2);
                            position: relative;
                            height: 27px;
                          }
                        }
                      }
                      &:is(.datagrid-hide-column-label, .button) {
                        text-indent: -100vw;
                        overflow: hidden;
                      }
                      &.select {
                        min-width: 100px;
                      }
                      &.radio {
                        label {
                          padding-left: 0;
                          min-height: auto;
                        }
                      }
                      &.field-required {
                        label {
                          padding-inline-end: 24px;

                          span {
                            display: inline-flex;
                            align-items: center;

                            &::after {
                              display: inline-block;
                              content: " ";
                              background-color: rgba(255, 0, 59, 0.7);
                              width: 6px;
                              height: 6px;
                              border-radius: 5px;
                              min-width: 6px;
                              margin-inline-start: 6px;
                            }
                          }
                        }
                        &::after {
                          display: none;
                        }
                      }
                      &.button {
                        box-shadow: none !important;
                      }
                      > label {
                        span {
                          line-break: anywhere;
                        }
                      }
                      input[ref*="-searchField"] {
                        margin-top: 4px;
                      }
                      .formio-button-reset {
                        padding: 0;
                        min-height: 16px;
                        width: 16px;
                        box-shadow: none;
                        bottom: 3.5px;
                        inset-inline-end: 3.5px;
                        color: transparent;
                      }
                    }
                    &:has(.formio-button-reset) {
                      th {
                        vertical-align: top;
                      }
                    }
                  }
                }
                tbody {
                  tr {
                    background-color: transparent;

                    td {
                      background-color: transparent;
                      border: none;
                      padding: 0.75rem 0 0.75rem 14px;
                      vertical-align: top;
                      display: table-cell;
                      min-width: calc(100% / var(--eg-cols));
                      width: 100%;

                      &:not([class*="width"]) {
                        width: calc(100% / var(--eg-cols));
                      }
                      > .formio-component {
                        &:first-child {
                          margin-top: 0;
                        }
                      }
                      &.radio {
                        .form-radio {
                          padding-top: 10px;
                          margin-bottom: 0;
                        }
                        label {
                          input[type="radio"] {
                            padding: 0;
                          }
                        }
                      }
                      &.radio-switch {
                        .form-radio.radio {
                          padding-top: 0px;
                        }
                      }
                      .formio-component {
                        &.value-label-style {
                          .input-group {
                            height: auto;

                            :where(.input-group-append, .input-group-prepend) {
                              border: none;
                              height: 15px;
                              line-height: 15px;

                              .input-group-text {
                                padding: 0;
                              }
                            }
                            .input-group-prepend {
                              .input-group-text {
                                padding-inline-end: 4px;
                              }
                            }
                            input {
                              &.form-control {
                                padding-inline-start: 0;
                              }
                            }
                          }
                          input {
                            &.form-control {
                              height: 20px;
                              border: none !important;
                              border-radius: 0 !important;

                              &:disabled {
                                background-color: transparent !important;
                              }
                            }
                          }
                          &.formio-component-datetime {
                            .input-group {
                              .input-group-append {
                                display: none;
                              }
                            }
                          }
                        }
                        &-checkbox {
                          &:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) {
                            .form-check-label {
                              span {
                                color: transparent;
                                padding: 0;
                                white-space: nowrap;
                                overflow: hidden;
                                width: 14px;
                                display: block;
                                margin: 0 auto;

                                &::before {
                                  inset-inline-start: calc(50% - 7px);
                                }
                              }
                              input.form-check-input:checked + span {
                                color: transparent !important;
                              }
                              .form-check.checkbox {
                                padding: 0;
                              }
                            }
                            .form-check.checkbox {
                              padding: 0;
                            }
                          }
                        }
                        &:is(.formio-component-select) {
                          span.value {
                            font-size: 16px;
                            min-height: 40px;
                          }
                        }
                      }
                      &:not([ref]) {
                        width: 50px;
                        text-align: center;

                        :where(.formio-button-edit-row) {
                          background-color: transparent;
                          padding: 0 12px;
                          margin-inline-start: auto;
                          display: flex;
                          margin-inline-end: 0;
                          height: 40px;
                          align-items: center;
                          border: none;

                          i {
                            color: @accentColor40;
                            font-size: 16px;
                            font-weight: 400;
                          }
                          &:hover {
                            i {
                              color: var(--color-primary-600);
                            }
                          }
                        }
                        :where(.formio-button-remove-row, .formio-drag-button) {
                          background-color: transparent;
                          padding: 0 14px;
                          margin-inline-start: auto;
                          display: flex;
                          margin-inline-end: 0;
                          height: 40px;
                          align-items: center;
                          border: none;
                          box-shadow: none;

                          i {
                            color: @errorColor30;
                            font-size: 16px;
                            font-family: var(--fontawesome-font);
                            font-weight: 400;

                            &::before {
                              content: "\F00D";
                            }
                          }
                          &:hover {
                            i {
                              color: @errorNotice;
                            }
                          }
                        }
                      }
                      &:first-child {
                        padding-inline-start: 0;

                        &.value-label-style {
                          & ~ td[ref].value-label-style ~ td.dg-action-col {
                            .formio-button-remove-row {
                              height: auto;
                            }
                          }
                        }
                      }
                      &.value-label-style {
                        vertical-align: middle;
                        padding-block: 5px 0px !important;

                        &:not(:first-child) {
                          .formio-component.value-label-style {
                            input.form-control {
                              padding-inline-start: 0px;
                            }
                          }
                        }
                        &:first-child {
                          .formio-component.value-label-style {
                            input.form-control {
                              padding-inline-start: 14px;
                            }
                          }
                        }
                        &.select {
                          .formio-choices .form-control.selection {
                            height: auto;
                            min-height: 40px;
                          }
                          .formio-disabled-component {
                            .choices__button {
                              display: none;
                            }
                          }
                          .formio-select-autocomplete-input {
                            top: calc(100% - 2px);
                          }
                        }
                        & ~ .button {
                          padding-block: 5px 0px !important;
                        }
                        :is(.has-suffix, .has-prefix) {
                          input.form-control {
                            field-sizing: content;
                            min-width: 10px;
                            width: auto;
                            flex: none;
                            max-width: 200px;
                            overflow: hidden !important;
                            text-overflow: ellipsis;
                          }
                          .input-group {
                            gap: 4px;
                          }
                        }
                        :is(.input-group-append, .input-group-prepend) {
                          flex: none;
                          background-color: transparent !important;

                          .input-group-text {
                            font-size: var(--field-font-size);
                            font-weight: var(--field-font-weight, 400) !important;
                          }
                        }
                      }
                      .formio-component-textarea:not(.formio-disabled-component) {
                        .card.card-body {
                          margin-bottom: 0px !important;
                          box-shadow: none;
                          border: solid 1px rgba(110, 114, 150, 0.15);
                          border-radius: 6px !important;
                          font-weight: 600;
                          min-height: 40px;
                          letter-spacing: 0.4pt;
                          text-align: start;
                          background: #efefef !important;
                          padding: 8.5px 14px;
                          line-height: 1.5;
                          font-size: 16px;
                        }
                      }
                      &.checkbox {
                        vertical-align: middle;
                        padding-block: 5px 0 !important;

                        &:is(.datagrid-checkbox-hide-label, .datagrid-hide-column-label) {
                          padding-inline: 7px !important;
                          text-align: center;
                          vertical-align: middle;

                          .form-check-label {
                            margin: 0;
                            padding: 0;
                          }
                          .form-check-input {
                            width: auto;
                            margin-inline: auto;
                          }
                          &:not(.datagrid-checkbox-hide-label) {
                            .form-check.checkbox {
                              padding-top: 0px;
                            }
                          }
                          &.horizontal-align-center {
                            .form-check-label {
                              width: auto !important;
                            }
                          }
                        }
                        &:not(.datagrid-checkbox-hide-label) {
                          .form-check.checkbox {
                            padding-top: 0px;
                            justify-content: center;

                            .form-check-label {
                              width: auto !important;
                            }
                          }
                        }
                        &.horizontal-align-center {
                          .form-check-label {
                            width: auto !important;
                          }
                        }
                        .formio-component-label-hidden {
                          .form-check-input {
                            width: auto;
                            margin-inline: auto;
                            margin-top: 8px;
                          }
                        }
                      }
                      &.datetime {
                        .form-control {
                          min-height: 40px;
                        }
                      }
                      &.vertical-align-center {
                        vertical-align: middle;
                      }
                      &.horizontal-align-right {
                        text-align: end;
                      }
                      label.field-required::after {
                        display: none;
                      }
                      &.button {
                        box-shadow: none !important;

                        .formio-component-button {
                          line-height: 1;
                        }
                      }
                      &.textarea {
                        textarea.form-control {
                          &[rows="1"] {
                            min-height: var(--field-height);
                            font-size: var(--field-font-size);
                            line-height: 1.5;
                            padding-top: 8px;
                            field-sizing: content;
                          }
                        }
                        &.value-label-style {
                          textarea.form-control {
                            padding-block: 8px;
                            line-height: 1.5;
                            font-size: 16px;
                          }
                          .formio-component-textarea.formio-disabled-component {
                            .formio-editor-read-only-content {
                              border: none;
                              background: transparent;
                              padding-inline: 0;
                            }
                          }
                        }
                      }
                      &.file {
                        .formio-component-file {
                          padding: 8px 10px;
                          min-height: 42px;

                          .fileSelector {
                            padding: 8px 10px;
                            font-size: 10px;
                            align-items: center;

                            i.fa-cloud-upload {
                              inset-inline-end: 12px;
                            }
                            a.browse {
                              font-size: 10px;
                            }
                          }
                          &.has-files {
                            padding-block: 0;
                            padding-inline: 0;

                            &.formio-component-multiple {
                              .list-group {
                                margin-bottom: 30px;
                                justify-content: center;
                              }
                              .fileSelector {
                                align-items: flex-end;
                              }
                            }
                            .list-group {
                              margin-bottom: 0px;
                              min-height: 40px;
                              align-items: center;
                              display: flex;

                              .col-md-9 {
                                padding: 8px 10px;
                              }
                            }
                          }
                          &.formio-component-multiple {
                            .list-group {
                              flex-direction: column;
                              align-items: flex-start;
                              padding-inline: 12px;

                              .list-group-item {
                                width: 100%;
                                max-width: 100%;
                                min-width: 100%;
                              }
                            }
                            .fileSelector {
                              i {
                                bottom: 11px;
                              }
                            }
                          }
                        }
                        label.field-required.control-label--hidden::after {
                          display: block;
                          inset-inline-end: 48px;
                        }
                      }
                      &.columns {
                        .formio-component-columns {
                          margin-inline: 0px;

                          > [class^="col-"] {
                            &:first-child {
                              padding-inline-start: 0px;
                            }
                            &:is(:last-child, .cols-grid-end) {
                              padding-inline-end: 0px;
                            }
                          }
                        }
                      }
                      &.editgrid {
                        .open-modal-button {
                          --button-text-color: var(--button-bg-color);
                          --button-text-color-hover: var(--button-bg-color);
                          --button-border-width: 1px;
                          --button-border-color: var(--button-bg-color);
                          background-color: var(--button-outline-bg-color);
                          border-color: var(--button-border-color);
                        }
                      }
                    }
                    &:first-child {
                      td {
                        padding-top: 1.5rem;
                      }
                    }
                    &:has(.has-files, .formio-component-textfield, .formio-component-textarea, .radio-switch) {
                      td.value-label-style {
                        vertical-align: top;

                        .value-label-style input.form-control {
                          height: 40px;
                        }
                      }
                    }
                    &:has(td.value-label-style):has(td:not(.value-label-style)) {
                      td.value-label-style {
                        vertical-align: middle;
                      }
                    }
                  }
                  &:first-child {
                    tr:first-child {
                      td {
                        padding-top: 0px;
                      }
                    }
                  }
                }
                tfoot {
                  tr {
                    td {
                      padding: 0.75rem 0 0;
                      border: none;
                      text-align: center;

                      button {
                        &.btn {
                          &.formio-button-add-row {
                            border: none;
                            border-radius: 4px !important;
                            color: var(--color-primary-600);
                            background-color: @accentColor10;
                            padding: 0.9rem;
                            height: 32px;
                            font-weight: 600;
                            font-size: 12px;
                            display: inline-flex;
                            align-items: center;
                            transition-duration: 0.3s;
                            gap: 8px;
                            margin-top: 6px;
                            --button-shadow: none;

                            > i {
                              font-weight: 400;
                              font-family: var(--fontawesome-font);
                            }
                            &:hover {
                              color: @whiteColor;
                              background-color: var(--color-primary-600);
                              box-shadow: 0 9px 20px @accentColor40 !important;
                              z-index: 999;
                              position: relative;
                            }
                          }
                        }
                      }
                    }
                  }
                }
                &.table-striped {
                  tbody {
                    tr {
                      &:nth-of-type(odd) {
                        td {
                          background-color: @accentColor5;
                        }
                      }
                    }
                  }
                }
                &.table-hover {
                  tbody {
                    tr {
                      &:hover {
                        td {
                          background-color: @accentColor5;
                        }
                      }
                    }
                  }
                }
                &.table-sm {
                  thead {
                    tr {
                      th {
                        padding-inline-start: 10px;
                      }
                    }
                  }
                  tbody {
                    tr {
                      td {
                        padding: 1px 0;
                        text-align: start;
                        vertical-align: middle;
                        padding-inline-start: 10px;

                        button:not(.choices__button) {
                          width: 100%;
                          max-width: 100%;
                          white-space: normal;

                          &:hover {
                            box-shadow: none !important;
                          }
                        }
                        .formio-component {
                          &.value-label-style {
                            .input-group {
                              :where(.input-group-append, .input-group-prepend) {
                                margin-top: 16px;
                              }
                            }
                            input {
                              &.form-control {
                                height: 36px;
                              }
                            }
                          }
                          .form-control {
                            padding-inline: 10px;
                          }
                        }
                        &.datetime {
                          .form-control {
                            min-height: 36px;
                          }
                        }
                        &.textarea {
                          textarea.form-control {
                            padding-block: 6px;
                          }
                          &.value-label-style {
                            textarea.form-control {
                              padding-inline: 0px;
                            }
                          }
                        }
                        &.dg-action-col {
                          .formio-button-remove-row {
                            --default-button-height: 36px;
                          }
                        }
                        &:first-child {
                          padding-inline-start: 0px;
                        }
                      }
                    }
                  }
                  thead + tbody {
                    tr:first-child td {
                      padding-top: 5px;
                    }
                  }
                }
                &[style*="table-layout: fixed;"] {
                  thead {
                    tr {
                      th {
                        &:not([class*="width"], .dg-action-col) {
                          width: calc(100% / var(--eg-cols));
                        }
                        &.dg-action-col {
                          width: 50px;
                          padding: 0;
                          vertical-align: top;
                        }
                      }
                    }
                  }
                }
                &[style*="--eg-cols:0;"] {
                  table-layout: fixed;

                  tbody tr td[ref] {
                    width: 100%;
                  }
                }
              }
            }
          }
          &.has-footer {
            table {
              &.table {
                &.table-sm {
                  tfoot {
                    tr {
                      td {
                        button {
                          &.btn {
                            &.formio-button-add-row {
                              margin-bottom: -115px;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            tfoot {
              tr {
                td {
                  button {
                    &.btn {
                      &.formio-button-add-row {
                        margin-bottom: -125px;
                      }
                    }
                  }
                  &[colspan] {
                    display: block;
                  }
                }
              }
            }
          }
          .generate-columns(100);
          .generate-columns(@n, @i: 5) when (@i =< @n) {
            :is(th, td).width@{i} {
              width: (@i * 100% / @n);
              min-width: (@i * 100% / @n);
              max-width: (@i * 100% / @n);
            }
            .generate-columns(@n, (@i + 5));
          }
          &:has(thead) + .formio-component-datagrid:has(tbody:first-child) {
            table {
              &.table {
                &.datagrid-table {
                  tbody {
                    tr {
                      td {
                        &.dg-action-col {
                          min-width: 40px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          &.no-rows {
            &.hide-if-empty {
              display: none;
            }
          }
          &.adding-rows-disabled.removing-rows-disabled {
            table.table.datagrid-table {
              :is(thead, tbody) tr :is(th, td).dg-action-col {
                padding: 0;
                min-width: 0px;
                display: none;

                .formio-button-more {
                  display: none;
                }
              }
            }
            &:has(.dg-action-col) {
              table.table.datagrid-table {
                thead tr th:nth-last-of-type(2) {
                  border-top-right-radius: @roundedCorners;
                  border-bottom-right-radius: @roundedCorners;
                }
              }
            }
          }
          &.text-small {
            table {
              &.table {
                &.datagrid-table {
                  tbody {
                    tr {
                      td {
                        padding-top: 10px;
                        padding-bottom: 0px;
                        vertical-align: top;

                        :is(.form-control, .choices__item) {
                          font-size: 14px;
                          --field-height: 36px;
                          --field-font-size: 14px;
                        }
                        .formio-component:is(.formio-component-select) {
                          .form-control.selection.dropdown {
                            padding-inline: 0px;
                          }
                          .value {
                            font-size: 14px;
                          }
                        }
                        &.checkbox {
                          vertical-align: top;
                          padding-top: 10px;

                          .form-check.checkbox {
                            min-height: 36px;
                            align-items: center;
                            display: flex;
                          }
                          &:not(.datagrid-checkbox-hide-label) {
                            .form-check-label {
                              span {
                                line-height: 20px;
                                font-size: 14px;

                                &::before {
                                  top: 0px;
                                }
                              }
                            }
                          }
                          &.datagrid-checkbox-hide-label {
                            .form-check-label {
                              span::before {
                                top: 0px;
                                line-height: 18px;
                              }
                            }
                          }
                        }
                        &.radio {
                          .form-radio.radio {
                            --field-height: 36px;

                            .form-check-label {
                              span {
                                font-size: 14px;
                              }
                            }
                          }
                        }
                        &.textarea {
                          textarea.form-control {
                            padding-block: 4px;
                          }
                          .formio-component-textarea:not(.formio-disabled-component) {
                            .card.card-body {
                              font-size: 14px;
                            }
                          }
                          .formio-editor-read-only-content {
                            --field-height: 36px;
                            line-height: 1.5;
                          }
                        }
                        &.file {
                          .formio-component-file {
                            min-height: 36px;
                            padding: 4px 3px;

                            &.has-files {
                              ul.list-group {
                                min-height: 24px;

                                :is(.col-md-2, .col-md-9, .col-md-1) {
                                  padding: 3px 10px;
                                }
                              }
                              a[ref="fileLink"] {
                                --field-font-size: 14px;
                              }
                              &.value-label-style {
                                ul.list-group {
                                  min-height: 28px;
                                }
                              }
                            }
                            .fileSelector {
                              padding: 8px 12px;
                            }
                            &:not(.has-files) {
                              .fileSelector i {
                                bottom: 8px;
                              }
                            }
                          }
                          &.value-label-style {
                            .formio-component-file {
                              padding: 4px 1px;
                            }
                          }
                        }
                        &.datetime {
                          --field-height: 36px;

                          .form-control {
                            min-height: 36px;
                          }
                        }
                        &.select {
                          .formio-choices {
                            .form-control.selection {
                              min-height: 36px;
                            }
                          }
                        }
                        &.button {
                          .formio-component-button {
                            margin-top: 2px;
                          }
                          button.btn {
                            --default-button-font-size: 14px;
                            --xs-button-font-size: 14px;
                            --sm-button-font-size: 14px;
                            --md-button-font-size: 14px;
                            --lg-button-font-size: 14px;
                            --xl-button-font-size: 14px;
                            --default-button-height: 36px;
                            --xs-button-height: 36px;
                            --sm-button-height: 36px;
                            --md-button-height: 36px;
                            --lg-button-height: 36px;
                            --xl-button-height: 36px;
                          }
                        }
                      }
                    }
                  }
                  &.table-sm {
                    tbody {
                      tr {
                        td {
                          padding-top: 6px;
                          padding-bottom: 0px;
                          vertical-align: top;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          &.formio-virtual-scroll {
            tbody.scroll-padding {
              td {
                padding: 0 !important;
              }
            }
            .table > tbody + tbody {
              border-top: none;
            }
            .table-container {
              overflow-x: hidden;
            }
          }
          &.modal-edit-row {
            .dg-action-col {
              width: 80px !important;
              min-width: 80px !important;
              font-size: 0px !important;

              button:is(.formio-button-edit-row, .formio-button-remove-row) {
                width: 50%;
                padding: 0 !important;
                margin: 0 !important;
                display: inline-flex !important;
                box-shadow: none;
                justify-content: center;

                span[ref="tooltip"] {
                  &::before,
                  &::after {
                    display: none !important;
                  }
                }
              }
            }
          }
        }
        &.formio-component-tabs {
          scroll-margin: 68px;

          > .card {
            > .card-body {
              &.tab-pane {
                > .formio-component {
                  &.formio-component-datagrid {
                    & + .formio-component-datagrid {
                      tbody:only-child {
                        tr:first-child {
                          td {
                            padding-top: 0.75rem;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.formio-component-fieldset {
          &:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) {
            --panel-bg-color: white;
            background-color: var(--panel-bg-color);
            border-radius: @roundedCorners;

            > fieldset {
              background: transparent;
            }
          }
          &.background-red {
            --panel-bg-color: @errorNotice15Solid;
          }
          &.background-blue {
            --panel-bg-color: @infoNotice15Solid;
          }
          &.background-green {
            --panel-bg-color: @successNotice15Solid;
          }
          &.background-orange {
            --panel-bg-color: @warningNotice15Solid;
          }
          &.background-gray {
            --panel-bg-color: var(--color-gray-75);
          }
          &.background-primary {
            --panel-bg-color: @accentColor075;
          }
          &.background-white {
            --panel-bg-color: #FFFFFF;
          }
          fieldset {
            padding: 24px 24px 24px;
            position: relative;
            border-radius: @roundedCorners;
            outline: solid 4px var(--color-gray-100);
            border: none;
            background-color: @whiteColor;

            legend {
              margin: 0;
              text-transform: initial;
              font-size: 18px;
              font-weight: 600;
              color: @darkTextColor;
              background: transparent;
              padding: 0;
              float: left;
              width: 100%;
              margin-bottom: 20px;
              line-height: 1.3;
            }
            @media screen and (max-width: 580px) {
              padding: 20px 20px 20px;
            }
            > .fieldset-body {
              &::before {
                content: " ";
                display: block;
                height: 0px;
                clear: both;
              }
            }
          }
          &.no-border {
            > fieldset {
              outline: none;
            }
          }
          &.full-height {
            > fieldset {
              height: 100%;
            }
          }
        }
        &.formio-component-file {
          border: dashed 2px rgba(27, 37, 60, 0.1);
          border-radius: @roundedCorners;
          padding: @panelBodyPadding;
          position: relative;

          label {
            &.col-form-label {
              font-size: 16px;
              color: @darkTextColor;
              margin-bottom: 10px;

              & + div {
                margin-bottom: 20px;
                position: relative;
                z-index: 9;

                div:last-child {
                  margin-bottom: 36px;
                }
                span {
                  i {
                    cursor: pointer;
                    color: rgba(255, 0, 59, 1);
                  }
                }
              }
              .fa-question-circle {
                position: relative;
                z-index: 9;
              }
            }
            &.control-label--hidden {
              & ~ .fileSelector {
                align-items: center;
              }
              &.field-required {
                &::after {
                  inset-inline-end: 31px;
                  top: -12px;
                }
              }
            }
          }
          ul {
            &.list-group {
              border: none;
              width: 100%;
              position: relative;
              z-index: 9;

              &:nth-last-child(2) {
                margin-bottom: 0px;
              }
              &:nth-last-child(n+4) {
                & + .fileSelector {
                  i.fa-cloud-upload {
                    top: initial;
                  }
                }
              }
              li {
                &.list-group-item {
                  border: none;
                  box-shadow: inset 0 -2px 0 #E2E7E7;
                  padding: 0;
                  background-color: transparent;
                  border-radius: 0;

                  .row {
                    border: none;
                    display: grid;
                    grid-template-columns: auto auto 1fr;
                    font-size: 12px;

                    &::before,
                    &::after {
                      display: none;
                    }
                    > div {
                      padding: 10px 12px;
                      text-transform: none;
                      color: var(--color-primary-600);
                      min-width: 10px;

                      &.col-md-1 {
                        order: 3;
                        width: auto;
                        justify-content: flex-end;
                        color: rgba(255, 0, 59, 1);
                      }
                      &:where(.col-md-9, .col-md-2) {
                        white-space: nowrap;
                        width: auto !important;

                        a[href] {
                          display: block;
                        }
                      }
                      &.col-md-9 {
                        a {
                          white-space: normal;
                          line-break: anywhere;
                        }
                      }
                      &:first-child {
                        display: flex;
                        align-items: center;

                        i {
                          &.fa-remove {
                            font-family: var(--fontawesome-font);
                            font-weight: 300;
                            cursor: pointer;
                            margin: 0;
                            font-size: 16px;
                          }
                        }
                        & + .col-md-9 {
                          width: 60%;
                        }
                      }
                    }
                    &:hover {
                      background: @black5Color;
                      border-radius: @roundedCorners;
                    }
                  }
                  &.list-group-header {
                    background: @accentColor10Solid;
                    border: none;
                    border-radius: @roundedCorners;
                    padding: 0;
                    display: none;
                    z-index: 5;
                    .row {
                      > div {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 1;

                        strong {
                          font-weight: 600;
                        }
                      }
                    }
                  }
                  &:hover {
                    box-shadow: none;
                  }
                  &:last-child {
                    box-shadow: none;
                  }
                }
                &.list-group-header {
                  > .row {
                    > div {
                      &.radio {
                        label {
                          padding-inline-start: 0;
                        }
                      }
                    }
                  }
                }
              }
              tbody {
                tr {
                  background-color: transparent;

                  td {
                    background-color: transparent;
                    border: none;
                    padding: 1.5rem 12px;

                    &:first-child {
                      border-radius: @roundedCorners 0 0 @roundedCorners;
                    }
                    &:last-child {
                      border-radius: 0 @roundedCorners @roundedCorners 0;
                    }
                    a {
                      &.btn {
                        background-color: transparent;
                        border: none;

                        span {
                          &.glyphicon-remove {
                            font-family: "Font Awesome 5 Pro";
                            font-weight: 300;
                            -moz-osx-font-smoothing: grayscale;
                            -webkit-font-smoothing: antialiased;
                            display: inline-block;
                            font-style: normal;
                            font-variant: normal;
                            text-rendering: auto;
                            line-height: 1;
                            color: @errorColor50;
                            font-size: 16px;

                            &::before {
                              content: "\f00d";
                            }
                            &:hover {
                              color: @errorColor;
                            }
                          }
                        }
                      }
                    }
                  }
                  &:hover {
                    td {
                      background-color: @pageBgColor;
                    }
                  }
                }
              }
            }
          }
          .fileSelector {
            margin-bottom: 0;
            width: 100%;
            border: none;
            border-radius: @roundedCorners;
            position: absolute;
            top: 0;
            inset-inline-start: 0;
            align-items: flex-end;
            height: 100%;
            padding: 23px;
            gap: 6px;
            font-size: 12px;
            transition-duration: 0.2s;
            line-height: 1.3;

            &:not([hidden]) {
              display: flex;
            }
            i {
              &.fa-cloud-upload {
                display: block;
                position: absolute;
                inset-inline-end: 23px;
                font-size: 16px;
                margin: 0;
                top: calc(50% - 8px);

                &::before {
                  font-family: "Font Awesome 5 Pro";
                  font-weight: 300;
                  -moz-osx-font-smoothing: grayscale;
                  -webkit-font-smoothing: antialiased;
                  display: inline-block;
                  font-style: normal;
                  font-variant: normal;
                  text-rendering: auto;
                  line-height: 1;
                  content: "\f56f";
                  color: @darkTextColor;
                  font-size: 16px;
                }
                & ~ * {
                  color: @darkTextColor;
                  font-size: 12px;
                }
              }
            }
            &.fileDragOver {
              box-shadow: 0 0 0 2px @accentColor50;
              background: @accentColor5;
              z-index: 99;
            }
            & ~ .file {
              display: flex;

              > .row {
                &:first-child {
                  min-width: 100px;

                  .fileName {
                    flex: auto;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    position: relative;

                    i {
                      position: absolute;
                      inset-inline-end: 0;
                    }
                  }
                  .fileSize {
                    flex: 0;
                    white-space: nowrap;
                    margin-inline-end: 10px;
                  }
                  & + .row {
                    .progress {
                      margin-inline-end: 36px;
                      height: 4px;
                      margin-top: 4px;
                      margin-bottom: 0;
                      background-color: rgba(27, 37, 60, 0.1);
                      box-shadow: none;

                      .progress-bar {
                        background-color: rgba(41, 188, 143, 1);
                        border-radius: 5px;
                      }
                    }
                  }
                }
              }
              &:nth-last-child(2) {
                margin-bottom: 40px;
              }
            }
          }
          &.formio-component-multiple {
            .fileSelector {
              i {
                &.fa-cloud-upload {
                  top: initial;
                  bottom: 26px;
                }
              }
            }
          }
          &.has-files {
            background-color: rgba(41, 188, 143, 0.03);

            .list-group-item {
              > .row {
                > [class^="col-"] {
                  --custom-min-width: auto;
                }
              }
            }
            label {
              &.control-label--hidden {
                & ~ .fileSelector {
                  align-items: flex-end;
                }
              }
            }
          }
          &.has-message {
            .fileSelector {
              padding-bottom: 44px;
            }
            .formio-errors {
              margin-top: 34px;
            }
          }
          .has-preview {
            grid-template-columns: auto 1fr !important;

            a {
              display: grid !important;
              gap: 8px;
            }
            img {
              max-width: 200px;
              border: solid 1px hsla(0, 0%, 90%, 1);
              border-radius: @roundedCorners;
              padding: 5px;
            }
            .col-md-1 {
              grid-column-start: 2;
              grid-row: 1;
            }
            .col-md-9 {
              padding-bottom: 0px !important;
            }
            .col-md-2 {
              padding-top: 0 !important;
            }
          }
          a[ref="fileLink"] {
            color: var(--main-text-color);
            text-decoration: none;
            border-bottom: solid 1px;
            line-height: 1.5;

            &:hover {
              color: black;
              font-weight: 500;
            }
          }
          .browse {
            text-decoration: none;
            box-shadow: inset 0px -1px 0 var(--main-text-color);
            line-height: 1.3;
            position: relative;
          }
          &:is(.value-label-style, .formio-disabled-component) {
            border: none;
            background-color: transparent;
            padding: 0;

            a[ref="fileLink"] {
              font-size: var(--field-font-size);
              position: relative;
              padding-inline-start: 24px;

              &::before {
                content: "\f15b";
              }
            }
            &.hide-link-text {
              a[ref="fileLink"] {
                overflow: hidden;
                width: 20px;
                white-space: nowrap !important;
              }
            }
            :where(.file-extension-png, .file-extension-jpg, .file-extension-jpeg, .file-extension-gif, .file-extension-bmp, .file-extension-pdf, .file-extension-doc, .file-extension-docx, .file-extension-txt) {
              a[ref="fileLink"] {
                &::before {
                  position: absolute;
                  font-family: "Font Awesome 6 Pro";
                  width: 20px;
                  font-size: calc(var(--field-font-size) + 2px);
                  inset-inline-start: 0;
                  line-height: 18px;
                  top: 2px;
                }
              }
            }
            :where(.file-extension-png, .file-extension-jpg, .file-extension-jpeg, .file-extension-gif, .file-extension-bmp) {
              a[ref="fileLink"] {
                &::before {
                  content: "\f1c5";
                }
              }
            }
            .file-extension-pdf {
              a[ref="fileLink"] {
                &::before {
                  content: "\f1c1";
                }
              }
            }
            :where(.file-extension-doc, .file-extension-docx) {
              a[ref="fileLink"] {
                &::before {
                  content: "\f1c2";
                }
              }
            }
            .file-extension-txt {
              a[ref="fileLink"] {
                &::before {
                  content: "\f15c";
                }
              }
            }
            .list-group-item {
              > .row {
                margin-inline: 0;

                &:hover {
                  background-color: transparent !important;
                }
              }
            }
            .col-md-9 {
              padding: 0px !important;
            }
            .col-md-2 {
              display: none;
            }
          }
          &.required {
            &.formio-component-label-hidden {
              label.field-required.control-label--hidden {
                width: calc(100% - @panelBodyPadding) !important;
              }
            }
          }
          &.value-label-style {
            .list-group:has(.list-group-header:only-child) {
              margin-bottom: 40px;

              & + .fileSelector {
                height: 68px;
                padding-inline: 0px;
              }
            }
            &.has-files.formio-component-multiple {
              &:not(.formio-disabled-component) {
                display: flow-root;

                .list-group {
                  margin-bottom: 30px;
                }
                .fileSelector {
                  padding-inline: 0;
                  padding-bottom: 0;
                }
              }
            }
          }
        }
        &.formio-component-table {
          margin-inline-start: -12px;
          margin-inline-end: -12px;

          table {
            &.table {
              > tbody {
                > tr {
                  > td {
                    input {
                      &:where([type="text"], [type="email"], [type="phoneNumber"]) {
                        height: var(--field-height) !important;
                      }
                    }
                    .form-group {
                      &.form-field-type-radio {
                        &.radio-switch {
                          .input-group {
                            ng-form {
                              .radio-inline {
                                margin-top: 0;
                              }
                            }
                          }
                          label {
                            &.control-label {
                              & + .input-group {
                                .radio-inline {
                                  margin-top: 0;
                                }
                              }
                            }
                          }
                        }
                        label {
                          &.control-label {
                            & + .input-group {
                              .radio-inline {
                                margin-top: 0;
                              }
                              .radio {
                                &:first-child {
                                  margin-top: 0;
                                }
                              }
                            }
                          }
                        }
                        .input-group {
                          &:first-child {
                            .radio-inline {
                              margin-top: 0px;
                            }
                          }
                        }
                        &.remove-space-top {
                          .input-group {
                            .radio-inline {
                              margin-top: 12px;
                            }
                            .radio {
                              margin-top: 12px;

                              &:first-child {
                                margin-top: 13px;
                              }
                            }
                          }
                        }
                      }
                      &:where(.form-field-type-textfield, .form-field-type-number, .form-field-type-email, .form-field-type-phoneNumber) {
                        &.field-plus-button {
                          input {
                            &.form-control {
                              border-top-right-radius: 0 !important;
                              border-bottom-right-radius: 0 !important;
                            }
                          }
                        }
                      }
                    }
                    fieldset {
                      legend {
                        line-height: 34px;
                        display: block;
                      }
                    }
                    .formio-component-textfield {
                      display: flex;
                      flex-direction: column;
                    }
                  }
                  &.row {
                    display: table-row;

                    &::after,
                    &::before {
                      display: none;
                    }
                    td {
                      display: table-cell;

                      &:first-child {
                        padding-inline-start: 0;
                      }
                      &:last-child {
                        padding-inline-end: 0;
                      }
                    }
                  }
                  &:first-child {
                    > td {
                      > .form-group {
                        &:first-child {
                          &:not(.ng-hide) {
                            margin-top: 0;
                          }
                        }
                      }
                    }
                    & ~ tr {
                      > td {
                        > .form-group {
                          &:first-child {
                            margin-top: 3rem;
                          }
                          &.top-label-space:first-child {
                            margin-top: calc(3rem + 3px + 29px) !important;
                          }
                          &.formio-hidden {
                            & + .formio-component:not(.formio-hidden) {
                              margin-top: 3rem !important;
                            }
                          }
                          &.form-field-type-checkbox {
                            .checkbox {
                              margin-top: 0;
                            }
                            &.top-label-space {
                              .checkbox {
                                margin-top: 0;
                              }
                            }
                          }
                          &.form-field-type-radio {
                            .input-group {
                              &:first-child {
                                margin-top: 0;
                              }
                            }
                            &.top-label-space {
                              .input-group {
                                &:first-child {
                                  padding-top: 34px;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  &:last-child {
                    > td {
                      .formio-component {
                        &.formio-component-panel {
                          &:last-child {
                            margin-bottom: 0;
                          }
                        }
                      }
                    }
                  }
                }
              }
              &.table-bordered {
                > tbody {
                  > tr {
                    > td {
                      border: solid 1px var(--color-gray-200) !important;
                      padding: 16px;
                    }
                    & ~ tr {
                      > td {
                        .formio-component {
                          &:first-child {
                            margin-top: 0px !important;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          &.vertical-align-center {
            table.table {
              > tbody {
                > tr {
                  > td {
                    vertical-align: middle;
                  }
                }
              }
            }
          }
          &.table-responsive {
            @media screen and (max-width: 768px) {
              border: none;
            }
            @media screen and (max-width: 580px) {
              table {
                &.table {
                  tbody {
                    tr {
                      td {
                        display: table;
                        width: 100%;

                        & + td {
                          margin-top: 3rem;

                          > .formio-component {
                            &:first-child {
                              margin-top: 0;
                            }
                          }
                        }
                        .formio-component-textfield {
                          &.field-plus-button {
                            input {
                              &.form-control {
                                border-radius: @roundedCorners !important;
                              }
                            }
                          }
                        }
                        .formio-component-button {
                          &.field-plus-button {
                            button {
                              &.btn {
                                border-radius: @roundedCorners !important;
                                margin-inline-start: 0;
                              }
                            }
                          }
                        }
                        &:empty {
                          display: none;
                        }
                      }
                      & + tr {
                        td {
                          &:first-child {
                            margin-top: 3rem;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          &:has(.table-bordered) {
            margin-inline: 0px;
          }
        }
        &.formio-component-columns {
          clear: both;
          margin-inline-start: -12px;
          margin-inline-end: -12px;

          &:not(.formio-hidden) {
            display: flex;
            flex-wrap: wrap;
          }
          &.datagrid-custom-footer {
            margin-top: -26px !important;
            margin-bottom: 79px;
            padding-inline-end: 0;
            margin-inline-start: 0;
            margin-inline-end: 50px;
            border: solid 1px @borderColor;
            border-radius: @roundedCorners;

            > div[class^="col-"] {
              padding: 0;
              flex: 1;

              :where(.formio-component-textfield, .formio-component-number) {
                label {
                  display: none;
                }
                input {
                  border: none;
                  font-weight: 600;
                  background-color: transparent;
                  background-image: none;

                  &[disabled] {
                    background-color: transparent !important;
                  }
                }
              }
              &:not(:first-child) {
                input {
                  padding-inline-start: 28px;
                }
              }
            }
            &.datagrid-add-remove-disabled {
              padding-inline-end: 0;
              margin-top: 6px !important;
              margin-bottom: 0;
              margin-inline-end: 0;
            }
            &.datagrid-condensed {
              margin-top: -32px !important;

              > div {
                &[class^="col-"] {
                  &:not(:first-child) {
                    input {
                      padding-inline-start: 14px;
                    }
                  }
                }
              }
            }
          }
          > div {
            &[class^="col-"] {
              padding-inline-start: 12px;
              padding-inline-end: 12px;
              min-height: 0px;

              &.col-md-12 {
                & ~ [class^="col-"] {
                  .formio-component {
                    &:first-of-type {
                      margin-top: @componentSeparation;
                    }
                  }
                }
              }
              &.col-md-3 {
                &:first-of-type {
                  & + :where(.col-md-9, .col-md-3 + .col-md-3 + .col-md-3) {
                    & ~ [class^="col-"] {
                      > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden) {
                        &:first-of-type {
                          margin-top: 3rem;
                        }
                        &.formio-component-columns {
                          > div[class^="col-"] {
                            > .formio-component:not(.formio-hidden, .hide, .formio-component-hidden) {
                              &:first-of-type {
                                margin-top: 0px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              &.col-md-4 {
                &:first-of-type {
                  & + .col-md-8,
                  & + .col-md-4 + .col-md-4 {
                    & ~ [class^="col-"] {
                      .formio-component {
                        &:first-of-type {
                          margin-top: @componentSeparation;
                        }
                      }
                    }
                  }
                }
              }
              > .formio-component-panel {
                &:has(> .card > [aria-expanded="false"]) {
                  height: initial;
                }
                & + .formio-component-panel {
                  --custom-panel-top-margin: -4px;
                }
              }
              &.cols-grid-end ~ [class^="col-"] {
                > .formio-component:not(.margin-top-zero, .top-space, .top-space-2x, .top-space-3x, .top-label-space) {
                  &:is(:first-of-type, .is-first) {
                    margin-top: @componentSeparation;
                  }
                }
              }
            }
          }
          &.formio-component-totalPrice {
            display: flex;

            .formio-component-htmlelement {
              &.panel-title {
                h4 {
                  font-size: 16px;
                  font-weight: 600;
                }
              }
              &[class*="total"] {
                p[ref="html"] {
                  display: flex;
                  justify-content: end;
                }
              }
            }
            .formio-component-content {
              > [ref="html"] {
                display: flex;
                justify-content: end;
                gap: 4px;
              }
            }
          }
          &.i-swear {
            .form-check.checkbox {
              padding-top: 0px;
            }
            .col-md-1 {
              flex: 0;
              width: auto;
              padding-inline-end: 0;
            }
          }
          &.vertical-align-top {
            align-items: flex-start;
          }
          &.vertical-align-center {
            align-items: center;
          }
          &.vertical-align-bottom {
            align-items: flex-end;
          }
          &:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) {
            --columns-bg-color: transparent;
            background-color: var(--columns-bg-color);
            border-radius: @roundedCorners;
            margin-inline: 0;
            padding: 24px 12px;
          }
          &.background-red {
            --columns-bg-color: @errorNotice15Solid;
          }
          &.background-blue {
            --columns-bg-color: @infoNotice15Solid;
          }
          &.background-green {
            --columns-bg-color: @successNotice15Solid;
          }
          &.background-orange {
            --columns-bg-color: @warningNotice15Solid;
          }
          &.background-gray {
            --columns-bg-color: var(--color-gray-75);
          }
          &.background-primary {
            --columns-bg-color: @accentColor075;
          }
          &.background-white {
            --columns-bg-color: #FFFFFF;
          }
          &.top-line {
            border-top: solid 1px var(--color-gray-200);
            padding-top: 10px;
            padding-bottom: 10px;
            margin-inline: 0px;

            > [class*="col-"] {
              &:first-child {
                padding-inline-start: 0px;
              }
              &:last-child {
                padding-inline-end: 0px;
              }
            }
          }
        }
        &.formio-component-panel {
          &[class*="formio-component-paymentPagePayments"] {
            .card {
              .card-body {
                .formio-component-columns {
                  &.default-pay-form-payments {
                    padding-bottom: 0;
                    margin-bottom: 12px;
                    display: flex;

                    .formio-component {
                      &.formio-component-checkbox {
                        .checkbox {
                          padding-top: 0;
                          cursor: pointer;

                          input {
                            &::before {
                              cursor: pointer;
                            }
                          }
                        }
                      }
                      &.formio-component-htmlelement {
                        margin: 0;

                        .tile-text {
                          label {
                            margin: 0;
                            display: flex;
                          }
                        }
                      }
                    }
                    &:nth-last-child(2) {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }
          }
          &.payment-form-component {
            .formio-component-totalPrice {
              border-top: none;

              &::before {
                display: block;
                height: 1px;
                background-color: @borderColor;
                position: absolute;
                top: 0;
                width: calc(100% - 24px);
                inset-inline-start: 12px;
              }
            }
            [class*="formio-component-price_"] {
              margin-top: 0px !important;

              :is(p, span) {
                font-size: @defaultTextSize !important;
              }
            }
          }
          > .card {
            > .card-body {
              > .formio-component {
                &.formio-component-editgrid,
                &.formio-component-columns > [class*="col-"] > .formio-component-editgrid {
                  .list-group {
                    &.has-open-row {
                      .list-group-item {
                        &:only-child {
                          > .formio-component {
                            &:first-child {
                              margin-top: 0px !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          &.limited-height {
            > .card {
              > .card-body {
                max-height: 10cm;
                overflow-y: auto;
              }
            }
          }
          &.full-height {
            > .card {
              height: 100%;
            }
          }
          &.notice {
            border-inline-start: none;

            > .card {
              background-color: @accentColor10Solid !important;
              border-inline-start: none;

              > .card-header {
                background-color: transparent !important;
              }
            }
          }
          &:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) {
            --panel-bg-color: white;
            --notice-color-rgb: 243, 244, 245;
            background-color: var(--panel-bg-color);

            > .card {
              background: transparent;

              > .card-header {
                &::after {
                  background-color: rgba(var(--notice-color-rgb), 0.15);
                }
              }
              .formio-component-panel {
                > .card {
                  background-color: var(--custom-bg-color, transparent) !important;
                }
              }
            }
            &.highlight-block {
              > .card {
                --highlight-border-color: rgba(var(--notice-color-rgb), 1);
              }
            }
          }
          &.background-red {
            --panel-bg-color: @errorNotice15Solid;
            --notice-color-rgb: @errorNoticeRGB;
          }
          &.background-blue {
            --panel-bg-color: @infoNotice15Solid;
            --notice-color-rgb: @infoNoticeRGB;
          }
          &.background-white {
            --panel-bg-color: #FFFFFF;
            --notice-color-rgb: 255, 255, 255;
          }
          &.background-green {
            --panel-bg-color: @successNotice15Solid;
            --notice-color-rgb: @successNoticeRGB;
          }
          &.background-orange {
            --panel-bg-color: @warningNotice15Solid;
            --notice-color-rgb: @warningNoticeRGB;
          }
          &.background-gray {
            --panel-bg-color: var(--color-gray-75);
          }
          &.background-primary {
            --panel-bg-color: @accentColor075;
          }
        }
        &.formio-component-hidden {
          &:first-child {
            & + .formio-component {
              margin-top: 0;
            }
          }
        }
        &.formio-component-leafletmap {
          .map-container {
            border: solid 10px white;
            border-radius: @roundedCorners;
            box-shadow: 0 4px 16px rgba(0,0,0,0.05);
          }
          .leaflet-control {
            border-radius: @roundedCorners;
            border: 5px solid rgba(0,0,0,.2);
            box-shadow: 0 2px 6px rgba(0,0,0,0.1);
          }
          :is(.leaflet-control-zoom-in, .leaflet-control-zoom-out) {
            text-decoration: none;
            color: @darkTextColor;
          }
          .leaflet-control-zoom-in {
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
          }
          .leaflet-control-zoom-out {
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
          }
          .leaflet-control-geocoder {
            display: grid;
            grid-auto-flow: column;
          }
          .leaflet-control-geocoder-icon {
            grid-column: 1;
            background: no-repeat;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            &::before {
              -webkit-font-smoothing: antialiased;
              display: inline-block;
              font-style: normal;
              font-variant: normal;
              text-rendering: auto;
              line-height: 1;
              font-family: "Font Awesome 5 Pro";
              font-weight: 400;
              font-size: 14px;
              content: "\f002";
              color: @darkTextColor;
            }
          }
          .leaflet-control-geocoder-form {
            grid-column: 2;
            height: 30px;
            font-size: 12px;
            font-family: "Open Sans";
          }
          .leaflet-control-geocoder-expanded .leaflet-control-geocoder-form {
            display: flex;
          }
          .leaflet-control-geocoder-form-no-error {
            grid-column: 1 / 3;
            grid-row: 2;
            font-size: 12px;
            font-family: "Open Sans";
            padding-inline: 10px;
            padding-block: 6px;
            font-weight: 600;
            margin: 0 !important;
          }
          .leaflet-control-geocoder-alternatives {
            width: 100%;
            font-size: 14px;
            font-family: "Open Sans";
            grid-row: 2;
            grid-column: 1/3;
          }
          .leaflet-control-geocoder-options-open {
            :is(.leaflet-control-geocoder-icon, .leaflet-control-geocoder-form) {
              background-color: #EFEFEF;
            }
          }
        }
        &.formio-component-signature {
          .signature-pad-refresh {
            i {
              font-family: var(--fontawesome-font);
              font-weight: 400;
              font-size: 14px;

              &::before {
                content: "\f304";
              }
            }
          }
          .signature-pad-body {
            border: solid 1px var(--color-gray-100);
            border-radius: @roundedCorners;

            canvas {
              cursor: crosshair;
            }
          }
          [value="No"] {
            & ~ .signature-pad-body {
              :where(canvas, img[ref="signatureImage"]) {
                cursor: not-allowed;
              }
            }
          }
          [value^="data:"] {
            & ~ .signature-pad-body {
              i {
                color: @errorNotice;

                &::before {
                  content: "\f2ed";
                }
              }
            }
          }
          img[ref="signatureImage"] {
            filter: contrast(1.1) brightness(1.1);
          }
        }
        &.formio-component-cubanproductlist {
          .modal-content {
            .modal-header {
              background-color: var(--color-gray-75);
            }
            .modal-body {
              background-color: var(--color-gray-75);
              padding-top: 15px !important;
            }
          }
        }
        // Multiple
        &:where(.formio-component-textfield, .formio-component-number, .formio-component-email, .formio-component-phoneNumber, .formio-component-currency, .formio-component-datetime, .formio-component-textarea, .formio-component-url, .formio-component-address) {
          &.formio-component-multiple {
            table {
              &.table {
                table-layout: auto;
                border: none;

                tbody {
                  tr {
                    td {
                      padding: 12px 0 0;
                      border: none;

                      &:first-child {
                        width: 100%;

                        & + td {
                          button {
                            &.btn {
                              background-color: transparent;
                              height: 40px;
                              font-size: 20px;
                              line-height: 1;
                              border: none;

                              &[ref="removeRow"] {
                                color: @errorNotice;

                                i {
                                  font-family: var(--fontawesome-font);
                                  font-weight: 300;

                                  &::before {
                                    content: "\F057";
                                  }
                                }
                                &:hover {
                                  background-color: @errorNotice5;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    &:last-child {
                      td {
                        padding-top: 0;

                        button {
                          &.btn.formio-button-add-another {
                            border: solid 1px var(--color-primary-600);
                            background-color: transparent;
                            border-radius: @roundedCorners !important;
                            color: var(--color-primary-600);
                            padding-inline-start: 25px;
                            padding-inline-end: 25px;
                            font-weight: 600;
                            font-size: 12px;
                            height: 40px;
                            display: inline-flex;
                            align-items: center;
                            transition-duration: 0.3s;
                            margin-top: 10px;

                            > i {
                              display: none;
                            }
                            &:hover {
                              color: @whiteColor;
                              background-color: var(--color-primary-600);
                              box-shadow: 0 9px 20px @accentColor40 !important;
                            }
                          }
                        }
                      }
                    }
                    &:first-child {
                      td {
                        padding-top: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
        // Fields used as notice
        &:is(.formio-component-textfield, .formio-component-number, .formio-component-textarea) {
          &.notice {
            background: @darkTextColor3 !important;
            border-inline-start: solid 3px;
            border-radius: 0 @roundedCorners @roundedCorners 0;
            padding: 2rem;
            border-left-color: var(--color-primary-600);

            :is(input, textarea) {
              background-color: transparent;
              border: none !important;
              padding: 0;
              height: auto;
              font-weight: 600;

              &[disabled] {
                background-color: transparent !important;
                margin-bottom: -13px;
              }
            }
          }
        }
        // Aligning classes
        &.text-right {
          :where(label, input, textarea) {
            text-align: end !important;
          }
          &.formio-component-panel {
            > .card {
              > .card-header {
                .card-title {
                  flex-direction: row-reverse;

                  .formio-collapse-icon {
                    inset-inline-start: 0;
                    inset-inline-end: initial;
                  }
                  .formio-collapse-text {
                    margin-inline-end: auto;
                    margin-inline-start: 24px;
                  }
                }
              }
            }
          }
        }
        &.align-value-right {
          :where(input, textarea) {
            &:not(.choices__input) {
              text-align: end !important;
            }
          }
          &.required {
            :where(input, textarea) {
              &:not(.choices__input) {
                padding-inline-end: 20px;
              }
            }
          }
        }
        &.has-suffix:not(.formio-component-datetime) {
          &.required {
            label.field-required.control-label--hidden {
              &::after {
                display: none;
              }
              & + [ref="element"] {
                .input-group-append {
                  position: relative;

                  &::before {
                    content: " ";
                    display: inline-block;
                    background-color: @errorColor70;
                    vertical-align: unset;
                    width: 6px;
                    height: 6px;
                    border-radius: 5px;
                    position: absolute;
                    top: calc(50% - 3px);
                    inset-inline-start: -16px;
                    min-width: 6px;
                    z-index: 9;
                  }
                }
              }
            }
          }
        }
        &.horizontal-align-left {
          text-align: start;
        }
        &.horizontal-align-center {
          text-align: center;

          &.formio-component-radio {
            display: grid;
            place-content: center;
          }
          &:is(.formio-component-content, .formio-component-htmlelement) {
            .image {
              margin-inline: auto;
            }
          }
        }
        &.horizontal-align-right {
          text-align: end;

          &.formio-component-radio {
            display: grid;
            place-content: end;
          }
        }
        &:is(.size-xs, .size-sm, .size-md, .size-lg) {
          .input-group-text i,
          span.value,
          .formio-choices::after,
          .input-group-text {
            font-size: var(--field-font-size);
          }
          .choices[data-type*=select-one] .choices__button {
            background-size: var(--clear-selection-icon-size);
            margin-inline-end: var(--clear-selection-icon-margin) !important;
          }
          input {
            &.form-control {
              padding-inline: 1rem;
            }
          }
        }
        &.size-xs {
          --field-height: 22px;
          --field-font-size: 10px;
          --clear-selection-icon-size: 6px;
          --clear-selection-icon-margin: 15px;
        }
        &.size-sm {
          --field-height: 32px;
          --field-font-size: 12px;
          --clear-selection-icon-size: 8px;
          --clear-selection-icon-margin: 18px;
        }
        &.size-md {
          --clear-selection-icon-size: 8px;
          --clear-selection-icon-margin: 25px;
        }
        &.size-lg {
          --field-height: 48px;
          --field-font-size: 18px;
          --clear-selection-icon-size: 10px;
          --clear-selection-icon-margin: 30px;

          input {
            &.form-control {
              padding-inline: 1.4rem;
            }
          }
        }
        // Button colors
        .btn-green {
          --button-bg-color: #10B981;
          --button-secondary-bg-color: rgba(@successNoticeRGB, 0.35);
          --status-bg-color: #4BC394;
          --status-secondary-bg-color: #E1F0EE;
          --status-text-color: white;
          --status-secondary-text-color: #10B981;
          --status-secondary-outline-border-color: #9CCEC7;
          --status-outline-border-color: #10B981;
        }
        .btn-blue {
          --button-bg-color: @infoNotice;
          --button-secondary-bg-color: rgba(@infoNoticeRGB, 0.35);
          --status-bg-color: #4CAAE5;
          --status-secondary-bg-color: #E4F2FB;
          --status-text-color: white;
          --status-secondary-text-color: #1D86C8;
          --status-secondary-outline-border-color: #8CC7EE;
          --status-outline-border-color: #1D86C8;
        }
        .btn-orange {
          --button-bg-color: @warningNotice;
          --button-secondary-bg-color: rgba(@warningNoticeRGB, 0.5);
          --status-bg-color: #FFA385;
          --status-secondary-bg-color: @warningNewLight;
          --status-text-color: white;
          --status-secondary-text-color: @warningNew;
          --status-secondary-outline-border-color: #FFA385;
          --status-outline-border-color: @warningNew;
        }
        .btn-red {
          --button-bg-color: @errorNotice;
          --button-secondary-bg-color: rgba(@errorNoticeRGB, 0.35);
          --status-bg-color: #F75F5F;
          --status-secondary-bg-color: #FEE7E7;
          --status-text-color: white;
          --status-secondary-text-color: #F53D3D;
          --status-secondary-outline-border-color: #FA8585;
          --status-outline-border-color: #F53D3D;
        }
        .color-neutral {
          --button-bg-color: var(--color-gray-900);
          --button-secondary-bg-color: var(--color-gray-200);
          --status-bg-color: var(--color-gray-900);
          --status-secondary-bg-color: var(--color-gray-200);
          --status-text-color: white;
          --status-secondary-text-color: var(--color-gray-900);
          --status-secondary-outline-border-color: hsla(221deg, 39%, 11%, 50%);
          --status-outline-border-color: var(--color-gray-900);
        }
        // Button sizes
        button.btn {
          padding-inline: var(--default-button-inline-padding);
          padding-block: var(--default-button-block-padding);
          font-size: var(--default-button-font-size);
          min-height: var(--default-button-height);
          border-radius: var(--default-button-border-radius);
          line-height: 1;
          border: solid var(--button-border-width, 0px) var(--button-border-color, transparent);
          box-shadow: var(--button-shadow, none);

          &:hover {
            --button-shadow: var(--button-shadow-hover);
            opacity: 0.9;
            color: var(--button-text-color-hover);
          }
          &.btn-xs {
            box-shadow: none;
            padding-inline: var(--xs-button-inline-padding);
            padding-block: var(--xs-button-block-padding);
            font-size: var(--xs-button-font-size);
            min-height: var(--xs-button-height);
            border-radius: var(--xs-button-border-radius);

            &.button-status {
              --button-inline-padding: 10px;
            }
          }
          &:is(.btn-sm, [ref*="-saveRow"], [ref*="-cancelRow"]) {
            padding-inline: var(--sm-button-inline-padding);
            padding-block: var(--sm-button-block-padding);
            font-size: var(--sm-button-font-size);
            min-height: var(--sm-button-height);
            border-radius: var(--sm-button-border-radius);

            &.button-status {
              --button-inline-padding: 16px;
            }
          }
          &.btn-md {
            padding-inline: var(--md-button-inline-padding);
            padding-block: var(--md-button-block-padding);
            font-size: var(--md-button-font-size);
            min-height: var(--md-button-height);
            border-radius: var(--md-button-border-radius);

            &.button-status {
              --button-inline-padding: 16px;
            }
          }
          &.btn-lg {
            padding-inline: var(--lg-button-inline-padding);
            padding-block: var(--lg-button-block-padding);
            font-size: var(--lg-button-font-size);
            min-height: var(--lg-button-height);
            border-radius: var(--lg-button-border-radius);

            &.button-status {
              --button-inline-padding: 24px;
            }
          }
          &.btn-xl {
            padding-inline: var(--xl-button-inline-padding);
            padding-block: var(--xl-button-block-padding);
            font-size: var(--xl-button-font-size);
            min-height: var(--xl-button-height);
            border-radius: var(--xl-button-border-radius);

            &.button-status {
              --button-inline-padding: 24px;
            }
          }
          &.no-shadow {
            box-shadow: none;
          }
        }
        :is(.btn-sm, .btn-xs, .btn-lg) {
          &.button-status {
            --button-border-radius: 50px;
          }
        }
        // Label position
        &[style*="label-flex"] {
          .field-wrapper {
            gap: calc(1% * var(--label-flex-margin, 0));
          }
          .field-label {
            flex: calc(100 - var(--label-flex, 1) - var(--label-flex-margin, 0));
          }
          .field-content {
            flex: var(--label-flex, 1);
          }
        }
        .field-label--right {
          :where(label, .col-form-label) {
            --label-text-align: end;
          }
        }
      }
      .formio-form .wizard-page > .formio-component,
      &:not(.new-formio-part-b) .formio-form > .formio-component,
      &.new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component {
        &.guide-form,
        .guide-form {
          margin-top: 9px;
          display: flex !important;
          flex-wrap: wrap;
          gap: 24px;
          margin-inline: 0px !important;

          &::after,
          &::before {
            display: none;
          }
          > .formio-errors {
            width: 100%;
          }
          > div[class^="col-"] {
            align-items: normal;
            align-self: stretch;
            min-width: 320px;
            flex: 1;
            padding-inline: 0px !important;

            > .formio-component {
              &.formio-component-panel {
                &:is(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) {
                  margin-inline-end: 36px;

                  .formio-component-htmlelement {
                    &.formio-component-mandatoryRegistrationsContent {
                      margin: 0 0 1rem !important;

                      p {
                        font-size: 13px !important;
                        line-height: 1.1;
                      }
                    }
                    &:where(.formio-hidden, .hide) {
                      & + .formio-component-checkbox {
                        .form-check {
                          &.checkbox {
                            padding-top: 0;

                            label {
                              &.form-check-label {
                                margin-top: -3px;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  .formio-component-checkbox {
                    margin-top: 0;

                    .form-check {
                      &.checkbox {
                        label {
                          &.form-check-label {
                            span {
                              line-height: 20px;
                            }
                            input {
                              &[type="checkbox"] {
                                &.form-check-input {
                                  &:checked {
                                    & + span {
                                      &::before {
                                        color: var(--color-primary-600);
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                &.formio-component-registrationsPanel {
                  .formio-component-checkbox {
                    margin-top: 0;
                  }
                }
                &.formio-component-FeesPanel {
                  > .card {
                    > .card-body {
                      .formio-component-feesDescription {
                        border: none;
                        padding-top: 0;

                        .formio-component-columns {
                          &[class*="formio-component-priceRow"] {
                            margin-top: 0px;

                            > .col-md-6:first-child {
                              flex: auto;

                              & + .col-md-6 {
                                flex: 0;
                              }
                            }
                            .formio-component-htmlelement {
                              margin: 0;

                              :where(p, p span) {
                                font-size: 14px !important;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  &.formio-hidden {
                    & + .formio-component-requiredDocumentsPanel {
                      margin-top: 0;
                    }
                  }
                  .formio-component-htmlelement:is([class*="price_"]) {
                    [ref="html"] {
                      direction: ltr;
                      display: flex;
                    }
                  }
                }
                &.formio-component-requiredDocumentsPanel {
                  > .card {
                    > .card-body {
                      .formio-component-requiredDocumentsContainer {
                        border: none;
                        margin-top: -1px;

                        .formio-component-htmlelement {
                          line-height: 1.2;
                          margin-bottom: 1rem;
                          padding-bottom: 1rem;
                          border-bottom: solid 1px @borderColor;
                          margin-top: 0;

                          li {
                            div {
                              line-height: 1.5;

                              a {
                                display: none;
                              }
                            }
                          }
                          &:last-child {
                            border: none;
                            padding: 0;
                            margin: 0;
                          }
                          &:first-child {
                            margin-top: 0;
                          }
                        }
                      }
                    }
                  }
                }
                // Special alignment rules:
                &.formio-component-FeesPanel {
                  &.formio-hidden {
                    & + .formio-component {
                      &.formio-component-requiredDocumentsPanel {
                        &:not(.formio-hidden) {
                          & ~ .formio-component {
                            &.formio-component-button {
                              &:last-child {
                                margin-bottom: auto !important;
                              }
                            }
                          }
                        }
                        &.formio-hidden {
                          & + .formio-component {
                            &.formio-component-button {
                              margin-top: 0;
                            }
                          }
                        }
                      }
                    }
                  }
                  &:not(.formio-hidden) {
                    & ~ .formio-component {
                      &.formio-component-button {
                        &:last-child {
                          margin-bottom: auto !important;
                        }
                      }
                    }
                  }
                }
              }
            }
            &.col-md-8 {
              flex: 2;
              margin-inline-end: 30px;
              align-self: flex-start;

              & + .col-md-4 {
                flex: 1;
                position: sticky;
                top: calc(var(--service-bar-height) + 10px);

                :where(.formio-component-mandatoryRegistrationsPanel, .formio-component-optionalRegistrationsPanel) {
                  margin-inline: 0px;
                  margin-top: 0px;

                  > .card {
                    border: none;

                    > .card-header {
                      padding-inline: 0px;

                      > .card-title {
                        text-transform: uppercase;
                        font-weight: 700 !important;
                        color: var(--color-gray-400) !important;
                        font-size: 13px;
                      }
                    }
                    > .card-body {
                      padding: 0px;
                    }
                  }
                }
                .formio-component-registrationsPanel {
                  border-bottom-left-radius: 0px;
                  border-bottom-right-radius: 0px;

                  .formio-collapse-text {
                    display: none;
                  }
                  &:not(.formio-hidden) + .formio-component-FeesPanel {
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                    border-top: solid 2px var(--color-gray-75);

                    > .card {
                      border-radius: 0px;
                    }
                  }
                }
                :is(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel) {
                  margin-top: 0px;
                }
                .formio-component-mandatoryRegistrationsPanel {
                  border-radius: 0px;

                  > .card > .card-body {
                    border-radius: 0px;
                  }
                }
                &:has(.formio-component-requiredDocumentsPanel.formio-hidden) {
                  .formio-component-FeesPanel {
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                    border-top: solid 2px var(--color-gray-75);
                  }
                }
                &:has(.formio-component-requiredDocumentsPanel:not(.formio-hidden)) {
                  .formio-component-FeesPanel {
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                  }
                }
                .formio-component-requiredDocumentsPanel:not(.formio-hidden) {
                  border-top-left-radius: 0px;
                  border-top-right-radius: 0px;
                  border-top: solid 2px var(--color-gray-75);
                }
              }
            }
            &.col-md-5 {
              flex: 2;
              align-self: flex-start;
            }
            &.col-md-3 {
              flex: 1.3;
              position: sticky;
              top: calc(var(--service-bar-height) + 10px);
              align-self: flex-start;

              & + .col-md-4 {
                flex: 1.3;
                position: sticky;
                top: calc(var(--service-bar-height) + 10px);
                align-self: flex-start;
              }
            }
            &.col-md-4 {
              &::before {
                inset-inline-start: -50px;
              }
            }
          }
          .formio-component-requiredDocumentsPanel {
            & ~ .formio-component-button {
              button {
                width: 100%;
              }
            }
          }
          @media screen and (max-width: 1073px) {
            [class*="RegistrationsPanel"] {
              margin-inline-end: 0px !important;
            }
            > div[class^="col-"] {
              &:nth-last-of-type(2) {
                &::before {
                  display: none;
                }
              }
            }
          }
        }
        &.formio-component-htmlelement {
          &:where(.notice, .info-notice, .success-notice, .warning-notice, .error-notice) {
            background: @whiteColor70 !important;
          }
        }
        &.formio-component-columns {
          &.datagrid-custom-footer {
            margin-top: -56px !important;
            margin-bottom: 49px;
          }
        }
        &.formio-component-editgrid {
          :where(.new-entry, .editing-entry) {
            display: inherit;
            flex-direction: inherit;

            > .formio-component-panel {
              > .card {
                border-radius: @roundedCorners !important;
              }
            }
            .formio-component-columns.row:not(.vertical-align-bottom, .vertical-align-center) {
              align-items: stretch;
            }
          }
          .new-entry {
            > .formio-component-panel {
              > .card {
                border: solid 1px var(--color-gray-200);
              }
            }
          }
          .editing-entry {
            > .formio-component-panel {
              > .card {
                border: solid 1px var(--color-gray-300);
              }
            }
          }
        }
      }
      .formio-form {
        .wizard-page {
          padding-top: 0px;

          > .formio-component {
            &.guide-form {
              margin-top: 0;
              display: flex !important;
            }
          }
        }
      }
      &.pagename-off {
        .formio-form {
          > .formio-component {
            &.formio-component-panel {
              &[class*="roleapplicant"] {
                display: contents;

                > .card {
                  display: contents;

                  > .card-body:only-child {
                    display: contents;

                    > .formio-component {
                      margin-top: @componentSeparation !important;
                    }
                  }
                }
              }
            }
          }
        }
        .wizard-page {
          padding-top: 36px;
        }
        > .formio-component-form {
          margin-top: calc(@componentSeparation * 2);
        }
      }
      &:not(.new-formio-part-b) > .formio-component-form {
        margin-top: @componentSeparation;

        > .formio-form {
          .formio-component {
            &.formio-component-panel {
              &.payment-form-component {
                .formio-component-columns {
                  margin-top: 0;
                  align-items: center;

                  &[class*="cost"] {
                    .formio-component-htmlelement {
                      margin: 0;

                      &:first-child {
                        margin: 0;
                      }
                      p {
                        margin-bottom: 0;
                      }
                    }
                  }
                  &.formio-component-totalPrice {
                    .formio-component-htmlelement {
                      &:first-child {
                        margin: 0;
                      }
                      h4 {
                        font-weight: 600;
                      }
                      p {
                        span {
                          font-weight: 600;
                        }
                      }
                    }
                  }
                }
                & ~ .formio-component.formio-component-columns.formio-component-conclusionRow {
                  padding-inline-start: 0 !important;
                }
              }
              .formio-component {
                &.formio-component-columns {
                  &.default-pay-form-payments {
                    .radio {
                      .form-check {
                        padding-top: 0;
                        cursor: pointer;
                      }
                    }
                    .formio-component {
                      &.formio-component-htmlelement {
                        display: flex;

                        .tile-text {
                          display: flex;

                          label {
                            margin-bottom: 0;
                          }
                        }
                      }
                    }
                    > [class*="col-"] {
                      display: flex;
                    }
                  }
                  &.overlay-simple {
                    margin-top: 0;
                    min-height: 30px;
                    align-items: center;

                    .col-md-1 {
                      flex: 0;

                      .formio-component-checkbox {
                        .form-check {
                          &.checkbox {
                            padding-top: 0;

                            label {
                              &.form-check-label {
                                width: 14px !important;
                                height: 14px;

                                input {
                                  &.form-check-input {
                                    margin: 0;
                                    opacity: 1;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    .col-md-11 {
                      flex: auto;
                    }
                  }
                }
              }
              &.formio-component-applicantPaymentFees {
                .formio-component-columns {
                  margin-top: 0;
                  align-items: center;

                  .formio-component-htmlelement {
                    margin-top: 0;
                  }
                  &.formio-component-totalPrice {
                    .formio-component-htmlelement {
                      &:first-child {
                        margin: 0;
                      }
                      h4 {
                        font-weight: 600;
                      }
                      p {
                        span {
                          font-weight: 600;
                        }
                      }
                    }
                  }
                }
              }
              &.formio-component-applicantPaymentPayments {
                > .card {
                  > .card-body {
                    > .formio-component-columns {
                      padding-bottom: 0;

                      .formio-component-checkbox {
                        .checkbox {
                          margin-top: -5px;
                          padding-top: 0;

                          .form-check-label {
                            margin-top: 0;
                            cursor: pointer;

                            input {
                              &[type="radio"] {
                                appearance: none;
                                margin-top: 0;
                                cursor: pointer;

                                &::before {
                                  -webkit-font-smoothing: antialiased;
                                  display: inline-block;
                                  font-style: normal;
                                  font-variant: normal;
                                  text-rendering: auto;
                                  line-height: 1;
                                  font-family: "Font Awesome 5 Pro";
                                  font-weight: 400;
                                  font-size: @defaultTextSize;
                                  content: "\f111";
                                  color: @accentColor50;
                                }
                                &:checked {
                                  &::before {
                                    content: "\f192";
                                    font-weight: 900;
                                    color: var(--color-primary-600);
                                  }
                                }
                                &:focus {
                                  outline: none;
                                }
                                &:hover {
                                  &::before {
                                    color: var(--color-primary-600);
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                & + .formio-component-button {
                  button {
                    &.btn {
                      border-radius: @roundedCorners !important;
                      background-color: var(--color-primary-600);
                      padding-inline-start: 25px;
                      padding-inline-end: 25px;
                      font-weight: 600;
                      text-transform: none;
                      position: relative;
                      z-index: 1;

                      &:hover {
                        box-shadow: 0 9px 20px @accentColor40 !important;
                        opacity: 0.9;
                      }
                    }
                  }
                }
              }
            }
            &.formio-component-part-b-processing-tab {
              & ~ .formio-component {
                .formio-component {
                  &.formio-component-columns {
                    &.default-pay-form-payments {
                      & + .overlay-simple {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
            &.formio-component-part-b-documents-tab {
              & + .formio-component {
                margin-top: 0;
              }
              & ~ .formio-component-panel {
                .card {
                  background-color: transparent;

                  .card-header {
                      padding: 0;
                  }
                  .card-body {
                    padding-top: 2.5rem !important;
                  }
                }
              }
            }
          }
        }
      }
      > .alert {
        &.alert-success {
          position: fixed !important;
          top: 19px;
          inset-inline-end: 0;
          z-index: 99999;
          width: auto;
          max-width: 320px;
          background: @successNotification !important;
          color: @whiteColor!important;
          padding: 1rem 2rem !important;
          font-size: 16px !important;
          inset-inline-end: 16px;
          -webkit-animation-duration: 2s;
          animation-duration: 2s;
          -webkit-animation-delay: 1s;
          animation-delay: 1s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
          -webkit-animation-name: fadeOut;
          animation-name: fadeOut;

          &::after {
            display: none !important;
          }
        }
        &.alert-danger {
          --alert-color: 247, 95, 95;
          bottom: auto;
          top: 156px;
          z-index: 99999;
          transition-duration: 0.2s;
          padding-inline-start: 24px !important;
          border-color: rgb(var(--alert-color)) !important;
          box-shadow: 0 5px 20px rgba(var(--alert-color), 0.4);
          inset-inline-end: 2.5%;//calc(((100vw - 1200px) / 2) - 10px);
          width: fit-content;
          display: flex;
          flex-wrap: wrap;
          max-width: 360px;
          flex-direction: row-reverse;
          justify-content: space-between;

          button {
            &.close-button {
              color: transparent;
              position: relative;
              top: -10px;
              inset-inline-end: -10px;
              border-radius: @roundedCorners;
              height: 36px;
              float: none;
              width: 40px;
              padding: 0;
              text-align: center;
              flex: 0 0 40px;

              &::before {
                font-family: var(--fontawesome-font);
                font-weight: 300;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                content: "\F00D";
                color: @darkTextColor;
                position: absolute;
                font-size: 16px;
                top: calc(50% - 8px);
                width: 14px;
                inset-inline-start: calc(50% - 7px);
              }
              &:hover {
                background: @accentColor10;
              }
            }
          }
          p {
            font-weight: 600;
            color: rgb(var(--alert-color));
            line-height: 1.3 !important;
            flex: 0 0 calc(100% - 40px);
            margin-bottom: 12px;
          }
          ul {
            padding-inline-start: 14px;
            overflow-y: auto;
            width: 100%;
            padding-inline-end: 16px;
            max-height: ~"min(calc(100vh - 200px), 320px)";
            margin-top: 0px;

            li {
              margin-bottom: 8px;
              font-weight: 500;
              transition-duration: 0.15s;

              &:last-child {
                margin-bottom: 0;
              }
              &:hover {
                padding-inline-start: 0;
                background: no-repeat;

                &::marker {
                  content: "\f0a9";
                }
                span {
                  &::after {
                    display: none;
                  }
                }
              }
              span {
                margin-inline-start: 8px;
                text-decoration: none;
                display: block;
                color: var(--color-gray-900);

                &:hover {
                  text-decoration: underline;
                }
              }
              &::marker {
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                font-family: var(--fontawesome-font);
                font-weight: 400;
                content: "\f06a";
                color: rgb(var(--alert-color));
              }
            }
            &::-webkit-scrollbar {
              width: 6px;
              height: 6px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 4px;
              background-color: rgba(var(--alert-color), 0.8);
              transition-duration: .15s;
            }
            &::-webkit-scrollbar-track {
              background-color: rgba(var(--alert-color), 0.3);
            }
          }
          @media screen and (max-width: 1200px) {
            inset-inline-end: 2.4rem;
          }
        }
      }
      .tooltip {
        .tooltip-inner {
          max-width: 240px;
          white-space: break-spaces;
          width: max-content;
        }
      }
      .part-b-processing-tab {
        .formio-component-modal-wrapper {
          margin-bottom: 0px;

          > [ref="openModalWrapper"] {
            button {
              &.btn {
                --button-bg-color: @buttonAccentColor;
                --button-secondary-bg-color: @accentColor10;
                --button-text-color: @whiteColor;
                --button-text-color-hover: @whiteColor;
                --button-outline-bg-color: transparent;


                background-color: var(--button-bg-color);

                font-weight: 500;
                color: var(--button-text-color);
                text-transform: none;
                position: relative;
                z-index: 1;
                line-height: 1;
                text-align: center;
                gap: 8px;

                .fa-cog {
                  position: absolute;
                  animation: none;
                  background: inherit;
                  width: 100%;
                  z-index: 9;
                  color: transparent;

                  & + span {
                    position: absolute !important;
                    top: -7px;
                    z-index: 99;
                    border-radius: 50%;
                    width: 8px;
                    height: 8px;
                    -webkit-animation-fill-mode: both;
                    animation-fill-mode: both;
                    -webkit-animation: load7 1.8s infinite ease-in-out;
                    animation: load7 1.8s infinite ease-in-out;
                    color: #ffffff;
                    font-size: 10px;
                    margin: 0 auto;
                    position: relative;
                    text-indent: -9999em;
                    -webkit-transform: translateZ(0);
                    -ms-transform: translateZ(0);
                    transform: translateZ(0);
                    -webkit-animation-delay: -0.16s;
                    animation-delay: -0.16s;

                    &::after,
                    &::before {
                      border-radius: 50%;
                      width: 8px;
                      height: 8px;
                      -webkit-animation-fill-mode: both;
                      animation-fill-mode: both;
                      -webkit-animation: load7 1.8s infinite ease-in-out;
                      animation: load7 1.8s infinite ease-in-out;
                      content: '';
                      position: absolute;
                      top: 0;
                    }
                    &::before {
                      inset-inline-start: -16px;
                      -webkit-animation-delay: -0.32s;
                      animation-delay: -0.32s;
                    }
                    &::after {
                      inset-inline-start: 16px;
                    }
                  }
                }
                &:hover {
                  --button-shadow: var(--button-shadow-hover);
                  opacity: 0.9;
                  color: var(--button-text-color-hover);
                }
                .fa-question-circle {
                  color: @whiteColor;

                  &::before {
                    color: inherit;
                  }
                }
                &:is(.btn-secondary, .light-color) {
                  --button-text-color: @darkTextColor;
                  --button-text-color-hover: @darkTextColor;

                  background-color: var(--button-secondary-bg-color);
                }
                &.outline-button {
                  --button-text-color: var(--button-bg-color);
                  --button-text-color-hover: var(--button-bg-color);
                  --button-border-width: 1px;
                  --button-border-color: var(--button-bg-color);
                  background-color: var(--button-outline-bg-color);

                  &:is(.btn-secondary, .light-color) {
                    --button-border-color: var(--button-secondary-bg-color);
                    --status-secondary-outline-border-color: @accentColor50;
                  }
                }
              }
            }
          }
          > [ref="modalWrapper"] {
            .formio-dialog-close {
              padding: 0 !important;
              box-shadow: none !important;
            }
            [ref="modalContents"] {
              > .formio-component {
                &.formio-component-panel:first-child {
                  margin: 0;
                  --wrapper-panel-margin: 0;

                  > .card {
                    background-color: transparent !important;
                    margin-bottom: 0 !important;
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                    border: none;

                    .card-header {
                      padding: 0 0 30px;
                      border-bottom: none;
                    }
                    .card-body {
                      padding: 0px;

                      .formio-component-select {
                        &[class*="back"] {
                          .field-wrapper {
                            display: block;

                            label {
                              &.col-form-label {
                                display: block;
                                margin-bottom: 8px;
                              }
                            }
                          }
                        }
                      }
                      .formio-component-conclusionRow {
                        margin-bottom: 0 !important;
                      }
                      [class*="formio-component-conclusionRow"] {
                        .col-md-9 {
                          flex: auto;
                          width: initial;
                        }
                        .col-md-3 {
                          min-width: fit-content;
                          width: initial;
                        }
                        .formio-errors {
                          width: 100%;
                        }
                      }
                    }
                    &::after {
                      display: none !important;
                    }
                  }
                }
              }
              > .formio-dialog-buttons {
                display: none;
              }
            }
          }
        }
        .formio-component-tabs {
          .formio-component-panel {
            &:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary) {
              --custom-sibling-panel-top-margin: 0;
              --custom-panel-top-margin: 0;

              > .card {
                margin-inline: 0 !important;
                padding: 28px !important;

                &::before,
                &::after {
                  display: none !important;
                }
              }
            }
          }
        }
        .readonly-forms-block {
          margin-inline: 0px;

          .formio-component-panel {
            margin-inline: 0px;
          }
        }
      }
      .formio-form {
        nav {
          ul.pagination {
            li {
              span.page-link {
                color: var(--wizard-page-link-text-color, #333);
                background-color: var(--wizard-page-link-bg-color, transparent);
                font-size: var(--wizard-page-link-text-size, 24px);
                font-weight: var(--wizard-page-link-text-weight, 500);
                padding: var(--wizard-page-link-block-padding, 8px) var(--wizard-page-link-inline-padding, 28px);
                border-radius: var(--wizard-page-link-border-radius);

                &::before {
                  font-size: 24px !important;
                  border: none !important;
                  font-weight: 500 !important;
                  color: @menuBarColor;
                  line-height: 22px !important;
                  margin-inline-end: 4px !important;
                  color: var(--color-gray-400) !important;
                }
                &.progress-complete {
                  &::before {
                    -moz-osx-font-smoothing: grayscale;
                    -webkit-font-smoothing: antialiased;
                    display: inline-block;
                    font-style: normal;
                    font-variant: normal;
                    text-rendering: auto;
                    line-height: 1;
                    font-family: var(--fontawesome-font);
                    font-weight: 400;
                    color: @menuBarColor !important;
                    content: "\F058" !important;
                    font-size: 21px !important;
                    margin-inline-end: 9px !important;
                  }
                }
                svg {
                  display: none !important;
                }
              }
              &:is(:hover, .active) {
                span.page-link {
                  color: var(--wizard-page-link-active-text-color, #000);
                  background-color: var(--wizard-page-link-active-bg-color, #CCC);
                }
              }
            }
          }
        }
      }
      &:not(.new-formio-part-b) .formio-component,
      .wizard-page .formio-component,
      &.new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component,
      &.new-formio-part-b .formio-form .formio-component-part-b-processing-tab ~ .formio-component .formio-component,
      .formio-dialog-content .formio-component {
        label {
          &.col-form-label {
            font-size: var(--label-font-size, 14px) !important;
            margin-bottom: var(--label-bottom-margin, 10px) !important;
            color: var(--label-text-color, var(--color-gray-600)) !important;
            font-weight: 500 !important;
            font-family: var(--main-font-family, var(--fallback-font-family, 'Arial'));
          }
        }
        input.form-control {
          letter-spacing: 0 !important;
          font-size: var(--field-font-size);
          font-weight: var(--field-font-weight, 400) !important;
        }
        &.formio-component-editgrid {
          > button {
            &.btn {
              background-color: @accentColor10;

              &:hover {
                background-color: var(--color-primary-600);
              }
            }
          }
          .list-group .list-group-item > .row:not(.formio-component-columns) > div {
            padding-bottom: 10px;
          }
        }
        &.formio-component-datagrid {
          > table {
            &.table {
              &.datagrid-table {
                :is(th, td):is(.checkbox, .radio, .button) {
                  display: table-cell;
                  box-shadow: none !important;
                }
                td.checkbox {
                  padding-inline: 28px !important;
                }
                tfoot {
                  tr {
                    td {
                      button {
                        &.btn {
                          &.formio-button-add-row {
                            background-color: @accentColor10;

                            &:hover {
                              background-color: var(--color-primary-600);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.formio-component-checkbox {
          .form-check.checkbox {
            label.form-check-label {
              span {
                font-size: 16px;
                color: @darkTextColor;
                font-weight: 400;
              }
              input.form-check-input:checked + span {
                color: @darkTextColor;

                &::before {
                  color: @menuBarColor !important;
                }
              }
            }
          }
          .form-check-input:disabled {
            background-color: transparent;
          }
        }
        &.formio-component-radio:not(.radio-switch) {
          .form-radio.radio {
            label.form-check-label {
              span {
                font-size: @defaultTextSize;
                font-weight: 500;
                color: @accentColor50;
                margin-inline-start: 6px;
                position: relative;
                top: 2px;
                line-height: 14px;
                display: block;
                margin-block: -3px;
              }
              input.form-check-input[type="radio"] {
                &::before {
                  font-size: @defaultTextSize;
                  content: "\f111";
                  color: @accentColor50;
                }
                &:disabled {
                  background: transparent !important;

                  & + span {
                    pointer-events: none;
                    color: var(--color-gray-500) !important;
                  }
                }
              }
              &:hover {
                color: var(--color-primary-600);
              }
            }
            .form-check {
              &.radio-selected {
                label.form-check-label {
                  color: var(--color-primary-600);

                  :is(span, input, input::before) {
                    color: inherit !important;
                  }
                }
              }
            }
          }
        }
        &.formio-component-panel {
          > .card {
            > .card-header {
              > .card-title {
                color: @darkTextColor !important;
                font-weight: 600 !important;
              }
            }
          }
        }
        &.formio-component-select {
          span.key {
            margin-inline-end: 8px;
          }
          span.value {
            font-size: 16px;
            letter-spacing: 0;
            font-weight: var(--field-font-weight);
          }
          &.value-label-style {
            span.value[ref="tooltip"] {
              &::before,
              &::after {
                display: none !important;
              }
            }
          }
        }
        button {
          &.btn.formio-button-add-another {
            border: solid 1px @darkTextColor !important;
            color: @darkTextColor !important;

            &:hover {
              color: @whiteColor !important;
              background-color: @darkTextColor !important;
              box-shadow: 0 9px 20px @darkTextColor40 !important;
            }
          }
        }
      }
      .formio-dialog-content {
        background-color: white;
        padding: 24px;
        border-radius: @roundedCorners;
      }
      > .modal-edit-row {
        > .formio-dialog {
          place-content: center;

          .formio-dialog-close {
            background-color: white;
          }
          .formio-dialog-buttons {
            > button.btn.formio-dialog-button {
              --button-bg-color: @buttonAccentColor;
              --button-text-color: @whiteColor;
              --button-text-color-hover: @whiteColor;
              background-color: var(--button-bg-color);
              font-weight: 500;
              color: var(--button-text-color);
              padding-inline: var(--md-button-inline-padding);
              padding-block: var(--md-button-block-padding);
              font-size: var(--md-button-font-size);
              min-height: var(--md-button-height);
              border-radius: var(--md-button-border-radius);
              line-height: 1;
              margin-top: 24px;
              text-transform: initial;
            }
          }
        }
      }
    }
    > [class*="editgrid-row-modal-"] {
      > .formio-dialog {
        place-content: center;
      }
      .formio-dialog-content {
        > button.btn.btn-primary {
          --button-bg-color: @buttonAccentColor;
          --button-text-color: @whiteColor;
          --button-text-color-hover: @whiteColor;
          background-color: var(--button-bg-color);
          font-weight: 500;
          color: var(--button-text-color);
          padding-inline: var(--md-button-inline-padding);
          padding-block: var(--md-button-block-padding);
          font-size: var(--md-button-font-size);
          min-height: var(--md-button-height);
          border-radius: var(--md-button-border-radius);
          line-height: 1;
          margin-top: 24px;
          margin-inline-start: auto;
          display: flex;
        }
      }
    }
    > [class*="editgrid-row-modal-confirmation"] {
      .formio-dialog-content {
        width: fit-content;

        [ref="dialogHeader"] {
          padding-inline-end: 35px;
          margin-bottom: 24px;
        }
        [ref="dialogCancelButton"] + [ref="dialogYesButton"] {
          margin-inline-start: 10px;
        }
      }
    }
    // ------------------------------------------------------
    // Isolating Panel styles to redefine Block / Sub-block |
    // ------------------------------------------------------

    // Block -----

    @panelInlinePadding: 24px;
    @panelInlinePaddingNegative: 0px - @panelInlinePadding;
    @panelBlockPadding: 24px;
    @panelHeaderBottomBorderWidth: 2px;
    @panelHighlightHeaderBottomBorderWidth: 1px;
    @panelHeadingFontWeight: 600;
    @guidePanelCounterSize: 13px;

    // Root block mixin
    .root-block() {
      margin-bottom: 0px;
      margin-inline: 0px;
      --panel-bg-color: white;
      background-color: var(--panel-bg-color);
      border-radius: @roundedCorners;

      > .card {
        box-shadow: none;
        background-color: transparent;
        border-radius: @roundedCorners;
        margin-bottom: 0;
        border: solid 1px var(--color-gray-200);

        > .card-header {
          padding-top: @panelBlockPadding;
          padding-bottom: @panelBlockPadding;
          padding-inline-start: @panelInlinePadding;
          padding-inline-end: @panelInlinePadding;
          border-bottom: solid @panelHeaderBottomBorderWidth @panelHeadingBottomBorderColor;

          &:has(.formio-collapse-icon) {
            cursor: pointer;
          }
        }
        > .card-body {
          padding-top: @panelBlockPadding;
          padding-bottom: @panelBlockPadding;
          padding-inline-start: @panelInlinePadding;
          padding-inline-end: @panelInlinePadding;
        }
      }
      .card-header {
        &[aria-expanded="false"] {
          border-bottom: none;

          .formio-collapse-icon {
            &::before {
              content: "\F107";
            }
          }
        }
      }
      .card-title {
        font-size: @panelHeadingFontSize;
        font-weight: @panelHeadingFontWeight;
        color: @darkTextColor;
        min-height: 20px;

        &:has(.formio-collapse-text) {
          display: grid;
          grid-template-columns: 1fr auto 13px;
          gap: 8px;
        }
        i {
          &.formio-collapse-icon {
            font-weight: 400;
            margin: 0;
            color: var(--main-text-color);

            &::before {
              content: "\F106";
            }
          }
          &.fa-question-circle {
            position: relative;
            margin-inline-start: 8px;
            display: flex;
          }
        }
      }
      .formio-collapse-text {
        font-size: 16px;
        font-weight: normal;
        margin-inline-end: 24px;
        margin-inline-start: auto;

        @media screen and (max-width: 480px) {
          display: none;
        }
      }
      &.highlight-block {
        > .card {
          --highlight-border-color: @infoNotice;
          outline: solid 1px var(--highlight-border-color);
          box-shadow: 0px 0px 20px @infoNotice20;

          > .card-header {
            border-bottom: solid @panelHighlightHeaderBottomBorderWidth var(--highlight-border-color, @panelHeadingBottomBorderColor);

            > .card-title {
              color: var(--highlight-border-color);
            }
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
      &.background-gray {
        > .card {
          > .card-header {
            border-bottom-color: var(--color-gray-100);
          }
        }
      }
      &:is(.block-header-gray, .block-header-blue, .block-header-green, .block-header-orange, .block-header-red, .block-header-white, .block-header-primary) {
        --header-bgcolor: white;

        > .card {
          > .card-header {
            border-radius: @roundedCorners @roundedCorners 0 0;
            background-color: var(--header-bgcolor);
          }
        }
      }
      &.block-header-gray {
        --header-bgcolor: var(--color-gray-75);
      }
      &.block-header-blue {
        --header-bgcolor: var(--color-blue-50);
      }
      &.block-header-green {
        --header-bgcolor: var(--color-green-50);
      }
      &.block-header-orange {
        --header-bgcolor: var(--color-orange-50);
      }
      &.block-header-red {
        --header-bgcolor: var(--color-red-50);
      }
      &.block-header-white {
        --header-bgcolor: white;
      }
      &.padding-off {
        > .card.border {
          border: none;

          > .card-body {
            padding-bottom: 0px;
            padding-inline: 0px;

            &:only-child {
              padding-top: 0px;
            }
          }
          > .card-header {
            padding-top: 0px;
            padding-inline: 0px;

            &[aria-expanded="false"] {
              padding-bottom: 0px;
            }
          }
        }
      }
      &.mini-block {
        > .card {
          > .card-header {
            padding-top: calc(@panelBlockPadding / 2);
            padding-bottom: calc(@panelBlockPadding / 2);
            padding-inline-start: calc(@panelInlinePadding / 2);
            padding-inline-end: calc(@panelInlinePadding / 2);
            border-bottom: solid @panelHeaderBottomBorderWidth @panelHeadingBottomBorderColor;

            .card-title {
              font-size: calc(@panelHeadingFontSize - 4px);
            }
          }
          > .card-body {
            padding-top: calc(@panelBlockPadding / 2);
            padding-bottom: calc(@panelBlockPadding / 2);
            padding-inline-start: calc(@panelInlinePadding / 2);
            padding-inline-end: calc(@panelInlinePadding / 2);

            .formio-component-panel:not(.root-block, .formio-hidden, .hide) {
              margin-inline: -12px;

              > .card {
                > .card-header {
                  .card-title {
                    font-size: calc(@panelHeadingFontSize - 4px);
                  }
                }
              }
            }
          }
        }
      }
    }

    // Root block cases:
    .formio-component-panel[class*="formio-component-tab"] .formio-component-panel,
    .formio-component-panel {
      .root-block();
    }
    // Custom Blocks
    .formio-component-panel {
      &:where(.formio-component-FeesPanel, .formio-component-requiredDocumentsPanel) {
        .formio-collapse-text {
          display: none;
        }
        .card-title {
          span:not(.title-text, .title-total, .title-total-amount, .currency-suffixed, .currency-prefixed) {
            font-size: @guidePanelCounterSize;
            margin-inline: 0px;
          }
        }
        .title-text {
          margin-inline-end: 6px;
        }
        .title-total {
          display: flex;
          align-items: baseline;
          direction: ltr;

          .currency-suffixed {
            margin-inline-start: 4px;
          }
          .currency-prefixed {
            margin-inline-end: 4px;
          }
        }
        .title-count {
          font-size: 11px;
          min-width: 17px;
          height: 17px;
          padding: 2px;
          background-color: var(--color-gray-400);
          color: white;
          font-weight: 700;
          border-radius: 10px;
          text-align: center;
          margin-inline: 6px;
        }
      }
      &.documents-tab-wrapping-panel {
        > .card {
          > .card-body {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;

            &::after,
            &::before {
              display: none;
            }
          }
        }
        .formio-component-panel {
          margin-top: 0 !important;
          margin-bottom: 0;
          margin-inline: 0;
          min-width: 260px;
          flex-grow: 1;
          flex-basis: calc(50% - 2rem);

          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
          > .card {
            border: none;
          }
          .card-body {
            padding: 0;
          }
        }
      }
      &[class*="formio-component-tab"] {
        background-color: transparent;

        > .card {
          > .card-header {
            border-bottom: none;
            padding-top: 0px;
            padding-bottom: 24px;
            padding-inline-start: 0px;
            padding-inline-end: 0px;
          }
          > .card-body {
            padding-top: 0px;
            padding-bottom: 0px;
            padding-inline-start: 0px;
            padding-inline-end: 0px;
          }
        }
      }
      &.container-block {
        margin-inline: 0px;

        > .card {
          border-top: none !important;

          > .card-header {
            border-bottom: none;
            padding-top: 0;
            padding-bottom: 0;
            padding-inline-start: 0;
            padding-inline-end: 0;

            > .card-title {
              min-height: 0;

              :is(.formio-collapse-text, .formio-collapse-icon) {
                display: none;
              }
            }
          }
          .card-body {
            padding-top: 0;
            padding-bottom: 0;
            padding-inline-start: 0;
            padding-inline-end: 0;
          }
        }
      }
      &.readonly-forms-block,
      &.readonly-forms-block .formio-component-panel,
      &.readonly-forms-block ~ .formio-component-panel {
        .card {
          > .card-body {
            padding-top: 10px;
            padding-inline: 0px;
            padding-bottom: 0px;
          }
        }
      }
      &:is(.review-documents-container, .review-certificates-container) {
        .card-body {
          padding: 0px;
        }
      }
      &.block-border {
        > .card {
          border: solid 1px var(--color-gray-200) !important;
        }
      }
    }
    [role="tabpanel"] > .formio-component-panel,
    [role="tabpanel"] > .formio-component-columns > [class*="col-"] > .formio-component-panel:not(.formio-component-label-hidden) {
      > .card {
        border: solid 1px var(--color-gray-200);
      }
    }
    .formio-component-editgrid .new-entry > .formio-component-panel {
      > .card {
        border: solid 1px var(--color-gray-200);
      }
    }
    .formio-component-editgrid .editing-entry > .formio-component-panel {
      > .card {
        border: solid 1px var(--color-gray-300) !important;
      }
    }

    // Sub-block -----

    @subBlockLineWidth: 4px;

    // Sub block mixin:
    .sub-block() {
      .formio-component-panel:not(.root-block, .formio-hidden, .hide) {
        margin-inline: @panelInlinePaddingNegative;
        border-radius: 0px;

        > .card {
          border-top: solid @subBlockLineWidth @subBlockLineColor;
          border-bottom: solid @subBlockLineWidth @subBlockLineColor;
          border-inline: none;
          border-radius: 0px;

          > .card-header {
            border-bottom: none;

            &[aria-expanded="true"] {
              padding-bottom: 0px;
            }
            :where(.formio-collapse-text, .formio-collapse-icon) {
              font-size: 14px;
            }
          }
        }
        .card-title {
          font-size: @subPanelHeadingFontSize;
          font-weight: @panelHeadingFontWeight;
          line-height: 22px;
        }

        & + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow),
        &.is-adjacent:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) {
          margin-top: 0px;

          > .card {
            border-top: none;
          }
        }
        &:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) {
          &.padding-off {
            > .card.border {
              border: none;

              > .card-body {
                padding: 0px;
              }
            }
          }
        }
      }
      // First and last sub-block
      .formio-component-panel:not(.root-block, .formio-hidden, .hide, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) {
        &:is(:first-child, .is-first) {
          > .card {
            border-top: none;

            > .card-header {
              padding-top: 0px;
            }
            > .card-body:only-child {
              padding-top: 0;
            }
          }
        }
        &:is(:last-child, .is-last) {
          > .card {
            border-bottom: none;

            > .card-body {
              padding-bottom: 0px;
            }
            > .card-header[aria-expanded="false"] {
              padding-bottom: 0;
            }
          }
        }
      }
      .formio-component-columns {
        [class^="col-"] {
          &:first-child {
            > .formio-component-panel {
              border-bottom-left-radius: @roundedCorners;
            }
          }
          > .formio-component-panel {
            &:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) {
              &:first-child {
                margin-top: 0px;
              }
              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
        }
        .formio-component-panel {
          &:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white) {
            margin-inline: 0px;
            margin-bottom: 0px;
            border-radius: @roundedCorners;

            > .card {
              border-top: none;
              border-bottom: none;
            }
          }
        }
      }
    }

    // Sub block cases:
    //.formio-component-tabs, /* Inclusive */
    .formio-component-panel[class*="formio-component-tab"] .formio-component-panel, /* Inclusive */
    //.formio-component-editgrid .new-entry, /* Inclusive */
    .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel,
    .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) /* Exclusive */ {
      .sub-block();

      :is(.formio-hidden, .hide, .formio-component-hidden):first-child { // If the first component is hidden
        & ~ .formio-component-panel:not(.formio-hidden, .hide) { // Re-search the actual first visible block
          border-top-left-radius: @roundedCorners;
          border-top-right-radius: @roundedCorners;

          > .card {
            border-top: none;

            > .card-header {
              padding-top: 0px;
            }
          }
          & ~ .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) { // Restore the adjacent block margin rule
            margin-top: @componentSeparation;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            > .card {
              border-top: solid @subBlockLineWidth @subBlockLineColor;

              > .card-header {
                padding-top: @panelBlockPadding;
              }
            }
            & + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white),
            &.is-adjacent:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) { // Restore the adjacent sub-block margin rule
              margin-top: 0px;
              border-top-left-radius: 0;
              border-top-right-radius: 0;

              > .card {
                border-top: none;
              }
            }
          }
          & + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) { // Adjacent sub-block margin rule
            margin-top: 0px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            > .card {
              border-top: none;
            }
          }
        }
        & ~ .formio-component:not(.formio-component-panel, .formio-hidden, .hide) ~ .formio-component-panel:not(.formio-hidden, .hide) {
          > .card {
            border-top: solid @subBlockLineWidth @subBlockLineColor;

            > .card-header {
              padding-top: @panelBlockPadding;
            }
          }
        }
      }
      .formio-component:not(.formio-hidden, .hide, .formio-component-hidden, .formio-component-panel) + .formio-component-panel:not(.root-block, .background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) {
        > .card {
          border-top: solid @subBlockLineWidth @subBlockLineColor;
        }
      }
      .formio-component-panel:not(.formio-hidden, .hide) {
        &:first-child {
          & ~ :is(.formio-hidden, .hide, .formio-component-hidden) ~ .formio-component-panel { // New first sub-block
            margin-top: 0px;

            > .card {
              border-top: none;
            }
            & ~ .formio-component-panel { // Regular separation
              margin-top: @componentSeparation;
            }
            & + .formio-component-panel:not(.readonly-forms-block) { // Adjacent sub-block separation
              margin-top: 0px;
              border-top-left-radius: 0;
              border-top-right-radius: 0;

              > .card {
                border-top: none;
              }
            }
          }
        }
        & + :is(.formio-hidden, .hide, .formio-component-hidden) {
          & ~ .formio-component-panel { // Adjacent sub-block separation
            margin-top: 0px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            > .card {
              border-top: none;
            }
          }
          // & ~ :not(.formio-component-panel, .formio-hidden, .hide, .formio-component-hidden) + .formio-component-panel {
          //   margin-top: @componentSeparation;
          //   border-top-left-radius: 0;
          //   border-top-right-radius: 0;

          //   > .card {
          //     border-top: solid @subBlockLineWidth @subBlockLineColor;
          //   }
          // }
        }
        & + .formio-component-panel:not(.formio-hidden, .hide, .root-block) {
          margin-top: 0px;

          > .card {
            border-top: none;
          }
        }
      }

      // Custom sub-blocks
      .formio-component-panel {
        &:not(.root-block) {
          &.container-block {
            margin-inline: 0px;
            margin-bottom: 0px;

            > .card {
              border-top: none;
              border-bottom: none;

              > .card-header {
                border-bottom: none;
                padding-top: 0;
                padding-bottom: 0;
                padding-inline-start: 0;
                padding-inline-end: 0;

                > .card-title {
                  min-height: 0;

                  :is(.formio-collapse-text, .formio-collapse-icon) {
                    display: none;
                  }
                }
              }
              .card-body {
                padding-top: 0;
                padding-bottom: 0;
                padding-inline-start: 0;
                padding-inline-end: 0;
              }
            }
          }
          &.review-documents-container {
            margin-inline: 0px;
            margin-top: 0px;
            margin-bottom: 0px;

            > .card {
              > .card-body {
                padding: 0px;
              }
            }
          }
          &:is(.background-red, .background-blue, .background-green, .background-orange, .background-gray, .background-primary, .background-white, .block-border, .block-shadow) {
            margin-inline: 0;
            border-radius: @roundedCorners;

            > .card {
              border-radius: @roundedCorners;
              border-top: none;
              border-bottom: none;
            }
          }
          &.block-border {
            > .card {
              border: solid 1px var(--color-gray-200) !important;
            }
          }
          &.block-shadow {
            box-shadow: 0 2px 40px rgba(0, 0, 0, .08);
          }
        }
        &.formio-component-feesDescription {
          margin-inline: 0px;

          .card-body {
            padding: 0;
          }
        }
      }
      tr + tr {
        .formio-component-panel {
          &:not(.root-block) {
            &.review-documents-container {
              margin-top: @componentSeparation;
            }
          }
        }
      }
      .formio-component-datagrid.formio-component-documentsTabDocuments {
        td {
          > .formio-component-panel {
            &:not(.root-block) {
              margin-inline: 0px;
              margin-top: 0px;
              margin-bottom: 0px;

              > .card {
                > .card-body {
                  padding: 0px;
                }
              }
            }
          }
        }
        tr + tr {
          td {
            > .formio-component-panel {
              &:not(.root-block) {
                margin-top: @componentSeparation;
              }
            }
          }
        }
      }
      // Old Part B Tabs structure
      :is(.formio-component-part-b-documents-tab, .formio-component-part-b-data-tab) {
        & ~ .formio-component-panel,
        & ~ :is(.formio-component-container, .formio-component-panel) .formio-component-panel {
          margin-inline: 0px;
          margin-bottom: 0px !important;

          &:not(.margin-top-zero, .top-space-2x, .top-space-3x, .top-label-space) {
            margin-top: 0;
          }
        }
      }
      // *Experimental (Part B tabs without any identification) Readonly forms
      .formio-component-previewcontainer:has(.readonly-forms-block) {
        .formio-component-panel:not(.root-block, .formio-hidden, .hide) {
          margin-inline: 0px;
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }
    }
    .formio-component-tabs {
      .formio-component-panel:not(.root-block) {
        padding-inline: 0px;
      }
    }
    .formio-component-editgrid {
      :is(.new-entry, .editing-entry) {
        > [class="row"] + .formio-component-panel {
          border-top-left-radius: @roundedCorners;
          border-top-right-radius: @roundedCorners;

          > .card {
            > .card-body {
              &:only-child {
                padding-top: 0px;
              }
              > .formio-component-panel {
                &:first-child {
                  border-top-left-radius: @roundedCorners;
                  border-top-right-radius: @roundedCorners;
                }
              }
            }
          }
        }
      }
    }

    // Blocks overrides
    .formio-component-panel {
      > .card.mb-2 {
        margin-bottom: 0px !important;
      }
    }
    // Root block to override specific sub-block cases
    .formio-component-panel:not([class*="formio-component-tab"], .documents-tab-wrapping-panel) .formio-component-editgrid :is(.new-entry, .editing-entry) > .formio-component-panel {
      .root-block();

      & + .formio-component-panel {
        margin-top: @componentSeparation;
      }
    }

    // ----------------------------------------------
    // Isolating tabs styles to reuse on part b tabs |
    // ----------------------------------------------

    .formio-component-tabs {
      --validation-tab-dot-display: inline-block;

      //// Border
      ////// Style
      --tab-pill-top-border-style: none;
      --tab-pill-right-border-style: none;
      --tab-pill-bottom-border-style: none;
      --tab-pill-left-border-style: none;
      --tab-pill-active-top-border-style: none;
      --tab-pill-active-right-border-style: none;
      --tab-pill-active-bottom-border-style: none;
      --tab-pill-active-left-border-style: none;
      ////// Width
      --tab-pill-top-border-width: 0px;
      --tab-pill-right-border-width: 0px;
      --tab-pill-bottom-border-width: 0px;
      --tab-pill-left-border-width: 0px;
      --tab-pill-active-top-border-width: 0px;
      --tab-pill-active-right-border-width: 0px;
      --tab-pill-active-bottom-border-width: 0px;
      --tab-pill-active-left-border-width: 0px;
      ////// Color
      --tab-pill-top-border-color: transparent;
      --tab-pill-right-border-color: transparent;
      --tab-pill-bottom-border-color: transparent;
      --tab-pill-left-border-color: transparent;
      --tab-pill-active-top-border-color: transparent;
      --tab-pill-active-right-border-color: transparent;
      --tab-pill-active-bottom-border-color: transparent;
      --tab-pill-active-left-border-color: transparent;
      //// BG
      --tab-pill-active-bg: white;
      // Tab body properties
      --tab-body-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
      --tab-body-bg-color: @tabsComponentBgColor;
      --tab-body-border-radius: @roundedCorners;
      //// Padding
      --tab-body-top-padding: @panelBodyPadding;
      --tab-body-right-padding: 24px;
      --tab-body-bottom-padding: @panelBodyPadding;
      --tab-body-left-padding: 24px;

      &.vertical-tabs {
        --tab-layout: row;
        --tab-heading-body-gap: 24px;
        --tab-pills-layout: column;
      }
      &.underline-style {
        --tab-pill-active-shadow: none;
        --tab-body-shadow: none;
        --tab-pill-active-bottom-border-style: solid;
        --tab-pill-active-bottom-border-width: 2px;
        --tab-pill-active-bottom-border-color: var(--color-gray-900);
        --tab-body-border-radius: 0px;
        --tab-body-bg-color: transparent;
        --tab-body-top-padding: 24px;
        --tab-body-right-padding: 0px;
        --tab-body-bottom-padding: 0px;
        --tab-body-left-padding: 0px;

        > .card {
          --tab-pill-active-bg: transparent;
          --tab-pill-font-weight: 400;
        }
      }
      &.tab-style {
        --tab-pill-active-shadow: 0px 16px 20px @black3Color;
        --tab-body-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
        --tab-pill-active-bottom-border-style: none;
        --tab-pill-active-bottom-border-width: 0px;
        --tab-pill-active-bottom-border-color: transparent;
        --tab-body-border-radius: @roundedCorners;
        --tab-body-bg-color: @tabsComponentBgColor;
        --tab-body-top-padding: calc(@panelBodyPadding + @panelBodyPadding / 2);
        --tab-body-right-padding: @panelBodyPadding;
        --tab-body-bottom-padding: @panelBodyPadding;
        --tab-body-left-padding: @panelBodyPadding;

        > .card {
          --tab-pill-font-weight: 600;
        }
      }
      &.affix-tab-header {
        > .card.card-vertical {
          > .card-header {
            display: block;

            .tab-expand-collapse-button {
              float: inline-end;
              height: calc(100% - 9px - var(--x-top));
              margin-top: 9px;
            }
            :is(.tab-expand-collapse-button, .nav-tabs-vertical) {
              top: var(--x-top);
            }
          }
        }
      }
      > .card {
        --tab-layout: column; // column -> top | column-reverse -> bottom | row -> left | row-reverse -> right
        --tab-pills-layout: row; // row -> horizontal | column -> vertical
        --tab-heading-body-gap: 0px;
        // Tab Pills properties
        --tab-pill-font-size: 20px;
        --tab-pill-font-weight: 600;
        --tab-pill-text-color: var(--color-gray-400);
        --tab-pill-bg: transparent;
        --tab-pill-shadow: none;
        --tab-pill-active-text-color: var(--color-gray-900);
        --tab-pill-active-bg: @tabsComponentBgColor;
        --tab-pill-active-shadow: 0px 16px 20px @black3Color;
        --tab-pill-top-left-radius: calc(@roundedCorners + 2px);
        --tab-pill-top-right-radius: calc(@roundedCorners + 2px);
        --tab-pill-bottom-left-radius: 0px;
        --tab-pill-bottom-right-radius: 0px;
        //// Padding
        --tab-pill-top-padding: 22px;
        --tab-pill-right-padding: 24px;
        --tab-pill-bottom-padding: 20px;
        --tab-pill-left-padding: 24px;
        --tab-pill-text-align: center;

        background-color: transparent;
        box-shadow: none;
        border-radius: @roundedCorners !important;
        padding: 0px;
        display: flex;
        flex-direction: var(--tab-layout, column);
        gap: var(--tab-heading-body-gap, 0px);

        &.card-vertical {
          --tab-layout: row;
          --tab-pills-layout: column;
          --tab-heading-body-gap: 24px;
          --tab-pill-top-padding: 10px;
          --tab-pill-right-padding: 16px;
          --tab-pill-bottom-padding: 10px;
          --tab-pill-left-padding: 16px;
          --tab-pill-text-align: start;
          --tab-pill-top-left-radius: 4px;
          --tab-pill-top-right-radius: 4px;
          --tab-pill-bottom-left-radius: 4px;
          --tab-pill-bottom-right-radius: 4px;
          --tab-pill-font-size: 16px;
          --root-font-weight: 600;
          --tab-pill-active-shadow: none;
          --tab-pill-text-color: @darkTextColor;
          --tab-tree-line-color: hsla(216, 12%, 84%, 1);
          --tab-tree-line-width: 1px;
          --tab-tree-line-curve-radius: 6px;

          > .card-header {
            width: 25%;
            min-width: 200px;
            max-width: 300px;
            flex-direction: row-reverse;
            display: flex;
            transition: width 200ms, min-width 200ms;
            margin-bottom: 0;

            &.collapsed {
              width: 26px;
              min-width: 26px;
              contain: size;
              overflow: hidden;

              .nav-tabs {
                transition: opacity 200ms, transform 200ms;
                transform: translateX(-274px);
                opacity: 0;
              }
              .tab-expand-collapse-button {
                --fontawesome-icon-content: "\f054";
                margin-inline-start: 0px;
              }
            }
            .tab-expand-collapse-button {
              appearance: none;
              background: transparent;
              border: none;
              min-width: 26px;
              padding: 0;
              position: relative;
              margin-inline-start: 6px;
              transition: outline 0.15s;
              --toggler-border-color: hsla(214, 14%, 90%, 1);
              --fontawesome-icon-content: "\f053";
              --line-color: hsla(220, 10%, 94%, 1);

              &::before {
                position: absolute;
                content: "";
                width: 2px;
                height: 100%;
                background-color: var(--line-color);
                left: 12px;
                top: 0px;
                transition: all 0.15s;
              }
              &::after {
                font-family: var(--fontawesome-font);
                content: var(--fontawesome-icon-content);
                font-weight: 400;
                width: 26px;
                height: 100%;
                max-height: 26px;
                overflow: hidden;
                display: grid;
                place-content: center;
                border-radius: 20px;
                position: absolute;
                top: 0px;
                left: 0px;
                font-size: 12px;
                background-color: white;
                color: hsla(219, 42%, 11%, 1);
                transition: all 0.15s;
                box-shadow: 0 0 4px rgba(0,0,0,.1), inset 0 0 0 1px var(--toggler-border-color);
              }
              &:hover {
                --toggler-border-color: hsla(219, 42%, 11%, 1);
                --line-color: hsla(220, 10%, 85%, 1);
              }
              & + .nav-tabs {
                max-width: 268px;
              }
            }
            .nav-tabs {
              transition: opacity 200ms, transform 200ms, display 200ms;
              overflow: visible;
              opacity: 1;

              > .nav-item:last-child {
                .nav-item {
                  --list-item-bottom-margin: 0px;
                }
              }
            }
            a.nav-link {
              --sub-tab-pill-active-bg: transparent;
              --list-item-bottom-margin: 2px;
              --tab-pill-font-weight: 400;
              white-space: initial !important;
              line-height: 1.5 !important;
              display: flex;
              align-items: center;
              gap: 10px;
              border-radius: @roundedCorners !important;

              &:last-child {
                --sub-tab-pill-active-bg: transparent;
              }
              &:hover {
                --hover-shadow: none;
                border-radius: 0;
                --sub-tab-pill-active-bg: var(--color-gray-75);
                --tab-pill-bg: var(--color-gray-75);
              }
              &.active:last-child {
                --tab-pill-active-text-color: @primaryColor;
                --sub-tab-pill-active-bg: var(--color-primary-50);
                border-radius: 0px @roundedCorners @roundedCorners 0px !important;
                box-shadow: inset 3px 0px var(--wizard-page-link-active-bg-color, #222);
              }
            }
            .nav-sub-tabs {
              padding-left: var(--tab-pill-left-padding);

              .nav-item {
                border-left: solid var(--custom-border-width, var(--tab-tree-line-width)) var(--custom-border-color, var(--tab-tree-line-color));
                padding-left: var(--tab-pill-left-padding);
                display: flow-root;

                > .nav-link {
                  &::before {
                    content: " ";
                    width: 12px;
                    height: 50%;
                    background-color: transparent;
                    position: absolute;
                    top: 0;
                    left: calc(0px - var(--tab-tree-line-width) - var(--tab-pill-left-padding));
                    border-bottom-left-radius: var(--tab-tree-line-curve-radius, 6px);
                    border-left: solid var(--tab-tree-line-width) var(--tab-tree-line-color);
                    border-bottom: solid var(--tab-tree-line-width) var(--tab-tree-line-color);
                  }
                }
                &:last-child {
                  border-left-color: transparent;
                }
              }
            }
            .item-group {
              --icon-code: "\f077";

              > .nav-link {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;
                cursor: pointer;
                position: relative;

                &::after {
                  width: 14px;
                  -webkit-font-smoothing: antialiased;
                  display: inline-block;
                  font-style: normal;
                  font-variant: normal;
                  text-rendering: auto;
                  line-height: 1.5;
                  font-family: "Font Awesome 5 Pro";
                  font-weight: 400;
                  content: var(--icon-code);
                  align-self: flex-start;
                  transform: translateY(1px);
                }
                &:has(i) {
                  justify-content: flex-start;

                  &::after {
                    margin-inline-start: auto;
                  }
                }
                &.icon-position-top {
                  &::after {
                    position: absolute;
                    inset-inline-end: 16px;
                    top: calc(50% - 6px);
                  }
                }
              }
              &.collapsed {
                --icon-code: "\f078";
              }
            }
            & + .card-body {
              &.tab-pane {
                &.active {
                  border-top-left-radius: var(--tab-body-border-radius);
                }
              }
            }
          }
          > .card-body.tab-pane {
            padding: 0px !important;
            background-color: transparent;

            > .formio-component-panel {
              > .card {
                > .card-header {
                  > .card-title {
                    --custom-panel-title-size: @panelHeadingFontSize;
                  }
                }
              }
            }
          }
        }
        &::after,
        &::before {
          display: none;
        }
        > .card-header {
          height: auto;
          margin-bottom: -1px;
          z-index: 99;
          position: relative;

          .card-header-tabs {
            background-color: transparent;
            height: auto;
            max-width: 100%;
            min-width: 100px;
            overflow-y: auto;
            flex-direction: var(--tab-pills-layout, row);

            .nav-item {
              flex: 0;
              height: auto;
              margin-bottom: 0px;

              &::before {
                display: none;
              }
            }
            .nav-link {
              font-size: var(--tab-pill-font-size, 20px);
              font-weight: var(--tab-pill-font-weight, 600);
              color: var(--tab-pill-text-color, #AAA) !important;
              background-color: var(--tab-pill-bg, #FFF);
              padding-top: var(--tab-pill-top-padding, 16px);
              padding-inline-end: var(--tab-pill-right-padding, 16px);
              padding-bottom: var(--tab-pill-bottom-padding, 16px);
              padding-inline-start: var(--nested-left-padding, var(--tab-pill-left-padding, 16px));
              border-top-left-radius: var(--tab-pill-top-left-radius, 0px); // (2px the border width) it creates the illusion of smaller corner
              border-top-right-radius: var(--tab-pill-top-right-radius, 0px);
              border-bottom-left-radius: var(--tab-pill-bottom-left-radius, 0px);
              border-bottom-right-radius: var(--tab-pill-bottom-right-radius, 0px);
              line-height: 1;
              text-decoration: none !important;
              white-space: nowrap;
              text-transform: none;
              text-align: var(--tab-pill-text-align, center);
              box-shadow: none;
              border-style: none;
              border-width: 0px;
              border-color: transparent;
              margin-bottom: var(--list-item-bottom-margin, 0px);
              margin-inline: 0px;

              &.active {
                font-size: var(--tab-pill-font-size, 20px);
                color: var(--tab-pill-active-text-color, black) !important;
                background-color: var(--sub-tab-pill-active-bg, var(--tab-pill-active-bg, #FFF));
                border-style: none;
                border-width: 0px;
                border-color: transparent;
                box-shadow: none;
              }
              &:hover {
                box-shadow: var(--hover-shadow, inset 0 -2px transparent);
              }
              &.tab-error {
                display: flex;
                align-items: center;
                gap: 6px;

                &::after {
                  content: ' ';
                  display: var(--validation-tab-dot-display);
                  height: 6px;
                  min-width: 6px;
                  background: @errorColor70;
                  border-radius: 6px;
                }
              }
              &:is(.icon-position-left, .icon-position-right, .icon-position-top) {
                display: flex;
                gap: 10px;
              }
              &.icon-position-right {
                flex-direction: row-reverse;
              }
              &.icon-position-top {
                flex-direction: column;
              }
            }
            .nav-link-vertical {
              font-weight: var(--root-font-weight, 600);

              &.icon-position-right {
                &.nav-link-vertical::after {
                  order: -1;
                  margin-inline-start: auto;
                }
              }
            }
            &::-webkit-scrollbar {
              width: 6px;
              height: 6px;
            }
            &::-webkit-scrollbar-thumb {
              border-radius: 4px;
              background-color: @accentColor40;
            }
            &::-webkit-scrollbar-track {
              background: @black5Color;
            }
            &:hover {
              &::-webkit-scrollbar-thumb {
                background-color: @accentColor70;
              }
            }
            &.nav-tabs-vertical {
              margin-inline-end: 0px;
            }
          }
          & + .card-body {
            &.tab-pane {
              &.active {
                border-top-left-radius: 0px;
              }
            }
          }
        }
        > .card-body {
          &.tab-pane {
            border-style: none;
            border-width: 0px;
            border-color: transparent;
            background-color: var(--tab-body-bg-color, transparent);
            box-shadow: none;
            border-radius: var(--tab-body-border-radius, 0px);
            padding: var(--tab-body-top-padding, 24px) var(--tab-body-right-padding, 24px) var(--tab-body-bottom-padding, 24px) var(--tab-body-left-padding, 24px) !important;
            margin-top: 0;
            flex-direction: column;
            min-width: 320px;

            &.active {
              display: flex !important;
            }
            &::before,
            &::after {
              display: none;
            }
          }
        }
      }
    }

    // Custom rules
    :where(.formio-component-tabs, .formio-component-panel) {
      .formio-component-tabs {
        > .card {
          > .card-header .card-header-tabs {
            .nav-link {
              border-style: solid solid none;
              border-width: 1px;
              border-color: transparent;

              &.active {
                border-style: solid solid none;
                border-width: 1px;
                border-color: var(--color-gray-200);
              }
            }
          }
          > .card-body.tab-pane {
            border-style: solid;
            border-width: 1px;
            border-color: var(--color-gray-200);
          }
        }
      }
    }
    #formio-form-wizard {
      .formio-component {
        &.formio-component-tabs {
          --validation-tab-dot-display: none;
        }
      }
    }
    .new-formio-part-b {
      .formio-form {
        .formio-component-tabs {
          > .card {
            > .card-header {
              margin-block: 30px;

              .card-header-tabs {
                gap: var(--wizard-bar-items-gap, 0px);

                &::after,
                &::before {
                  display: none;
                }
                .nav-link {
                  color: var(--wizard-page-link-text-color, #000) !important;
                  background-color: var(--wizard-page-link-bg-color, #CCC);
                  font-size: var(--wizard-page-link-text-size, 24px);
                  font-weight: var(--wizard-page-link-text-weight, 500);
                  padding: var(--wizard-page-link-block-padding, 8px) var(--wizard-page-link-inline-padding, 28px);
                  border-radius: var(--wizard-page-link-border-radius);

                  &:is(.active, :hover) {
                    background-color: var(--wizard-page-link-active-bg-color, #CCC);
                    color: var(--wizard-page-link-active-text-color, #000) !important;
                  }
                }
              }
            }
            > .card-body.tab-pane {
              &:nth-child(2).active {
                border-radius: 6px !important;
              }
            }
          }
          .readonly-forms-components-container {
            .formio-component-panel {
              background-color: transparent;
            }
          }
          .part-b-document-tab {
            .formio-component-panel:has(.validation-headers) {
              background-color: transparent;
            }
            .validation-headers {
              .row {
                margin: 0px;
              }
              .col-sm-1 {
                padding: 0px;
              }
            }
          }
        }
      }
    }

    // Editgrid and datagrid modals
    .formio-component-modal-wrapper-editgrid,
    .formio-component-modal-wrapper-datagrid {
      .formio-dialog-theme-default {
        padding-bottom: 15px;
        padding-top: 0px;
        display: flex;

        .formio-dialog-content {
          width: calc(100% - 130px);
          //Formio override
          font-family: var(--main-font-family);
          background-color: white;
          padding: 24px;
          align-self: center;
          max-height: 80vh;

          .formio-component-datagrid,
          .formio-component-editgrid {
            margin-bottom: 24px;

            > table {
              margin-top: 15px;
            }
          }
          .btn {
            &.btn-success {
              &.formio-dialog-button {
                border-radius: @roundedCorners !important;
                background-color: @buttonAccentColor;
                padding-inline-start: 25px;
                padding-inline-end: 25px;
                font-weight: 500;
                text-transform: none;
                position: relative;
                z-index: 1;
                padding-top: 12px;
                padding-bottom: 12px;
                line-height: 1;
                color: @whiteColor;
                text-align: center;
                font-size: 16px;
                gap: 8px;
                &:hover {
                  box-shadow: 0 9px 20px @accentColor40 !important;
                  opacity: 0.9;
                }
              }
            }
          }
          .formio-dialog-close {
            padding: 0 !important;
            box-shadow: none !important;

            &:hover {
              background-color: var(--color-gray-100);
              color: var(--color-black) !important;

              &::before {
                color: inherit !important;
              }
            }
          }
          > .sr-only {
            width: 0px !important;
          }
        }
      }
    }
    .formio-component-modal-wrapper-editgrid {
      margin-bottom: 0px;

      .formio-dialog-content {
        padding: 56px 24px 16px !important;
      }
    }
    // Editgrid Pagination
    .editgrid-pagination {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      ul {
        list-style: none;
        padding: 0;
        display: flex;
        margin: 0;
        margin-inline-end: 10px;
        height: 36px;
        align-items: center;
      }
      .pager-button {
        margin-inline-end: 3px;
        border-radius: @roundedCorners;
        width: 36px;
        justify-content: center;

        &:last-child {
          margin-inline-end: 0px;
        }
        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
      li {
        padding: 0 10px;
        display: flex;
        height: 100%;
        align-items: center;
        cursor: pointer;
        background-color: #F5F6F9;
        font-size: 12px;
        font-weight: 600;
        color: @darkTextColor;

        &:hover {
          background-color: #E5E9EF;
        }
      }
      .current-page {
        background-color: #E5E9EF;
        font-weight: 900;
      }
      .first-page {
        border-radius: @roundedCorners 0 0 @roundedCorners;
      }
      .last-page {
        border-radius: 0 @roundedCorners @roundedCorners 0;
        margin-inline-end: 3px;
      }
      .results-selector {
        display: flex;
        align-items: center;
        background-color: #f5f6f9;
        padding-inline-start: 14px;
        border-radius: @roundedCorners;
        color: @darkTextColor;
        font-size: 12px;
        font-weight: 600;

        .dropdown-toggle {
          padding-inline-start: 10px;
          padding-inline-end: 10px;
          border-radius: 0 @roundedCorners @roundedCorners 0;
          background-color: transparent;
          color: @darkTextColor;
          font-size: 12px;
          font-weight: 900;

          &::after {
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            font-family: "Font Awesome 5 Pro";
            font-weight: 400;
            content: "\f078";
            margin-inline-start: 10px;
          }
          &:hover {
            background-color: #E5E9EF;
          }
        }
        .dropdown.open {
          .dropdown-toggle {
            border-radius: 0 @roundedCorners 3px 0;
            background-color: #E5E9EF;
          }
          .dropdown-menu {
            border-radius: 3px 3px @roundedCorners @roundedCorners;
          }
        }
        .dropdown-menu {
          min-width: 40px;
          padding: 0;
          width: 100%;
          background-color: #f5f6f9;
          box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05);
          border: none;

          .dropdown-item {
            display: block;
            padding: 0 10px;
            min-height: 28px;
            text-decoration: none !important;
            color: @darkTextColor;

            &:hover {
              background-color: #E5E9EF;
            }
            &:first-child {
              border-radius: @roundedCorners @roundedCorners 0 0;
            }
            &:last-child {
              border-radius: 0 0 @roundedCorners @roundedCorners;
            }
          }
        }
      }
    }
    /* Business entity modal */
    #select-business-entity {
      background-color: @black5Color;

      .modal-dialog {
        .modal-content {
          box-shadow: 0px 0px 20px @black10Color;
        }
        .panel-heading {
          border-radius: @roundedCorners @roundedCorners 0 0;
          border-bottom: solid 2px #F3F4F5;
          padding: 16px 23px;

          .panel-title {
            font-size: @miniPanelHeadingFontSize;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .close {
              font-size: 28px;
              font-weight: 400;
              opacity: 0.7;
              line-height: 16px;
              display: flex;

              &:hover {
                opacity: 1;
              }
            }
          }
        }
        .panel {
          border-radius: 0 0 @roundedCorners @roundedCorners;
          background-color: @whiteColor;
          box-shadow: none;

          .panel-body {
            color: @darkTextColor;
            font-size: 16px;
            padding: 16px 23px;

            .radio {
              label {
                margin-top: 0;

                &::before {
                  display: none;
                }
                input {
                  &[type="radio"] {
                    appearance: inherit;

                    &::before {
                      -webkit-font-smoothing: antialiased;
                      display: inline-block;
                      font-style: normal;
                      font-variant: normal;
                      text-rendering: auto;
                      line-height: 1;
                      font-family: "Font Awesome 5 Pro";
                      font-weight: 400;
                      font-size: @defaultTextSize;
                      content: "\f111";
                      color: @accentColor50;
                    }
                    & + span {
                      font-size: @defaultTextSize;
                      font-weight: 600;
                      color: @accentColor50;
                      margin-inline-start: 3px;
                      position: relative;
                      top: 1px;
                      line-height: 14px;
                    }
                    &:checked {
                      &::before {
                        content: "\f192";
                        font-weight: 900;
                        color: var(--color-primary-600);
                      }
                      & + span {
                        color: var(--color-primary-600);
                      }
                    }
                    &:focus {
                      outline: none;
                    }
                  }
                }
                &:hover {
                  input {
                    &[type="radio"] {
                      appearance: inherit;

                      &::before {
                        color: var(--color-primary-600);
                      }
                      & + span {
                        color: var(--color-primary-600);
                      }
                    }
                  }
                }
              }
            }
            input.form-control {
              height: var(--field-height);
              border: solid 1px @borderColor;
              border-radius: @roundedCorners !important;
              font-weight: 600;
              box-shadow: none;

              &:where(:hover, :focus) {
                outline: none !important;
              }
            }
          }
          .panel-footer {
            background-color: transparent;
            border: none;
            padding: 0 23px 16px;

            a {
              border-radius: @roundedCorners !important;
              background-color: var(--color-primary-600);
              padding-inline-start: 25px;
              padding-inline-end: 25px;
              font-weight: 600;
              text-transform: none;
              position: relative;
              z-index: 1;
              padding-top: 12px;
              padding-bottom: 12px;
              line-height: 1;
              color: white;
              text-align: center;
              font-size: 16px;
              border: none;
            }
          }
        }
      }
    }
    /* Readonly forms components */
    @readonlyBlockTopMargin: 35px;
    :is(.readonly-forms-block, .readonly-forms-block ~ .formio-component-panel, .formio-component-previewcontainer ~ .formio-component-panel, .formio-component-previewcontainer .formio-component-panel) {
      .card {
        background-color: transparent;
        border: none !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        box-shadow: none;

        > .card-header {
          margin: 0 !important;
          padding: 0 !important;
          background-color: transparent !important;
          border-bottom: none !important;

          > .card-title {
            font-size: 18px !important;
            font-weight: 600;
            color: @darkTextColor;
          }
        }
        > .card-body {
          padding-top: 10px;
          padding-bottom: 0px;
          padding-inline: 0px;

          .formio-component-panel + .formio-component-panel {
            margin-top: @readonlyBlockTopMargin;
          }
        }
      }
      & ~ .readonly-forms-block {
        margin-top: @readonlyBlockTopMargin;
      }
      .formio-component-panel {
        margin-inline: 0px !important;
      }
    }
    .formio-component-part-b-data-tab {
      & + .formio-component-previewcontainer {
        margin-top: 0px;
      }
      & + .formio-component-button[class*="print"] + .formio-component-previewcontainer {
        margin-top: -26px !important;
      }
    }
    .row {
      &.formio-component {
        &.formio-component-columns {
          &.readonly-form-columns {
            align-items: center;
            margin-top: 0;
            border: solid 1px var(--color-gray-200);
            min-height: 39px;

            a[ref="fileLink"] {
              color: var(--main-text-color);
              border-bottom: solid 1px;
            }
            &:focus-within {
              z-index: 99;
            }
            &:nth-child(odd) {
              background-color: var(--color-gray-75);
            }
            &:last-child {
              border-bottom: solid 1px #e3e3e6 !important;
            }
            & + .formio-component {
              &.formio-component-columns {
                border-top: none;

                &.readonly-form-columns {
                  margin-top: 0px !important; //NOTE to override adjacent formio component space rule
                }
              }
            }
            &.formio-hidden {
              & + .formio-component {
                &.formio-component-columns {
                  border-top: solid 1px #e3e3e6;
                }
              }
            }
            &:not(.formio-hidden) {
              & + .formio-hidden {
                & + .formio-component {
                  &.formio-component-columns {
                    border-top: none;
                  }
                }
              }
            }
            > div {
              height: 100%;
              padding: 6px 12px;
              min-height: 39px;
              display: flex;
              align-items: center;

              &.col-md-2 {
                &:first-of-type {
                  border-inline-end: solid 1px #e3e3e6;
                  flex: 0 0 25%;
                  height: auto;
                  align-self: stretch;

                  & + div {
                    margin-inline-start: -1px;

                    .formio-component-htmlelement {
                      p, h1, h2, h3, h4, h5, h6 {
                        font-size: 15px;
                        font-weight: 400;
                        color: #404451;

                        &:first-child {
                          &:empty {
                            & + :is(blockquote, p) {
                              margin: 0;
                            }
                          }
                        }
                        &:last-of-type {
                          margin-bottom: 0;
                        }
                      }
                      blockquote {
                        margin-inline-start: 0;
                        margin-inline-end: 0;
                        background: transparent !important;
                        padding: 0 !important;

                        &::after {
                          display: none !important;
                        }
                      }
                      &.notice {
                        padding: 0 !important;
                        background: transparent !important;
                        border: none;
                      }
                    }
                    &.col-md-10 {
                      &:nth-last-child(3) {
                        flex: auto;
                        margin-inline-end: 46px;

                        & + .col-md-2 {
                          &:nth-last-child(2) {
                            flex: 0;
                            order: 3;
                            margin-inline-start: auto;

                            > .formio-component-columns {
                              flex-direction: row-reverse;
                              gap: 12px;

                              &::before,
                              &::after {
                                display: none;
                              }
                            }
                          }
                        }
                      }
                    }
                    & + .col-md-2 {
                      min-width: fit-content;

                      .formio-component-columns {
                        align-items: center;

                        .formio-errors {
                          display: none;
                        }
                        > div {
                          &:first-child {
                            flex: 0;
                            padding: 0;
                            min-width: fit-content;

                            & + div {
                              padding: 0;
                              flex: auto;
                              position: absolute;
                              width: fit-content;
                              inset-inline-end: calc(100% + 14px);
                              isolation: isolate;
                              z-index: 9;
                              min-height: 0px;

                              &:focus-within {
                                width: clamp(320px, 30vw, 30vw);
                              }
                              [class*="_validation_reason"] {
                                > div {
                                  display: flex;
                                  justify-content: flex-end;

                                  textarea {
                                    margin: 0 !important;
                                    resize: vertical;
                                    width: 24px;
                                    height: 24px !important;
                                    min-height: 24px;
                                    transition-duration: 0.15s;

                                    &:focus {
                                      width: 100%;
                                      height: auto !important;
                                    }
                                  }
                                }
                                &:not(.formio-hidden) {
                                  > div[ref="element"] {
                                    padding: 10px;
                                    border-radius: @roundedCorners;

                                    &::after {
                                      content: "\f4a6";
                                      position: absolute;
                                      z-index: 99;
                                      width: 24px;
                                      height: 24px;
                                      border-radius: 12px;
                                      display: grid;
                                      place-items: center;
                                      cursor: pointer;
                                      outline: solid 4px white;
                                      font-family: var(--fontawesome-font);
                                      font-weight: 400;
                                      -moz-osx-font-smoothing: grayscale;
                                      -webkit-font-smoothing: antialiased;
                                      font-style: normal;
                                      font-variant: normal;
                                      text-rendering: auto;
                                      line-height: 1;
                                      font-size: 18px;
                                      background: white;
                                      padding: 2px;
                                      color: #d56d72;
                                      transition-duration: 0.15s;
                                      transition-property: color, background, outline;
                                    }
                                    &::before {
                                      content: "";
                                      position: absolute;
                                      width: 14px;
                                      height: 14px;
                                      border-radius: 3px;
                                      transform: rotate(45deg);
                                      inset-inline-end: -6px;
                                      z-index: -1;
                                      display: none;
                                      background: white;
                                      top: calc(50% - 7px);
                                      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.4);
                                    }
                                    &:focus-within {
                                      background: white;
                                      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);

                                      &::after {
                                        z-index: -1;
                                      }
                                      &::before {
                                        display: block;
                                      }
                                    }
                                    &:hover {
                                      &::after {
                                        color: #ffffff;
                                        background: #a3a3a3;
                                        outline: solid 4px #a3a3a3;
                                      }
                                    }
                                  }
                                }
                                &.required {
                                  label.field-required {
                                    position: absolute;
                                    inset-inline-end: 6px;
                                    width: 6px !important;
                                    margin: 0;
                                    height: 6px;
                                    background: @errorColor;
                                    border-radius: 6px;

                                    &::after {
                                      display: none;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                    .formio-component-datagrid {
                      margin-top: 0;

                      [class*="_validation_reason"] {
                        &.required {
                          label.field-required {
                            position: absolute;
                            inset-inline-end: 6px;
                            width: 6px !important;
                            margin: 0;
                            height: 6px;
                            background: @errorColor;
                            border-radius: 6px;

                            &::after {
                              display: none;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              &.col-md-10 {
                &:first-of-type {
                  &:nth-last-of-type(3) {
                    flex: auto;
                    margin-inline-end: 46px;

                    & + .col-md-6 {
                      flex: 0;
                    }
                  }
                  & + .col-md-2:nth-last-of-type(2) {
                    flex: 0;

                    .hide-validation {
                      flex-direction: row-reverse;
                      align-items: center;

                      &::before {
                        display: none;
                      }
                      .formio-errors {
                        display: none;
                      }
                      .col-md-2 {
                        &:first-child {
                          flex: 1;
                          padding-inline-end: 0;
                        }
                      }
                      .col-md-2:nth-last-of-type(2) {
                        padding: 0;
                        flex: auto;
                        position: absolute;
                        width: fit-content;
                        inset-inline-end: calc(100% + 14px);
                        isolation: isolate;
                        z-index: 9;
                        min-height: 0px;

                        &:focus-within {
                          width: clamp(320px, 30vw, 30vw);
                        }
                        [class*="_validation_reason"] {
                          > div {
                            display: flex;
                            justify-content: flex-end;

                            textarea {
                              margin: 0 !important;
                              resize: vertical;
                              width: 24px;
                              height: 24px !important;
                              min-height: 24px;
                              transition-duration: 0.15s;
                              resize: none;

                              &:focus {
                                width: 100%;
                                height: auto !important;
                              }
                            }
                          }
                          &:not(.formio-hidden) {
                            > div[ref="element"] {
                              padding: 10px;
                              border-radius: @roundedCorners;

                              &::after {
                                content: "\f4a6";
                                position: absolute;
                                z-index: 99;
                                width: 24px;
                                height: 24px;
                                border-radius: 12px;
                                display: grid;
                                place-items: center;
                                cursor: pointer;
                                outline: solid 4px white;
                                font-family: var(--fontawesome-font);
                                font-weight: 400;
                                -moz-osx-font-smoothing: grayscale;
                                -webkit-font-smoothing: antialiased;
                                font-style: normal;
                                font-variant: normal;
                                text-rendering: auto;
                                line-height: 1;
                                font-size: 18px;
                                background: white;
                                padding: 2px;
                                color: #d56d72;
                                transition-duration: 0.15s;
                                transition-property: color, background, outline;
                              }
                              &::before {
                                content: "";
                                position: absolute;
                                width: 14px;
                                height: 14px;
                                border-radius: 3px;
                                transform: rotate(45deg);
                                inset-inline-end: -6px;
                                z-index: -1;
                                display: none;
                                background: white;
                                top: calc(50% - 7px);
                                box-shadow: 0 4px 16px rgba(0, 0, 0, 0.4);
                              }
                              &:focus-within {
                                background: white;
                                box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);

                                &::after {
                                  z-index: -1;
                                }
                                &::before {
                                  display: block;
                                }
                              }
                              &:hover {
                                &::after {
                                  color: #ffffff;
                                  background: #a3a3a3;
                                  outline: solid 4px #a3a3a3;
                                }
                              }
                            }
                          }
                          &.required {
                            label.field-required {
                              position: absolute;
                              inset-inline-end: 6px;
                              width: 6px !important;
                              margin: 0;
                              height: 6px;
                              background: @errorColor;
                              border-radius: 6px;

                              &::after {
                                display: none;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              &.formio-errors {
                display: none;
              }
              &.col-md-12 {
                flex: 0 0 100%;
              }
            }
            input {
              background: transparent !important;
              border: none;
              font-size: 15px !important;
              color: @darkTextColor;
              -webkit-text-fill-color: @darkTextColor;
              padding-top: 0;
              padding-bottom: 0;
              padding-inline-start: 0;
              height: 27px;

              &:hover {
                outline: none;
              }
              &:placeholder-shown {
                display: none;
              }
            }
            :where(.formio-component, .form-group) {
              width: 100%;

              &.formio-component-htmlelement {
                p {
                  font-size: 14px;
                  font-weight: 600;
                  color: @darkTextColor;
                  word-break: break-word;
                }
              }
              &.formio-component-radio {
                .form-radio {
                  margin-top: 0;
                  padding-top: 0 !important;

                  :where(.form-check, .form-check-inline) {
                    display: none;
                    padding-bottom: 0 !important;

                    &.radio-selected {
                      display: block;
                      margin: 0 !important;

                      label {
                        &.form-check-label {
                          margin-top: 0;
                          padding-inline-start: 0;
                          font-size: 15px;
                          cursor: text;

                          input {
                            &.form-check-input {
                              display: none;
                            }
                          }
                          span {
                            margin-inline-start: 0 !important;
                          }
                        }
                      }
                    }
                  }
                }
                .form-text.text-muted {
                  display: none;
                }
                &.radio-switch {
                  .form-radio {
                    border: none !important;
                    height: auto !important;

                    :where(.form-check, .form-check-inline) {
                      display: none !important;
                      padding-bottom: 0 !important;

                      &.radio-selected {
                        display: block !important;
                        margin: 0 !important;

                        label {
                          &.form-check-label {
                            display: inline-block !important;
                            margin-top: 0 !important;
                            padding-inline-start: 0 !important;
                            font-size: 15px !important;

                            input {
                              &.form-check-input {
                                display: none;
                              }
                            }
                            span {
                              margin-inline-start: 0 !important;
                              display: block !important;
                              height: auto !important;
                              background: none !important;
                              color: @darkTextColor !important;
                              padding: 0 !important;
                              border: none !important;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              &.formio-component-datetime {
                input.form-control {
                  font-weight: 400;
                }
                .input-group {
                  display: flex;
                  align-items: center;

                  .input-group-append {
                    display: none;
                  }
                }
                .form-text.text-muted {
                  display: none;
                }
              }
              &.formio-component-textarea {
                .card {
                  &.card-body {
                    padding: 0;
                    box-shadow: none;
                    background: transparent;
                    margin: 0;

                    .formio-editor-read-only-content {
                      font-size: 15px;
                      font-weight: 400;
                      color: #404451;
                      white-space: initial;
                      text-align: start;
                    }
                    &.mb-3 {
                      margin-bottom: 0 !important;
                    }
                  }
                }
                .form-text.text-muted {
                  display: none;
                }
                &[class*="_validation_reason"] {
                  height: auto;
                  padding: 0;
                  background: transparent;
                  border: none !important;

                  textarea {
                    &.form-control {
                      border: solid 1px rgba(64, 68, 81, 0.16);
                      outline: none;
                      border-radius: 6px !important;

                      &:where(:focus, :active) {
                        outline: none;
                        box-shadow: none;
                      }
                    }
                  }
                  &:hover {
                    outline: none;
                  }
                }
              }
              &.formio-component-select {
                .formio-choices {
                  .form-control {
                    &.dropdown {
                      background: transparent;
                      border: none;
                      outline: none;
                      height: 100%;
                      min-height: 26px;
                      line-height: 1;
                      display: flex;
                      align-items: center;
                      padding: 0;

                      .choices__list {
                        .choices__item {
                          span {
                            white-space: break-spaces;
                            font-size: 15px !important;
                            font-weight: 400;
                            color: #404451;
                          }
                          &.choices__item--selectable {
                            padding-inline-end: 0;
                            min-height: 30px;
                            display: flex;
                            align-items: center;
                            cursor: text;
                          }
                        }
                      }
                    }
                  }
                }
                .formio-select-autocomplete-input {
                  display: none;
                }
                select {
                  border: none;
                  background: transparent;
                }
                .form-text.text-muted {
                  display: none;
                }
                &.formio-component-multiple {
                  .form-control.selection {
                    .choices__list--multiple {
                      flex: auto;
                      display: flex;
                      align-items: flex-start;
                      flex-direction: column;
                      justify-content: flex-start;

                      .choices__item {
                        margin: 0;
                        padding: 0;
                        background: transparent;
                        border: none;
                        min-height: 24px !important;

                        &::before {
                          content: " ";
                          width: 6px;
                          height: 2px;
                          border-radius: 6px;
                          background: #404451;
                          margin-inline-end: 6px;
                        }
                        &[data-value="[]"] {
                          display: none;
                        }
                      }
                    }
                    .choices__input {
                      flex: 0;
                    }
                  }
                }
              }
              &.formio-component-datagrid {
                > table {
                  &.table {
                    &.datagrid-table {
                      > tbody {
                        > tr {
                          > td {
                            border: none;
                            padding: 0 !important;

                            .formio-component-table {
                              padding: 0 !important;

                              > table {
                                &.table {
                                  > tbody {
                                    > tr {
                                      align-items: center;
                                      margin: 0;
                                      grid-auto-columns: 25% auto min-content;
                                      grid-auto-flow: column;
                                      position: relative;

                                      &:not(.formio-hidden) {
                                        display: grid;
                                      }
                                      > td {
                                        display: flex;
                                        align-items: center;
                                        border: none;
                                        border-inline-end: solid 1px #e3e3e6 !important;
                                        border-bottom: solid 1px #e3e3e6 !important;
                                        padding: 0 1.2rem !important;
                                        align-self: stretch;

                                        > .formio-component:not(.formio-hidden) {
                                          min-height: 39px;
                                          place-items: center;
                                        }
                                        &:first-child {
                                          flex: 0 0 25%;
                                          height: auto;
                                          align-self: stretch;

                                          & + td {
                                            padding: 0 !important;

                                            &:nth-last-child(3) {
                                              min-width: initial;
                                              max-width: initial;
                                              border-inline-end: none !important;
                                              padding-inline-end: 36px !important;

                                              > .formio-component:not(.formio-hidden) {
                                                display: flex;
                                              }
                                            }
                                            & + td {
                                              &:nth-last-child(2) {
                                                order: 3;
                                                width: fit-content;
                                                margin-inline-start: auto;

                                                > .formio-component-checkbox {
                                                  justify-content: flex-end;
                                                }
                                              }
                                            }
                                            .formio-component-htmlelement {
                                              p {
                                                font-size: 15px;
                                                font-weight: 500;
                                                color: #404451;
                                              }
                                            }
                                            > .formio-component:not(.formio-component-datagrid) {
                                              margin-inline-start: 1.2rem;
                                            }
                                            &:last-child {
                                              > .formio-component {
                                                &:not(.formio-hidden) {
                                                  display: flex;
                                                  padding-top: 5px;
                                                  padding-bottom: 5px;
                                                }
                                                &:where(.formio-component-radio, .formio-component-selectboxes) {
                                                  .form-radio {
                                                    margin-top: 0;

                                                    .form-check:first-child {
                                                      margin-top: 0;
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                          }
                                          > .formio-component:not(.formio-hidden) {
                                            display: flex;
                                          }
                                        }
                                        &:nth-of-type(4) {
                                          padding: 0 !important;
                                          min-width: 0px;
                                          flex: auto;
                                          position: absolute;
                                          width: fit-content;
                                          inset-inline-end: 80px;
                                          isolation: isolate;
                                          z-index: 9;
                                          min-height: 0px;
                                          border: none !important;
                                          transform: translateY(-50%);
                                          top: 50%;

                                          [class*="_validation_reason"] {
                                            &:not(:empty) {
                                              margin: 0;

                                              textarea {
                                                margin: 0 !important;
                                                resize: vertical;
                                                width: 24px;
                                                height: 24px !important;
                                                transition-duration: 0.15s;
                                                transform: scale(0);

                                                &:focus {
                                                  width: 100%;
                                                  height: auto !important;
                                                  transform: scale(1);
                                                }
                                              }
                                            }
                                            &:not(.formio-hidden) {
                                              > div[ref="element"] {
                                                padding: 10px;
                                                border-radius: @roundedCorners;
                                                position: relative;

                                                &::after {
                                                  content: "\f4a6";
                                                  position: absolute;
                                                  z-index: 99;
                                                  width: 24px;
                                                  height: 24px;
                                                  border-radius: 12px;
                                                  display: grid;
                                                  place-items: center;
                                                  cursor: pointer;
                                                  outline: solid 4px white;
                                                  font-family: var(--fontawesome-font);
                                                  font-weight: 400;
                                                  -moz-osx-font-smoothing: grayscale;
                                                  -webkit-font-smoothing: antialiased;
                                                  font-style: normal;
                                                  font-variant: normal;
                                                  text-rendering: auto;
                                                  line-height: 1;
                                                  font-size: 18px;
                                                  background: white;
                                                  padding: 2px;
                                                  color: #d56d72;
                                                  transition-duration: 0.15s;
                                                  transition-property: color, background, outline;
                                                  inset-inline-start: calc(50% - 12px);
                                                  inset-block-start: calc(50% - 12px);
                                                }
                                                &::before {
                                                  content: "";
                                                  position: absolute;
                                                  width: 14px;
                                                  height: 14px;
                                                  border-radius: 3px;
                                                  transform: rotate(45deg);
                                                  inset-inline-end: -6px;
                                                  z-index: -1;
                                                  display: none;
                                                  background: white;
                                                  top: calc(50% - 7px);
                                                  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.4);
                                                }
                                                &:focus-within {
                                                  background: white;
                                                  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
                                                  margin-inline-end: 10px;

                                                  &::after {
                                                    z-index: -1;
                                                  }
                                                  &::before {
                                                    display: block;
                                                  }
                                                }
                                                &:hover {
                                                  &::after {
                                                    color: #ffffff;
                                                    background: #a3a3a3;
                                                    outline: solid 4px #a3a3a3;
                                                  }
                                                }
                                              }
                                            }
                                          }
                                          &:focus-within {
                                            width: clamp(320px, 30vw, 30vw);
                                          }
                                        }
                                        input {
                                          &:hover {
                                            outline: none;
                                          }
                                        }
                                        .formio-component {
                                          margin-top: 0;
                                        }
                                        &:last-child {
                                          border-inline-end: none !important;
                                        }
                                        .formio-component-htmlelement {
                                          p {
                                            text-align: start;
                                          }
                                        }
                                        .checkbox + .checkbox {
                                          margin-top: 0;
                                        }
                                      }
                                      &:last-child {
                                        > td {
                                          border-bottom: solid 3px #e3e3e6 !important;
                                        }
                                      }
                                      &:focus-within {
                                        z-index: 99;
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          &:last-child {
                            > td {
                              .formio-component-table {
                                > table {
                                  &.table {
                                    > tbody {
                                      > tr {
                                        &:last-child {
                                          > td {
                                            border-bottom: none !important;
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              &:where(.formio-component-textfield, .formio-component-number, .formio-component-currency, .formio-component-datetime) {
                .input-group {
                  height: auto;

                  :where(.input-group-prepend, .input-group-append) {
                    background: transparent;
                    border: none;

                    .input-group-text {
                      padding: 0;
                      font-size: 15px;
                      color: #404451;
                      margin-inline-end: 6px;
                    }
                  }
                }
                .field-wrapper {
                  > div {
                    &[style*="margin-inline-start: 33%;"] {
                      margin-inline-start: 0 !important;
                    }
                  }
                }
                .form-text.text-muted {
                  display: none;
                }
                :where(input, .input-group input) {
                  &.form-control {
                    &:where([type="text"], [type="search"], [type="phoneNumber"], [type="email"]) {
                      height: 28px !important;
                      border: none !important;
                      background-color: transparent !important;

                      &:where(:focus, :hover) {
                        outline: none;
                      }
                      &:disabled {
                        background-color: transparent !important;
                      }
                    }
                  }
                }
              }
              &.formio-component-selectboxes {
                .form-radio {
                  margin-top: 0;
                  display: flex;
                  align-items: baseline;
                  flex-wrap: wrap;
                  gap: 20px;

                  :where(.form-check, .form-check-inline) {
                    display: flex;
                    margin-bottom: 0;
                    margin-inline-end: 0;

                    &:not(.radio-selected) {
                      display: none;
                    }
                    label {
                      &.form-check-label {
                        margin: 0;

                        input {
                          display: none;

                          & + span {
                            display: none;
                          }
                          &[checked="true"] {
                            & + span {
                              display: flex;
                              font-size: 15px;
                              font-weight: 500;
                            }
                          }
                        }
                        &::before {
                          display: none;
                        }
                      }
                    }
                  }
                  .checkbox + .checkbox {
                    margin-top: 0;
                  }
                }
                > label {
                  display: none;
                }
              }
              &.formio-component-checkbox {
                &.check-switch {
                  .form-check.checkbox {
                    margin-top: 0;
                  }
                }
              }
              &.formio-component-file {
                > label {
                  display: none;
                }
                ul.list-group {
                  width: 100%;
                  padding-inline-end: 1.2rem;
                }
              }
              > .formio-component {
                &.formio-component-table {
                  table {
                    &.table {
                      table-layout: auto;
                    }
                  }
                }
              }
              select.formio-multiple-mask-select {
                appearance: none;
                background: transparent;
                border: none;
                width: auto;
                padding-inline-start: 0;

                &:hover {
                  outline: none;
                }
              }
            }
            > div[class^="col-"] {
              > .formio-component {
                &.formio-component-table {
                  margin-inline-start: -12px;
                  margin-inline-end: -12px;
                  margin-top: -7px;
                  margin-bottom: -7px;
                  width: calc(100% + 24px);

                  table {
                    &.table {
                      table-layout: auto;

                      tbody {
                        tr {
                          td {
                            padding: 6px 1.2rem;
                            border: solid 1px #e3e3e6 !important;

                            &:first-child {
                              vertical-align: middle;
                              width: 30%;

                              .formio-component-htmlelement {
                                p {
                                  text-align: start;
                                }
                              }
                            }
                            .formio-component {
                              margin: 0;

                              &.formio-component-table {
                                margin-inline-start: -12px !important;
                                margin-inline-end: -12px !important;
                                margin-top: -7px !important;
                                margin-bottom: -7px !important;
                                width: calc(100% + 24px);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            &.type-selectboxes {
              > .col-md-10 {
                &:nth-last-child(3) {
                  min-width: 200px !important;
                  flex: 1 !important;
                  max-width: 40% !important;
                }
              }
            }
            label.field-required {
              display: none;

              &::after {
                display: none;
              }
            }
            &.type-editgrid {
              > .col-md-10 + .col-md-6.cols-grid-end,
              > .col-md-12 + .col-md-6 {
                display: none;
              }
              &:has(.col-md-6.cols-grid-end) {
                .col-md-10 {
                  width: 100%;
                }
              }
              > .col-md-10:first-child {
                margin-inline-end: 0 !important;

                .formio-component-datagrid {
                  margin-top: 0px;

                  .form-check.checkbox {
                    margin-top: 0px;
                  }
                  [class*="_validation_reason"] {
                    inset-inline-end: 20px;
                  }
                }
              }
            }
            &.type-datagrid {
              .formio-component-datagrid {
                .formio-component-table {
                  td:first-child {
                    .formio-component-htmlelement {
                      p {
                        margin-top: 0px;
                      }
                    }
                  }
                  .input-value {
                    font-weight: 400;
                    font-size: 15px;
                  }
                }
              }
            }
            &:is(.type-datagrid, .type-editgrid) {
              flex-wrap: wrap;

              > .col-md-2:first-child {
                flex: 0 0 100%;

                & + .col-md-10 {
                  flex: 0 0 100%;
                }
              }
              table.datagrid-table {
                table.table {
                  table-layout: auto;
                  
                  td + td {
                    border-inline-start: solid 1px #e3e3e6;
                  }
                  td {
                    font-size: 14px;

                    &[ref*="table-"] {
                      border: solid 1px #e3e3e6 !important;
                      padding: 6px 12px;
                      height: 40px;
                      vertical-align: middle;

                      &:first-child {
                        width: 25%;
                      }
                      .formio-component {
                        margin-top: 0px;

                        &.formio-component-radio {
                          .form-radio {
                            margin-top: 0px;
                          }
                        }
                        &.formio-component-file {
                          .list-group {
                            padding: 0;
                            margin: 0;
                          }
                        }
                      }
                    }
                  }
                }
                .dg-action-col {
                  display: none;
                }
                td.table {
                  padding: 0;
                }
                .formio-component-htmlelement {
                  margin-top: 0px !important;
                }
              }
            }
            &.type-file {
              strong {
                font-weight: 500;
              }
              ul.list-group {
                padding-inline-end: 0px;
                margin-bottom: 0px;
              }
            }
            &:is(.type-htmlelement, .type-content) {
              strong {
                font-weight: 700;
              }
              .text-right {
                text-align: start !important;
              }
              .text-lg {
                font-size: 100%;
              }
            }
            &.type-select {
              .value {
                line-height: 1.3;

                &[ref="tooltip"] {
                  &::after,
                  &::before {
                    display: none;
                  }
                }
              }
              .key {
                margin-inline-end: 5px;
              }
            }
            &.type-textarea {
              .card.card-body {
                padding: 0 !important;
              }
            }
            &.type-hidden {
              display: none;
            }
            .input-prefix {
              margin-inline-end: 5px;
            }
            .input-suffix {
              margin-inline-start: 5px;
            }
            .currency-prefixed {
              margin-inline-end: 5px;
            }
            .currency-suffixed {
              &:not(:empty) {
                margin-inline-start: 5px;
              }
            }
            p[ref="html"]:has(.currency-prefixed, .currency-suffixed) {
              display: flex;
            }
          }
        }
      }
    }
    .error-page-container {
      width: 90%;
      max-width: 500px;
      margin: 0 auto;
      justify-content: space-between;
      background: @whiteColor;
      border-radius: @roundedCorners;
      padding: @panelPadding;
      box-shadow: 0px 0px 20px @black5Color;
      display: flex;
      gap: 12px;

      h1 {
        margin-top: 0;
      }
      .error-body {
        font-size: 14pt;
        line-height: 20pt;
      }
      a {
        border-radius: @roundedCorners !important;
        background-color: var(--color-primary-600);
        padding: 12px 25px;
        font-weight: 600;
        line-height: 1;
        color: @whiteColor;
        text-align: center;
        font-size: 16px;
        display: inline-block;
        margin-top: auto;
        width: fit-content;

        &:hover {
          text-decoration: none;
          box-shadow: 0 9px 20px @accentColor40 !important;
          opacity: 0.9;
        }
      }
      .error-page-message {
        display: flex;
        flex-direction: column;
        flex: 2.5;
      }
      .error-page-illustration {
        flex: 1;
        position: relative;

        span {
          position: absolute;
          font-size: clamp(18pt, 0.5vw + 3rem, 30pt);
          font-weight: 600;
          color: #334bb3;
          text-align: center;
          width: 86%;
          height: 32%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        img {
          height: 100%;
        }
      }
    }
    .formio-component-panel.readonly-forms-block {
      margin-bottom: 0;

      & + .formio-component-panel {
        margin-top: @readonlyBlockTopMargin !important;
      }
      .formio-component-panel {
        margin-bottom: 0px;

        & + .formio-component-panel {
          margin-top: @readonlyBlockTopMargin;
        }
      }
    }
    .part-b-data-tab {
      .field-wrapper {
        [style*="margin"] {
          margin-inline: 0px !important;
        }
      }
      .readonly-forms-components-container {
        &:first-child {
          margin-top: 0 !important;
        }
      }
      .readonly-forms-block {
        margin-inline: 0px !important;
      }
      .data-review-validation-column {
        flex-wrap: wrap;

        > [class*="col-"]:first-child {
          flex: initial;
          width: fit-content;
        }
        .cols-grid-end {
          flex: auto;
          width: initial;
        }
        > .formio-errors {
          flex: 0 0 100%;
          width: initial;
        }
        .validator-radio {
          .col-form-label {
            font-size: 18px;
            font-weight: 600;
            color: @darkTextColor;
            line-height: 1;

            &::after {
              display: none;
            }
          }
          .radio.form-check-inline {
            font-weight: 500;

            &:is(:hover, .radio-selected) {
              span {
                color: white;
              }
            }
            &:first-child {
              background-color: @accentColor10;
              border-radius: 6px 0 0 6px;

              &:hover {
                background-color: #80c49b;
                color: white;
              }
              &.radio-selected {
                background-color: #80c49b;
                color: white;
              }
            }
            &:last-child {
              background-color: @accentColor5;
              border-radius: 0 6px 6px 0;

              &:hover {
                background-color: #d56d72;
                color: white;
              }
              &.radio-selected {
                background-color: #d56d72;
                color: white;
              }
            }
          }
        }
        .formio-component-textarea {
          .col-form-label {
            color: var(--color-gray-600);
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 10px;
            line-height: 19px;
            margin-top: 6px;
  
            &::after {
              content: " ";
              display: inline-block;
              background-color: @errorColor70;
              vertical-align: unset;
              width: 6px;
              height: 6px;
              border-radius: 5px;
              position: relative;
              top: -1px;
              inset-inline-start: 2px;
              min-width: 6px;
            }
          }
          textarea {
            border: solid 1px @borderColor;
            border-radius: @roundedCorners !important;
          }
          &:not(.formio-modified) {
            textarea {
              border: solid 1px @errorNotice;
            }
          }
        }
      }
      // FIXME Just for Old Validation structure currently in RC
      .formio-component-validationtable {
        table.table {
          table-layout: auto;
        }
        td:first-child {
          flex: 2;
          padding: 0 !important;
          width: 90%;
        }
        .formio-component-htmlelement {
          margin-top: 6px;
        }
        .check-switch {
          .form-check {
            margin-top: 0px !important;
            margin-bottom: 0px !important;
            align-items: center;
            display: flex;
            gap: 12px;
            margin-inline-end: 32px;

            span {
              color: #ffa076;
              font-size: 18px;
              font-weight: 600;
              line-height: 1;
            }
          }
        }
        #datarejectionreason {
          flex: auto;

          label {
            font-size: 15px;

            &::after {
              background-color: rgba(255, 0, 59, .7);
              border-radius: 5px;
              content: " ";
              display: inline-block;
              height: 6px;
              inset-inline-start: 4px;
              min-width: 6px;
              position: relative;
              top: -1px;
              vertical-align: unset;
              width: 6px;
            }
          }
          textarea {
            font-size: 14px;
            border-color: rgba(255, 0, 59, .7) !important;
            color: #1b2331;
            border-radius: @roundedCorners;
            min-height: 64px;
            padding: 4px 10px;
            width: 100%;

            &:focus {
              outline: solid 4px rgba(255, 0, 59, .2);
            }
          }
        }
      }
    }
    /* Test */
    select:is(.form-control, .flatpickr-monthDropdown-months, .flatpickr-monthDropdown-months:hover) {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z' fill='@{escHexAccentColor}'/%3E%3C/svg%3E");
      background-size: 8px;
      background-repeat: no-repeat;
      background-position: right 12px center;
      padding-inline-end: 32px;
    }
    select option {
      background: white;
      font-weight: normal;
    }
    .search-field {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAxMyAxMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDVDMCAyLjIzODU5IDIuMjM4NTMgMCA1IDBDNy43NjE0NyAwIDEwIDIuMjM4NTkgMTAgNUMxMCA1LjkyNzYxIDkuNzQ3NDQgNi43OTYyNiA5LjMwNzI1IDcuNTQwODNMMTIuNTUzNSAxMC4wODIzTDExLjkzNyAxMC44Njk3TDguNzE0NiA4LjM0NjkyQzcuNzk5NDQgOS4zNjE5NCA2LjQ3NDI0IDEwIDUgMTBDMi4yMzg1MyAxMCAwIDcuNzYxNDEgMCA1Wk0xIDVDMSAyLjc5MDgzIDIuNzkwODkgMSA1IDFDNy4yMDkxMSAxIDkgMi43OTA4MyA5IDVDOSA3LjIwOTE3IDcuMjA5MTEgOSA1IDlDMi43OTA4OSA5IDEgNy4yMDkxNyAxIDVaIiBmaWxsPSIjMzczNzM3Ii8+Cjwvc3ZnPgo=);
      background-repeat: no-repeat;
      background-position: center right 10px;
    }
    // Position fixed Dropdown list
    .choices__list--dropdown, .choices__list[aria-expanded] {
      z-index: 100;
    }

    // Celia's revamp for Libya
    @menuBarColor: var(--color-primary-600);
    @primaryColor: var(--color-primary-600);

    .warning-list[hidden].modern-warning-list {
      background-color: var(--color-gray-100);
      box-shadow: -1200px 0 0 var(--color-gray-100), -2400px 0 0 var(--color-gray-100), 1200px 0 0 var(--color-gray-100), 2400px 0 0 var(--color-gray-100);

      li.several-msg {
        span {
          color: @primaryColor;
          font-weight: 500;
        }
        strong {
          color: @primaryColor;
        }
      }
    }
    .service-bar-user-lang-menu {
      ul {
        li {
          a {
            font-weight: 400 !important;
          }
        }
      }
    }
    #header {
      .secondary-menu {
        background-color: var(--menu-bar-bg-color);
        border-block: solid 1px var(--color-gray-200);

        .contain-lg {
          nav.secondary-nav-container {
            .services-select {
              background-color: rgba(0, 0, 0, 0.1) !important;

              &.active {
                .text {
                  opacity: 1;
                }
                .search:focus + .text {
                  opacity: 0.15;
                }
              }
              .menu {
                .item {
                  font-size: 14px;
                  letter-spacing: 0;
                }
              }
            }
            .all-roles-menu {
              ul.secondary-nav {
                li.active a {
                  background-color: rgba(255, 255, 255, 0.1);
                  font-size: 14px !important;
                }
                li.togglers label.menu-toggler span.count-roles {
                  background-color: @primaryColor;
                }
                li.role-menu-item {
                  a {
                    span {
                      font-size: 14px;
                    }
                  }
                }
                .bots-toggler {
                  span {
                    font-size: 14px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    #base {
      #content {
        .demo-mode-heading {
          background-color: var(--color-gray-100) !important;

          span {
            font-weight: 500 !important;
            color: @primaryColor;
          }
        }
        .section-body {
          .contain-lg {
            .dashboard {
              #tab-applications {
                #services-list {
                  .services-title-search-container {
                    .services-search-icon {
                      z-index: -1;
                    }
                    h2.big-title {
                      color: var(--custom-color, var(--color-gray-900));
                      font-weight: 500;
                    }
                    #available-services-filter {
                      box-shadow: none;
                      transition-duration: 0.15s;

                      &:where(:focus, :hover) {
                        background-color: @whiteColor70;
                        box-shadow: 0 0 0 1px @menuBarColor;
                      }
                      &:hover {
                        opacity: 0.8;
                      }
                      &:where(:focus, :focus:hover) {
                        opacity: 1;
                      }
                    }
                    &:focus-within {
                      .services-search-icon {
                        z-index: 1;
                      }
                    }
                  }
                  .dashboard-tiles {
                    .dashboard-tile {
                      .service-icon {
                        span {
                          display: none;
                        }
                      }
                      .service-name {
                        color: var(--custom-color, var(--color-gray-900));
                      }
                    }
                  }
                }
              }
            }
            #entreprise-grid {
              .table-responsive-container {
                table {
                  thead {
                    tr {
                      th {
                        letter-spacing: 0;
                      }
                    }
                  }
                  tbody {
                    tr {
                      td {
                        :is(span.label-status, span.label) {
                          letter-spacing: 0.6px;
                        }
                      }
                    }
                  }
                }
              }
            }
            .overview-panel {
              table.part-b-single-file-resume {
                thead {
                  tr {
                    th {
                      letter-spacing: 0;
                    }
                  }
                }
              }
            }
          }
          #formio-form-role {
            .formio-form {
              > .formio-component-tabs {
                > .card {
                  .formio-component-part-b-documents-tab ~ .formio-component {
                    #validationHeader {
                      .form-field-type-mycounter {
                        span.bullet {
                          line-height: 16px;
                        }
                      }
                      [id^="document-"] {
                        span.label {
                          padding: 4px 8px;
                        }
                      }
                    }
                  }
                  .formio-component-costtable {
                    table.table {
                      tbody {
                        tr {
                          td {
                            padding: 5px 0;

                            h4 {
                              font-size: 16px;
                              font-weight: 600;
                            }
                            [class*="totalPrice"] {
                              p {
                                font-weight: 600;
                              }
                            }
                          }
                        }
                      }
                    }
                    & ~ .formio-component-columns {
                      margin-top: 3rem !important;
                    }
                    & ~ .formio-component-confirmationRow {
                      padding: 0 !important;

                      article.margin-bottom-xxl {
                        margin-bottom: 0 !important;
                      }
                    }
                  }
                }
              }
            }
          }
          .readonly-form-columns {
            p:only-child {
              margin-top: 0;
              margin-bottom: 0;
            }
          }
        }
        // If a Catalog component is open
        &:has(.choices__list--dropdown.is-active) {
          .affix-menu-size-container:not(.affix) ~ .section-body {
            z-index: 1051;
          }
        }
      }
    }
    #select-business-entity .modal-dialog {
      .panel-heading {
        .panel-title {
          color: @darkTextColor;
        }
      }
      .panel-body .radio label input[type="radio"] {
        &:checked::before {
          color: @primaryColor;
        }
        & + span {
          color: @darkTextColor !important;
        }
      }
      .panel .panel-footer a {
        background-color: @primaryColor;
      }
    }
    // Bootstrap override
    .radio + .radio, .checkbox + .checkbox {
      margin-top: initial;
    }
    // Leapa payment overrides
    :not(:defined) {
      visibility: initial;
    }
    .open-modal-button {
      --button-bg-color: var(--color-primary-600);
      --button-text-color: white;
      --button-text-color-hover: white;
      width: initial;
      background-color: var(--button-bg-color);
      font-weight: 500;
      color: var(--button-text-color) !important;
      gap: 10px;
      display: inline-flex;
      align-items: center;

      &:hover {
        color: var(--button-text-color-hover);
      }
    }
    // Helpers
    .column-gap {
      column-gap: var(--gap-value, 0px);
    }
    .flex-wrap {
      flex-wrap: wrap;
    }
    #content > .section-body:has(.formio-component-modal-wrapper) {
      z-index: 2;
    }
  }
  .formio-hidden {
    display: none;
  }
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--color-primary-600);
}
::-webkit-scrollbar-track {
  background-color: @accentColor40;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes buttonProgress {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@-webkit-keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}
@keyframes fadeOut {
  0% {opacity: 1;}
  100% {opacity: 0;}
}
@keyframes buttonFeedback {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  90% {
    -webkit-transform: scale(1.15);
    transform: scale(1.15);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes hglightComp {
  0% {
    background-color: rgba(255, 0, 59, 0.15);
    box-shadow: 0 0 0 12px rgba(255, 0, 59, 0.15);
  }
  100% {
    background-color: rgba(255, 0, 59, 0);
    box-shadow: 0 0 0 12px rgba(255, 0, 59, 0);
  }
}
@keyframes hglightOutline {
  0% {
    outline-color: hsl(346.26deg, 100%, 65%);
  }
  100% {
    outline-color: hsl(346.26deg, 100%, 100%);
  }
}
@keyframes shake {
  0% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0px);
  }
}

// product list
.choose-product-btn {
  align-items: center !important;
  background-color: @accentColor10 !important;
  border: none !important;
  border-radius: 6px !important;
  color: var(--color-gray-800) !important;
  display: inline-flex;
  font-size: 16px;
  font-weight: 500 !important;
  height: 40px;
  padding-inline: 24px;
  padding-block: 10px;
  transition-duration: .3s;

  &:hover {
    background-color: @accentColor10 !important;
    box-shadow: var(--button-shadow-hover) !important;
    color: var(--color-gray-800) !important;
    opacity: 0.9;
  }
}
:root {
  --tab-content-items-gap: 3rem;
}